/**
 * The default parameters for the constructor of DataStorageProperty.
 */
class DataStoragePropertyConstructorParams {
  /**
   * Whether the property is the primary key.
   *
   * @type {boolean}
   */
  primaryKey = false;

  /**
   * Whether the property is the title key.
   *
   * @type {boolean}
   */
  titleKey = false;

  /**
   * The pretty name of the property.
   *
   * The default value is the property name in Header case.
   *
   * @type {string}
   */
  pretty = undefined;

  /**
   * The default value for the property.
   *
   * @type {*}
   */
  defaultValue = undefined;

  /**
   * Whether the property is required.
   *
   * @type {boolean}
   */
  required = true;

  /**
   * Whether the property is read only.
   *
   * @type {boolean}
   */
  readonly = false;

  /**
   * The name of the mode the property refers to.
   *
   * @type {string}
   */
  references = null;

  /**
   * Whether the property is searchable.
   */
  searchable = false;

  /**
   * Settings related to the data-table component.
   */
  table = {
    /**
     * Whether the column should be hidden.
     *
     * @type {boolean}
     */
    hidden: false,

    /**
     * The format function for Vue good table.
     *
     * @type {(value: T) => string}
     */
    formatFn: (value) => value,

    /**
     * Whether the table should be rendered as html.
     *
     * @type {boolean}
     */
    html: false,

    /**
     * The width of the column.
     *
     * @type {string}
     */
    width: undefined,
  };

  /**
   * Settings related to the data-form component.
   */
  form = {
    /**
     * If `true` the property will not appear in the form - will be ignored.
     *
     * @type {boolean}
     */
    ignore: false,

    /**
     * If `true` the property will not appear in the form when creating
     * a model - will be ignored.
     *
     * @type {boolean}
     */
    ignoreWhenNew: false,

    /**
     * If `true` the property will not appear in the form when updating
     * a model - will be ignored.
     *
     * @type {boolean}
     */
    ignoreWhenUpdate: false,

    /**
     * If `true` the property will be read-only in the form when updating
     * a model - once this property is set, it cannot be changed.
     */
    disableWhenUpdate: false,
  };

  /**
   * Whether the model provides some data to aggregate
   *
   * @type {boolean}
   */
  canAggregate = false;

  /**
   * Textual hint, denoting the meaning of the field or providing some
   * additional information.
   *
   * @type {string}
   */
  fieldHint = '';
}

export default DataStoragePropertyConstructorParams;
