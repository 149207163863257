import DataStorageProperties from '../abstract/data-storage-properties';
import date from '../abstract/properties/property-date';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';

const documentTemplateProperties = DataStorageProperties.create({
  slug: string({
    primaryKey: true,
    titleKey: true,
    required: false,
    maxLength: 50,
    searchable: true,
  }),
  type: string({
    maxLength: 50,
    required: false,
    searchable: true,
  }),
  currentVersion: int({
    required: false,
  }),
  lastUpdate: date({
    required: false,
  }),
});

export default documentTemplateProperties;
