/**
 * Works just like `fn.bind(thisArg)` but actually respects IntelliSense.
 *
 * @template {Function} T
 *
 * @param {T} fn
 * @param {*} thisArg
 *
 * @returns {T}
 */
export const thisBinder = (fn, thisArg) => {
  return fn.bind(thisArg);
};
