import keyboardTracker from './keyboard-tracker';

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        $keyboard() {
          return keyboardTracker;
        },
      },
    });
  },
};
