<template>
  <div>
    <div class="video-grid mt-2">
      <div v-for="video in displayedVideos" :key="video.id" class="video-card">
        <video-box
          :video="video"
          :hasActions="hasActions"
          :disabled="disabled"
          :promotionalVideos="promotionalVideos"
          :profileVideoId="profileVideoId"
          @setProfileVideo="$emit('setProfileVideo', $event)"
          @addPromotionalVideo="$emit('addPromotionalVideo', $event)"
          @removePromotionalVideo="$emit('removePromotionalVideo', $event)"
        />
      </div>
    </div>
    <div class="video-grid-pagination" v-if="displayPagination">
      <button
        @click="offset = offset - 1"
        class="btn video-grid-pagination-button"
        :disabled="offset === 1 || userLoading"
      >
        Previous three videos
      </button>
      <button
        @click="offset = offset + 1"
        class="btn video-grid-pagination-button"
        :disabled="offset * numberOfDisplayedVideos >= videos.length || userLoading"
      >
        Next three videos
      </button>
    </div>
  </div>
</template>

<script>
import { userFactory } from '../../model';
import VideoBox from './VideoBox.vue';

export default {
  name: 'video-grid-layout',
  components: {
    VideoBox,
  },
  props: [
    'videos',
    'promotionalVideos',
    'hasActions',
    'firstName',
    'lastName',
    'profileVideoId',
    'disabled',
    'error',
  ],
  data() {
    return {
      offset: 1,
      numberOfDisplayedVideos: 4,
      userLoading: false,
      userError: null,
      userName: null,
      displayedVideos: [],
      displayPagination: false,
    };
  },
  methods: {
    async displayVideosAtOffset() {
      const start = (this.offset - 1) * this.numberOfDisplayedVideos;
      const end = this.offset * this.numberOfDisplayedVideos;

      const videosToDisplay = this.videos.slice(
        start,
        end > this.videos.length ? this.videos.length : end
      );

      for (const video of videosToDisplay) {
        video['userName'] = await this.fetchUserName(video.userId);
      }

      this.displayedVideos = videosToDisplay;
      this.displayPagination = this.videos.length > this.numberOfDisplayedVideos;
    },
    async fetchUserName(userId) {
      try {
        this.userLoading = true;
        this.userError = null;
        const user = await userFactory.pk(userId);
        return user.fullname;
      } catch (e) {
        this.userError = e;
      } finally {
        this.userLoading = false;
      }
    },
  },
  mounted() {
    this.displayVideosAtOffset();
  },
  watch: {
    offset() {
      this.displayVideosAtOffset();
    },
  },
};
</script>

<style lang="scss">
.video-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  align-items: center;
}

.video-actions {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 15px;
}

.video-grid-pagination {
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  justify-content: end;
  gap: 16px;

  .video-grid-pagination-button {
    font-size: 14px !important;
    line-height: 24px !important;

    border-radius: 4px !important;
    height: 40px !important;

    font-weight: 600 !important;
    background: #22252b !important;
    color: #8f95a3 !important;
    cursor: pointer !important;
    transform: translateY(-0.1rem);

    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;

    &:disabled {
      cursor: not-allowed !important;
      background: #121417 !important;
      color: #272a30 !important;
    }
    &.left {
      padding-right: 12px !important;
    }

    &.right {
      padding-left: 16px !important;
    }
  }
}

.video-card {
  border-radius: 4px;
  padding: 8px 12px;

  &:hover {
    background: #191b1f;
  }

  .video {
    width: 100%;
  }

  .private-video {
    height: 165px;
    padding-top: 24px;
    text-align: center;
  }

  .video-info-title {
    font-size: 12px;
    line-height: 16px;
    color: #8f95a3;
  }

  .video-info-link {
    color: #b3bdff;
    font-size: 14px;
    line-height: 24px;
    text-decoration: underline;
    &:hover {
      color: #ffffff;
    }
  }
}
</style>
