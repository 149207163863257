/** @typedef {import('./data-storage-property').default} DataStorageProperty */

import { headerCase } from 'change-case';
import memoize from 'lodash/memoize';

class DataStorageProperties {
  /**
   * Calls the specified function for every property.
   *
   * @param {(value: DataStorageProperty, index: number, array: DataStorageProperty[]) => void} fn
   */
  forEach(fn) {
    this._descriptors.forEach(fn);
  }

  /**
   * Calls the specified function for every property and creates a new array
   * from the returned values. The new array is returned.
   *
   * @template T
   *
   * @param {(value: DataStorageProperty, index: number, array: DataStorageProperty[]) => T} fn
   */
  map(fn) {
    return this._descriptors.map(fn);
  }

  /**
   * Returns the value of the first element in the array where predicate is
   * true, and undefined otherwise.
   *
   * @param {(value: DataStorageProperty, index: number, array: DataStorageProperty[]) => void} fn
   */
  find(fn) {
    return this._descriptors.find(fn);
  }

  /**
   * Returns the elements of an array that meet the condition specified in a
   * callback function.
   *
   * @param {(value: DataStorageProperty, index: number, array: DataStorageProperty[]) => void} fn
   */
  filter(fn) {
    return this._descriptors.filter(fn);
  }

  /**
   * Determines whether the specified callback function returns true for any
   * element of an array.
   *
   * @param {(value: DataStorageProperty, index: number, array: DataStorageProperty[]) => void} fn
   */
  some(fn) {
    return this._descriptors.some(fn);
  }

  /**
   * Returns the columns config used by data-table.
   */
  _tableDescriptorImpl() {
    console.debug('DataStorageProperties._tableDescriptorImpl');

    return this._descriptors
      .map((descriptor) => descriptor.tableDescriptor())
      .filter(Boolean)
      .sort((a, b) => a.order - b.order);
  }

  tableDescriptor = memoize(this._tableDescriptorImpl);

  /**
   * Returns the full config used by data-form.
   */
  _formDescriptorImpl() {
    console.debug('DataStorageProperties._formDescriptorImpl');

    return this._descriptors.map((descriptor) => descriptor.formDescriptor()).filter(Boolean);
  }

  formDescriptor = memoize(this._formDescriptorImpl);

  /**
   * The iterator.
   */
  [Symbol.iterator]() {
    return this._descriptors.values();
  }

  /**
   * Creates a new instance of DataStorageProperties.
   *
   * @param {Array<DataStorageProperty>} descriptors
   */
  constructor(descriptors) {
    if (Object.prototype.hasOwnProperty.call(descriptors, 'tableDescriptor')) {
      throw new Error('tableDescriptor is a reserved name');
    }

    if (Object.prototype.hasOwnProperty.call(descriptors, 'formDescriptor')) {
      throw new Error('formDescriptor is a reserved name');
    }

    this._descriptors = descriptors;

    this._descriptors.forEach((descriptor) =>
      Object.defineProperty(this, descriptor.prop, { get: () => descriptor })
    );
  }

  /**
   * @template {Object<string,DataStorageProperty>} Descriptors
   *
   * @param {Descriptors} descriptors
   *
   * @returns {DataStorageProperties}
   */
  static create(descriptors) {
    Object.entries(descriptors).forEach(([prop, descriptor]) => {
      descriptor.prop = prop;
      descriptor.pretty = descriptor.pretty || headerCase(prop).replace(/-/g, ' ');
    });

    const properties = new DataStorageProperties(Object.values(descriptors));

    return properties;
  }
}

export default DataStorageProperties;
