<template>
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99998 16.3401L0.789978 12.7901V5.20007L6.99998 8.75007V16.3401Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 16.3401L13.21 12.7901V5.20007L7 8.75007V16.3401Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.21 5.21003L6.99998 1.66003L0.789978 5.21003L6.99998 8.75003L13.21 5.21003Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'product-icon',
};
</script>
