import Vue from 'vue';
import VueRouter from 'vue-router';

import modal from '../plugins/modal/modal-component';
import store from '../store';

import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({ routes });

router.beforeEach(async (to, from, next) => {
  if (/^\/model\/.+?\/(\d+|new)$/.test(from.path) && store.getters['dataForm/hasChanges']) {
    if (!(await modal.confirm('The changes will not be saved.'))) {
      return next(false);
    }
  }

  return next();
});

export default router;
