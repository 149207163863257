import { MODEL_NAME_REQUEST, MODEL_NAME_REQUEST_STATUS, MODEL_NAME_USER } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import Request from './request-class';
import requestProperties from './request-properties';

/** @extends {DataStorageFactory<Request>} */
class RequestFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_REQUEST;

  getSingular = () => 'Request';
  getPlural = () => 'requests';

  getIcon = () => 'all-requests-icon';

  getTabs = () => [
    {
      name: 'Products',
      slug: 'products',
      description: 'Products contained in the request.',
    },
    {
      name: 'Video',
      slug: 'video',
      description: 'The video recorded for the request.',
    },
    {
      name: 'Actions',
      slug: 'actions',
      description: 'Actions to be executed concerning the request.',
    },
    {
      name: 'Payments',
      slug: 'payments',
      description: 'Financial data about the request.',
    },
    {
      name: 'Contact',
      slug: 'contact',
      description: 'Contact information about the person who created the request.',
    },
    {
      name: 'Review',
      slug: 'review',
      description: 'Review of the video for the request.',
    },
  ];

  getFilters = () => [
    {
      filter: 'userId.eq',
      label: 'Filter by celebrity',
      type: 'data-select-filter',
      description: 'Display requests for given celebrity',
      props: {
        model: MODEL_NAME_USER,
        multiple: false,
        placeholder: 'All celebrities',
      },
    },
    {
      filter: 'requestStatusId.eq',
      label: 'Filter by request status',
      type: 'data-select-filter',
      description: 'Display requests based on their status',
      props: {
        model: MODEL_NAME_REQUEST_STATUS,
        multiple: false,
        placeholder: 'All statuses',
      },
    },
    {
      filter: 'paid.custom',
      label: 'Filter by payment status of the request',
      type: 'simple-select-filter',
      description: 'Display requests based on their paid property.',
      props: {
        options: [
          { label: 'Paid', value: 1 },
          { label: 'Not paid', value: 0 },
        ],
      },
    },
    {
      filter: 'expiresSoon.custom',
      label: 'Filter requests which expire soon',
      type: 'simple-select-filter',
      description: 'Display requests which have 24 hours or less to be completed.',
      props: {
        options: [{ label: '24 hours or less', value: 6 }],
      },
    },
    {
      filter: 'created.range',
      label: 'Filter by date of creation',
      type: 'date-range-filter',
      description: 'Display requests based on their time of creation.',
    },
  ];

  getDefaultAggregates = () => [
    {
      aggregateFunction: 'Avg',
      aggregatedColumn: 'amount',
    },
    {
      aggregateFunction: 'Sum',
      aggregatedColumn: 'amount',
    },
    {
      aggregateFunction: 'Avg',
      aggregatedColumn: 'amountUser',
    },
    {
      aggregateFunction: 'Sum',
      aggregatedColumn: 'amountUser',
    },
  ];
}

const requestFactory = new RequestFactory(requestProperties, Request.prototype);

export default requestFactory;
