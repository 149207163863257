import DataStorageProperties from '../abstract/data-storage-properties';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';
import date from '../abstract/properties/property-date';
import bool from '../abstract/properties/property-bool';

const celebritySuggestionProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  name: string({
    searchable: true,
    titleKey: true,
  }),
  isVerified: bool({}),
  onPlatform: bool({}),
  created: date({
    required: false,
    defaultValue: () => new Date(),
  }),
  celebrityCount: int({
    form: { ignoreWhenNew: true },
    readonly: true,
    required: false,
  }),
});

export default celebritySuggestionProperties;
