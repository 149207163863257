export const API_TIMEOUT = 5 * 1000; // 5 seconds

export const ERROR_CODE_UNKNOWN = 9999;
export const ERROR_CODE_AUTHORIZATION_HEADER_MISSING = 4302;
export const ERROR_CODE_JWT_INVALID = 4303;

export const PRIVATE_VIDEO_PRODUCT_ID = 12;

// PROPERTIES
export const PROPERTY_TYPE_BOOL = 'bool';
export const PROPERTY_TYPE_CONNECTION = 'connection';
export const PROPERTY_TYPE_DATE = 'date';
export const PROPERTY_TYPE_INT = 'int';
export const PROPERTY_TYPE_STRING = 'string';
export const PROPERTY_TYPE_TEXT = 'text';
export const PROPERTY_TYPE_PRICE = 'price';
export const PROPERTY_TYPE_OBJECT = 'object';

// MODELS
export const MODEL_NAME_ADMIN = 'admin';
export const MODEL_NAME_CHARITY = 'charity';
export const MODEL_NAME_OCCASION = 'occasion';
export const MODEL_NAME_PAYOUT = 'payout';
export const MODEL_NAME_PRODUCT = 'product';
export const MODEL_NAME_REVIEW = 'review';
export const MODEL_NAME_TAG = 'tag';
export const MODEL_NAME_USER = 'user';
export const MODEL_NAME_USER_PRODUCT = 'user-product';
export const MODEL_NAME_USER_BILLING_ADDRESS = 'user-billing-address';
export const MODEL_NAME_VERIFICATION = 'verification';
export const MODEL_NAME_REQUEST = 'request';
export const MODEL_NAME_REQUEST_PRODUCT = 'request-product';
export const MODEL_NAME_REQUEST_STATUS = 'request-status';
export const MODEL_NAME_VIDEO = 'video';
export const MODEL_NAME_VIDEO_STATUS = 'video-status';
export const MODEL_NAME_EMAIL_QUEUE = 'email-queue';
export const MODEL_NAME_EMAIL_TEMPLATE = 'email-template';
export const MODEL_NAME_SMS_QUEUE = 'sms-queue';
export const MODEL_NAME_SMS_TEMPLATE = 'sms-template';
export const MODEL_NAME_DOCUMENT_TEMPLATE = 'document-template';
export const MODEL_NAME_DOCUMENT_TEMPLATE_VERSION = 'document-template-version';
export const MODEL_NAME_CONTACT_FORM_MESSAGE = 'contact-form-message';
export const MODEL_NAME_USER_REQUEST_COUNT = 'user-request-count';
export const MODEL_NAME_USER_REQUEST_SUCCESS_FAIL_COUNT = 'user-request-success-fail-count';
export const MODEL_NAME_PRODUCT_REQUEST_COUNT_SUM = 'product-request-count-sum';
export const MODEL_NAME_REQUEST_DATE_RANGE_COUNT = 'request-date-range-count';
export const MODEL_NAME_USER_DATE_RANGE_COUNT = 'user-date-range-count';
export const MODEL_NAME_NEWSLETTER_ADDRESS = 'newsletter-address';
export const MODEL_NAME_REQUEST_INVOICE = 'request-invoice';
export const MODEL_NAME_CELEBRITY_SUGGESTION = 'celebrity-suggestion';
export const MODEL_NAME_CELEBRITY_SUGGESTION_EMAIL = 'celebrity-suggestion-email';
export const MODEL_NAME_USER_CHARITY_LOG = 'user-charity-log';
export const MODEL_NAME_USER_PROMOTIONAL_VIDEO = 'user-promotional-video';
export const MODEL_NAME_FIREBASE_NOTIFICATION_QUEUE = 'firebase-notification-queue';
export const MODEL_NAME_COUPON = 'coupon';
export const MODEL_NAME_USER_SUM_BALANCE = 'user-sum-balance';
export const MODEL_NAME_USER_RECORDING_TIME = 'user-recording-time';
export const MODEL_NAME_USER_TOTAL_RECORDING_TIME = 'user-total-recording-time';
