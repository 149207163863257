<template>
  <div class="card">
    <div class="card-body">
      <template v-if="title">
        <header class="d-flex align-items-center">
          <h1 class="card-title m-0">{{ title }}</h1>
          <slot name="title-button"></slot>
        </header>
      </template>

      <slot name="subtitle">
        <template v-if="subtitle">
          <h6 class="card-subtitle mb-2 text-muted">{{ subtitle }}</h6>
        </template>
      </slot>

      <slot name="navigation"></slot>

      <slot name="description">
        <template v-if="description">
          <p class="card-description" v-html="description"></p>
        </template>
      </slot>

      <div class="card-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'card',
  props: {
    title: String,
    subtitle: String,
    description: String,
  },
};
</script>

<style lang="scss">
.card {
  margin-bottom: var(--bs-gutter-x);

  .card-title {
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    color: #ffffff !important;
  }

  .card-description {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #8f95a3;
  }
}
</style>
