import { MODEL_NAME_REQUEST_PRODUCT } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import RequestProduct from './request-product-class';
import requestProductProperties from './request-product-properties';

/** @extends {DataStorageFactory<RequestProduct>} */
class RequestProductFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_REQUEST_PRODUCT;

  getSingular = () => 'Request Product';
  getPlural = () => 'request Products';

  getIcon = () => 'feather-archive';

  getTabs = () => [];
}

const requestProductFactory = new RequestProductFactory(
  requestProductProperties,
  RequestProduct.prototype
);

export default requestProductFactory;
