<template>
  <textarea
    class="form-control"
    :value="JSON.stringify(value)"
    @change="setValue(value, $event)"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'object-input',
  props: ['value', 'properties', 'type'],
  data() {
    return {
      valueStorage: this.value,
    };
  },
  methods: {
    setValue(oldValue, event) {
      const newValue = event.target.value;
      if (oldValue != newValue) {
        this.valueStorage = JSON.parse(newValue);
      }
    },
  },
  watch: {
    valueStorage() {
      this.$emit('input', this.valueStorage);
    },
  },
};
</script>
