<template>
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0823 14.928C10.0823 16.0786 9.15083 17.01 8.00022 17.01C6.8496 17.01 5.91815 16.0786 5.91815 14.928"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.4327 13.3061L13.4246 11.5528C13.2492 11.2569 13.1616 10.9172 13.1616 10.5775V7.62974C13.1616 5.28467 11.5945 3.30123 9.44671 2.67661C9.44671 2.61086 9.46863 2.53415 9.46863 2.4684C9.46863 1.65749 8.81114 1 8.00023 1C7.18932 1 6.53182 1.65749 6.53182 2.4684C6.53182 2.54511 6.54278 2.61086 6.55374 2.67661C4.40592 3.30123 2.83889 5.28467 2.83889 7.62974V10.5775C2.83889 10.9172 2.75123 11.2569 2.5759 11.5528L1.56774 13.3061C1.22803 13.8869 1.6554 14.6101 2.32386 14.6101H13.6766C14.345 14.6101 14.7615 13.8869 14.4327 13.3061Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'firebase-notification-queue-icon',
};
</script>
