<template>
  <div>
    <div class="model-list-content">
      <div class="icon-headline">
        <div class="icon">
          <icon :icon="factory.getIcon()" />
        </div>
        <div>
          <h1>{{ title }}</h1>
          <p class="model-description">{{ description }}</p>
        </div>
      </div>
      <button
        v-if="factory.canInsert()"
        class="btn btn-sm btn-primary text-white ml-2"
        @click="newButtonClick"
        slot="title-button"
      >
        <icon :icon="factory.getIcon()" />
        <span>New {{ factory.getSingular() }}</span>
      </button>
    </div>
    <data-table
      :model="model"
      hide-new-button
      :userSortOptions="{
        enabled: true,
        initialSortBy: [{ field: 'created', type: 'desc' }],
      }"
    />
  </div>
</template>

<script>
import getFactory from '../model';

export default {
  name: 'page-model-list',
  methods: {
    newButtonClick() {
      this.$router.push(`${this.factory.getPath()}/new`);
    },
  },
  computed: {
    model() {
      return this.$route.params.model;
    },
    factory() {
      return getFactory(this.model);
    },
    title() {
      return `All ${this.factory.getPlural()}`;
    },
    description() {
      return `The list of all ${this.factory.getPlural()}.`;
    },
  },
};
</script>

<style lang="scss">
.model-list-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .icon-headline {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-top: 24px;
    padding-bottom: 28px;

    .icon {
      height: 34px;
      width: 34px;
      margin-left: 7px;
      margin-right: 8px;

      svg {
        height: 100%;
        width: 100%;
        stroke: #ffffff !important;

        path {
          stroke: #ffffff !important;
        }
      }
    }
  }
}
</style>
