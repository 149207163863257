import { MODEL_NAME_REQUEST_STATUS, MODEL_NAME_USER } from '../../constants';
import DataStorageProperties from '../abstract/data-storage-properties';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';

const userRequestCountProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
  }),
  userId: string({
    references: MODEL_NAME_USER,
    pretty: 'User',
  }),
  count: int({}),
  requestStatusId: string({
    references: MODEL_NAME_REQUEST_STATUS,
    pretty: 'Request Status',
  }),
});

export default userRequestCountProperties;
