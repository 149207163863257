import { MODEL_NAME_PRODUCT } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import Product from './product-class';
import productProperties from './product-properties';

/** @extends {DataStorageFactory<Product>} */
class ProductFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_PRODUCT;

  getSingular = () => 'Product';
  getPlural = () => 'products';

  getIcon = () => 'product-icon';

  getTabs = () => [
    {
      name: 'Addons',
      slug: 'addons',
      description: "The list of the product's addons.",
      shouldDisplay: (single) => !single.isAddon,
    },
  ];

  getFilters = () => [
    {
      filter: 'isAddon.eq',
      label: 'Filter by isAddon property',
      type: 'simple-select-filter',
      description: 'Display products based on their isAddon property',
      props: {
        options: [
          { label: 'Addon', value: true },
          { label: 'Not addon', value: false },
        ],
      },
    },
    {
      filter: 'isPublic.eq',
      label: 'Filter by product visibility',
      type: 'simple-select-filter',
      description: 'Display products based on their visibility',
      props: {
        options: [
          { label: 'Public', value: true },
          { label: 'Not public', value: false },
        ],
      },
    },
    {
      filter: 'isUserPrice.eq',
      label: 'Filter by isUserPrice property',
      type: 'simple-select-filter',
      description: 'Display products based on whether the user can set the price',
      props: {
        options: [
          { label: 'Is user price', value: true },
          { label: 'Is not user price', value: false },
        ],
      },
    },
    {
      filter: 'productId.eq',
      label: 'Filter by parent product',
      type: 'data-select-filter',
      description: 'Display products based on their parent',
      props: {
        model: MODEL_NAME_PRODUCT,
        multiple: false,
        placeholder: 'All products',
        filters: { 'isAddon.eq': false },
      },
    },
  ];

  getDefaultAggregates = () => [
    {
      aggregateFunction: 'Avg',
      aggregatedColumn: 'price',
    },
  ];
}

const productFactory = new ProductFactory(productProperties, Product.prototype);

export default productFactory;
