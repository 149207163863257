/**
 * The central keyboard tracker. This components is the only component which
 * can directly set keyboard events on the window (addEventListener can still
 * be used).
 *
 * The keyboard tracker is accessible via a plugin which adds a $keyboard
 * getter to every Vue component.
 */
class KeyboardTracker {
  /**
   * Handler of the global keydown event.
   *
   * @param {KeyboardEvent} e
   */
  onKeyDown(e) {
    this.pressed.add(e.key);
    this.lastChange = Date.now();

    this.emit('down', e);
  }

  /**
   * Handler of the global keyup event.
   *
   * @param {KeyboardEvent} e
   */
  onKeyUp(e) {
    this.pressed.delete(e.key);
    this.lastChange = Date.now();

    this.emit('up', e);
  }

  /**
   * Returns `true` if the specified key is pressed.
   *
   * @param {string|number} key
   */
  isPressed(key) {
    return this.pressed.has(key);
  }

  /**
   * Whether the Control key is pressed.
   */
  get ctrl() {
    return this.isPressed('Control');
  }

  /**
   * Whether the Control key is pressed.
   */
  get control() {
    return this.ctrl;
  }

  /**
   * Whether the Shift key is pressed.
   */
  get shift() {
    return this.isPressed('Shift');
  }

  /**
   * Whether the Alt key is pressed.
   */
  get alt() {
    return this.isPressed('Alt');
  }

  /**
   * Calls all event listeners for the specified event.
   *
   * @param {'up' | 'down'} event
   * @param {KeyboardEvent} e
   */
  emit(event, e) {
    const listeners = this.listeners.filter(
      (listener) => listener.event === event && listener.keys.includes(e.key)
    );

    if (listeners.some(({ prevent }) => prevent)) {
      e.preventDefault();
    }

    listeners.forEach(({ callback }) => callback(e));
  }

  /**
   * Adds an event listener.
   *
   * @param {'up' | 'down' | 'up.prevent' | 'down.prevent'} event
   * @param {string|Array<string>} keys
   * @param {string} name
   * @param {(e: KeyboardEvent) => void} callback
   */
  on(event, keys, name, callback) {
    let prevent = false;

    if (event === 'down.prevent') {
      event = 'down';
      prevent = true;
    }

    if (event === 'up.prevent') {
      event = 'up';
      prevent = true;
    }

    this.listeners.push({
      event,
      keys: Array.isArray(keys) ? keys : [keys],
      name,
      prevent,
      callback,
    });

    console.debug(this.listeners);

    return this;
  }

  /**
   * Removes event listeners with the specified name.
   *
   * @param {string} name
   */
  off(name) {
    this.listeners = this.listeners.filter((listener) => listener.name !== name);

    console.debug(this.listeners);

    return this;
  }

  /**
   * Creates a new instance of KeyboardTracker.
   */
  constructor() {
    /**
     * Set of all pressed keys.
     *
     * @type {Set<string>}
     */
    this.pressed = new Set();

    /**
     * The timestamp of last keydown or keyup.
     *
     * @type {number}
     */
    this.lastChange = null;

    /**
     * All event listeners.
     *
     * @type {Array<{
     *   event: 'up' | 'down',
     *   keys: Array<string>,
     *   name: string,
     *   prevent: boolean,
     *   callback: (e: KeyboardEvent) => void
     * }>}
     */
    this.listeners = [];

    window.onkeydown = this.onKeyDown.bind(this);
    window.onkeyup = this.onKeyUp.bind(this);
  }
}

const keyboardTracker = new KeyboardTracker();

export default keyboardTracker;
