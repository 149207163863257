<template>
  <data-table
    :model="MODEL_NAME_USER_CHARITY_LOG"
    hideNewButton
    hideEditButton
    hideDeleteButton
    hideFilters
    :filters="{ 'userId.eq': single.pk() }"
    :overrideColumns="['charityId', 'timeStart', 'timeEnd', 'amount', 'isClosed']"
  />
</template>

<script>
import { MODEL_NAME_USER_CHARITY_LOG } from '../../constants';

export default {
  name: 'user-supported-charities-tab',
  data() {
    return {
      MODEL_NAME_USER_CHARITY_LOG,
    };
  },
  props: ['single'],
};
</script>
