<template>
  <div class="main-wrapper">
    <div :class="`page-wrapper full-page ${wrapperClass}`">
      <div :class="`page-content ${contentClass}`">
        <router-view />
      </div>
      <div class="creanet-text mx-auto">
        Created with 🤍 by <a href="https://creanet.sk" rel="external" target="_blank">Creanet</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'simple-layout',
  computed: {
    contentClass() {
      return this.$route.meta.contentClass || 'd-flex align-items-center justify-content-center';
    },
    wrapperClass() {
      return this.$route.meta.wrapperClass || '';
    },
  },
};
</script>

<style lang="scss">
.auth-page {
  margin-bottom: 10vh;
}
</style>
