<template>
  <!-- TODO implement -->
  <div class="user-products-tab">
    <span v-if="loading">{{ 'Loading ...' }}</span>
    <span v-else-if="error">{{ error }}</span>
    <template v-else>
      <form @submit.prevent="submit">
        <table class="mt--3 mb-3">
          <!-- Parent is of type UserProductTreeNode -->
          <template v-for="node in tree.nodes">
            <tr :key="node.productId">
              <td class="pt-3">
                <input
                  type="checkbox"
                  v-model="node.enabled"
                  :disabled="!node.enabled || !node.isActive"
                  :id="`user-product-checkbox-${node.productId}`"
                  @input="handleCheck(node, node.enabled)"
                />
                <label :for="`user-product-checkbox-${node.productId}`" class="ml-2">
                  {{ node.productName }}
                  <small class="text-muted">ID: {{ node.productId }}</small>
                </label>
              </td>
              <td class="pt-3">
                <price-input
                  v-model="node.price"
                  :required="node.enabled"
                  :disabled="!node.enabled || !node.isActive"
                  class="d-inline-block ml-4"
                  style="width: 120px"
                  step="0.01"
                  min="0"
                />
              </td>
              <td class="pt-3">
                <label :for="`user-product-checkbox-is-active-${node.productId}`" class="ml-2 mr-2">
                  is Active:
                </label>
                <input
                  type="checkbox"
                  v-model="node.isActive"
                  :id="`user-product-checkbox-is-active-${node.productId}`"
                  @input="handleIsActive(node, node.isActive)"
                />
              </td>
            </tr>

            <template v-if="node.children.length > 0">
              <tr class="mt-2" v-for="child in node.children" :key="child.productId">
                <td class="pl-4 pt-1">
                  <input
                    type="checkbox"
                    v-model="child.enabled"
                    :disabled="!node.enabled || !node.isActive"
                    :id="`user-product-checkbox-${child.productId}`"
                  />
                  <label :for="`user-product-checkbox-${child.productId}`" class="ml-2">
                    {{ child.productName }}
                    <small class="text-muted">ID: {{ child.productId }}</small>
                  </label>
                </td>
                <td class="pt-1">
                  <price-input
                    v-model="child.price"
                    :disabled="!child.enabled"
                    :required="node.enabled && child.enabled"
                    class="d-inline-block ml-4"
                    style="width: 120px"
                    step="0.01"
                    min="0"
                  />
                </td>
              </tr>
            </template>
          </template>
        </table>
        <button type="submit" class="btn btn-primary btn-sm submit text-white">Save</button>
      </form>
    </template>
  </div>
</template>

<script>
import PriceInput from '../../components/inputs/PriceInput.vue';
import { MODEL_NAME_PRODUCT } from '../../constants';
import getFactory from '../../model';
import UserProductTree from '../../model/user-product-tree/user-product-tree';

export default {
  components: { PriceInput },
  name: 'user-requests-tab',
  data() {
    return {
      loading: true,
      error: null,
      tree: [],
      userProducts: {},
    };
  },
  computed: {
    factory() {
      return getFactory(MODEL_NAME_PRODUCT);
    },
    userId() {
      return this.$route.params.pk;
    },
  },
  methods: {
    async getRecords() {
      try {
        this.loading = true;
        this.error = null;
        this.tree = await UserProductTree.forUser(this.userId);
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
        console.debug(this.tree);
      }
    },
    handleCheck(node, wasEnabled) {
      if (wasEnabled) {
        node.price = undefined;
        node.isActive = false;

        node.children.forEach((child) => {
          if (child.enabled) {
            child.enabled = false;
            child.price = undefined;
            child.isActive = false;
          }
        });
      }
    },
    handleIsActive(node, wasActive) {
      if (wasActive) {
        node.children.forEach((child) => {
          if (child.isActive) {
            child.isActive = false;
          }
        });
      } else {
        node.enabled = true;
      }
    },
    submit() {
      this.tree.save();
    },
  },
  created() {
    this.getRecords();
  },
};
</script>

<style lang="scss">
.user-products-tab {
  input[type='number'] {
    height: 28px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
</style>
