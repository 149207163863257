<template>
  <data-select
    :model="model"
    :multiple="multiple"
    :placeholder="placeholder"
    v-model="selected"
    :filters="filters"
  />
</template>

<script>
export default {
  name: 'data-select-filter',
  props: {
    clear: {
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    filters: {
      type: Object,
      default: () => ({}),
    },
    initialValue: {
      default: null,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    selected(newValue) {
      this.$emit('input', newValue);
    },
    clear() {
      this.selected = null;
    },
  },
  created() {
    this.selected = this.initialValue;
  },
};
</script>
