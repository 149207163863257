<template>
  <div class="data-select-area">
    <p v-if="loading">Loading ...</p>
    <p v-else-if="error">{{ error }}</p>
    <vue-select
      v-else
      :options="response"
      label="label"
      :reduce="reduce"
      v-model="selected"
      :placeholder="placeholder"
      :multiple="multiple"
    />
  </div>
</template>

<script>
import deepEqual from 'deep-equal';
import getFactory from '../../model';
import VueSelect from 'vue-select';

export default {
  name: 'data-select',
  components: {
    VueSelect,
  },
  props: {
    // Model
    model: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    value: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
  },
  computed: {
    factory() {
      return getFactory(this.model);
    },
    pk() {
      return this.factory.getPrimaryKey();
    },
    tk() {
      return this.factory.getTitleKey();
    },
  },
  data() {
    return {
      selected: this.value,
      response: [],
      loading: true,
      error: null,
    };
  },
  methods: {
    reduce(storage) {
      return storage.pk;
    },
    async fetchData() {
      try {
        this.loading = true;
        this.error = null;

        const response = await this.factory.keyValuePairs(this.filters);
        this.response = response.map(([pk, label]) => ({ pk, label }));
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    'factory.lastChange': 'fetchData',
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.selected = newValue;
      }
    },
    async filters(newValue, oldValue) {
      if (!deepEqual(newValue, oldValue)) {
        await this.fetchData();
      }
    },
    selected() {
      this.$emit(
        'input',
        this.selected === null ? null : this.selected === undefined ? undefined : this.selected
      );
    },
  },
  async created() {
    this.selected = this.value;
    await this.fetchData();
  },
};
</script>
