import { MODEL_NAME_USER_BILLING_ADDRESS } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import UserBillingAddress from './user-billing-address-class';
import userBillingAddressProperties from './user-billing-address-properties';

/** @extends {DataStorageFactory<UserBillingAddress>} */
class UserBillingAddressFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_USER_BILLING_ADDRESS;

  getSingular = () => 'User Billing Address';
  getPlural = () => 'user billing addresses';

  getIcon = () => 'feather-map-pin';

  getTabs = () => [];

  getFilters = () => [];
}

const userBillingAddressFactory = new UserBillingAddressFactory(
  userBillingAddressProperties,
  UserBillingAddress.prototype
);

export default userBillingAddressFactory;
