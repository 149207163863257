import DataStorageProperties from '../abstract/data-storage-properties';
import int from '../abstract/properties/property-int';
import date from '../abstract/properties/property-date';

const requestDateRangeCountProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  requestCount: int({}),
  requestPaid: date({}),
});

export default requestDateRangeCountProperties;
