import Vue from 'vue';
import Vuex from 'vuex';

import dataForm from './data-components/data-form';
import modal from './modal/modal';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    modal,
    dataForm: dataForm,
  },
});
