import Home from '../views/Home.vue';

const home = {
  path: '/home',
  component: Home,
  redirect: '/dashboard',
  name: 'Home',
};

export default home;
