<template>
  <div>
    <span v-if="loading">Loading ...</span>
    <span v-else-if="error">{{ error }}</span>
    <template v-else>
      <table class="table actions-table">
        <thead>
          <tr>
            <th>Action</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Set profile video
              <abbr
                v-if="isProfileVideo"
                class="text-muted"
                title="This video is already a profile video"
                >?</abbr
              >
            </td>
            <td>
              This video will be the profile video of <em>{{ user.pretty() }}</em
              >.
            </td>
            <td>
              <button
                @click="setProfileVideo"
                class="btn btn-primary btn-small text-white mr-2"
                :disabled="isProfileVideo || disabled"
              >
                Set profile video
              </button>
            </td>
          </tr>
          <tr>
            <td>Update promotional videos</td>
            <td>
              This video will be added to/removed from<br />the promotional videos of
              <em>{{ user.pretty() }}</em>
            </td>
            <td>
              <button
                @click="addPromotionalVideo"
                class="btn btn-primary btn-small text-white mr-2"
                :disabled="canAdd || isProfileVideo || disabled"
              >
                Add
              </button>
              <button
                @click="removePromotionalVideo"
                class="btn btn-primary btn-small text-white mr-2"
                :disabled="!canAdd || disabled"
              >
                Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import userFactory from '../../model/user/user-factory';
import userPromotionalVideoFactory from '../../model/user-promotional-video/user-promotional-video-factory';
import modal from '../../plugins/modal/modal-component';

export default {
  name: 'video-actions-tab',
  props: ['single'],
  data() {
    return {
      loading: true,
      error: null,
      user: null,
      disabled: false,
      promotionalVideos: [],
      ord: 0,
    };
  },
  methods: {
    async getUserAndPromotionalVideos() {
      try {
        this.loading = true;
        this.error = null;
        this.user = await userFactory.pk(this.single.userId);
        this.promotionalVideos = await userPromotionalVideoFactory.fetch({
          'userId.eq': this.user.id,
        });

        this.promotionalVideos.sort((first, second) => {
          if (first.id < second.id) {
            return -1;
          }
          if (first.id > second.id) {
            return 1;
          }
          return 0;
        });
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    async setProfileVideo() {
      try {
        this.disabled = true;
        this.error = null;
        this.user.profileVideoId = this.single.id;
        await this.user.save();
      } catch (e) {
        this.error = e;
      } finally {
        this.disabled = false;
        modal.alert('The profile video has been set!');
      }
    },
    async addPromotionalVideo() {
      try {
        this.disabled = true;
        this.error = null;
        const videoId = this.single.id;
        const userId = this.user.id;
        const promotionalVideosLength = this.promotionalVideos.length;

        // -1 since we want to start from 0 if no videos were added
        let lastOrd = -1;
        if (promotionalVideosLength > 0) {
          lastOrd = this.promotionalVideos[promotionalVideosLength - 1].ord;
        }

        await userPromotionalVideoFactory
          .blank({
            userId,
            videoId,
            ord: lastOrd + 1,
          })
          .save();
      } catch (e) {
        this.error = e;
      } finally {
        this.disabled = false;
        modal.alert('The promotional videos have been updated!');
      }
    },
    async removePromotionalVideo() {
      try {
        this.disabled = true;
        this.error = null;

        const videoToRemove = this.promotionalVideos.find(
          (video) => video.videoId === this.single.id
        );

        await userPromotionalVideoFactory.delete(videoToRemove);
      } catch (e) {
        this.error = e;
      } finally {
        this.disabled = false;
        modal.alert('The promotional videos have been updated!');
      }
    },
  },
  computed: {
    isProfileVideo() {
      return this.single.id === this.user.profileVideoId;
    },
    canAdd() {
      return this.promotionalVideos.find((video) => video.videoId === this.single.id);
    },
  },
  created() {
    this.getUserAndPromotionalVideos();
  },
};
</script>

<style lang="scss">
.actions-table td {
  color: #8f95a3;
}
</style>
