import DataStorageProperties from '../abstract/data-storage-properties';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';
import bool from '../abstract/properties/property-bool';
import text from '../abstract/properties/property-text';
import price from '../abstract/properties/property-price';
import { MODEL_NAME_PRODUCT } from '../../constants';

const productProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  name: string({
    maxLength: 50,
    titleKey: true,
    searchable: true,
  }),
  description: text({
    table: { hidden: true },
  }),
  isAddon: bool(),
  productId: int({
    defaultValue: null,
    references: MODEL_NAME_PRODUCT,
    pretty: 'Parent',
    required: false,
  }),
  isPublic: bool({
    defaultValue: false,
  }),
  requestModifier: string({
    maxLength: 50,
    defaultValue: null,
    required: false,
  }),
  isUserPrice: bool({
    defaultValue: true,
  }),
  price: price({
    required: false,
    min: 0,
    precision: 2,
    canAggregate: true,
    table: {
      formatFn: (price) => `${price} €`,
    },
  }),
  ord: int({
    table: { hidden: true },
    required: false,
  }),
});

export default productProperties;
