<template>
  <form v-if="storage" :id="formId" @submit.prevent="onSubmit" class="showtimes-data-form">
    <template v-for="cfg in config">
      <template
        v-if="
          !cfg.ignore &&
          ((storage.isNew && !cfg.ignoreWhenNew) || (!storage.isNew && !cfg.ignoreWhenUpdate))
        "
      >
        <div :key="cfg.prop" class="form-group col">
          <!-- Label -->
          <label class="col-form-label">
            {{ cfg.label }}
            <abbr v-if="cfg.required" class="text-danger" title="This field is required">*</abbr>
            <abbr v-if="cfg.fieldHint" class="text-muted" :title="cfg.fieldHint">?</abbr>
          </label>

          <!-- Input -->
          <div>
            <component
              v-bind="cfg.componentProps"
              :is="`${cfg.type}`"
              :required="cfg.required"
              :disabled="cfg.readonly || (!storage.isNew && cfg.disableWhenUpdate)"
              v-model="storage[cfg.prop]"
            />
          </div>
        </div>
      </template>
    </template>

    <!-- Submit -->
    <button
      v-if="!hideSubmitButton && canSubmit"
      :disabled="loading"
      type="submit"
      class="btn btn-primary btn-sm submit text-white"
    >
      <img src="../../assets/loading.png" alt="loading" v-if="loading" />
      Save
    </button>
  </form>
</template>

<script>
import { nanoid } from 'nanoid/non-secure';
import BooleanInput from '../inputs/BooleanInput.vue';
import DateInput from '../inputs/DateInput.vue';
import EmailInput from '../inputs/EmailInput.vue';
import NumberInput from '../inputs/NumberInput.vue';
import ObjectInput from '../inputs/ObjectInput.vue';
import PasswordInput from '../inputs/PasswordInput.vue';
import SelectInput from '../inputs/SelectInput.vue';
import TelInput from '../inputs/TelInput.vue';
import TextareaInput from '../inputs/TextareaInput.vue';
import TextInput from '../inputs/TextInput.vue';
import UrlInput from '../inputs/UrlInput.vue';
import PriceInput from '../inputs/PriceInput.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import modal from '../../plugins/modal/modal-component';

export default {
  name: 'model-form',
  components: {
    BooleanInput,
    DateInput,
    EmailInput,
    NumberInput,
    ObjectInput,
    PasswordInput,
    SelectInput,
    TelInput,
    TextareaInput,
    TextInput,
    UrlInput,
    PriceInput,
  },
  props: {
    model: {
      type: String,
      required: true,
    },
    pk: {
      type: [Number, String],
      default: null,
    },
    formId: {
      type: String,
      default: () => nanoid(),
    },
    hideSubmitButton: {
      type: Boolean,
      default: false,
    },
    noRedirect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      factory: 'dataForm/getFactory',
      config: 'dataForm/getConfig',
      storage: 'dataForm/getStorage',
      loading: 'dataForm/isLoading',
      hasChanges: 'dataForm/hasChanges',
      canSubmit: 'dataForm/canSubmit',
    }),
  },
  methods: {
    ...mapMutations({
      setPk: 'dataForm/setPk',
      setModel: 'dataForm/setModel',
      startLoading: 'dataForm/startLoading',
      stopLoading: 'dataForm/stopLoading',
      copyStorageIntoInitial: 'dataForm/copyStorageIntoInitial',
      reset: 'dataForm/reset',
    }),
    ...mapActions({
      fetchStorage: 'dataForm/fetchStorage',
    }),
    async onSubmit() {
      if (this.loading) {
        return; // Prevent multiple submits
      }

      if (!this.canSubmit) {
        return; // Prevent the update of read-only model
      }

      this.startLoading();

      try {
        const wasNew = this.storage.isNew;

        await this.storage.save();
        this.copyStorageIntoInitial();

        if (wasNew && !this.noRedirect) {
          this.$router.push(`${this.factory.getPath()}/${this.storage.pk()}`);
        }

        modal.alert('The changes have been saved!');

        this.$emit('success');
      } catch (err) {
        modal.alert('The changes failed to be saved!');
        this.$emit('error');
      } finally {
        this.stopLoading();
      }
    },
  },
  created() {
    this.setPk(this.pk);
    this.setModel(this.model);
    this.fetchStorage();
  },
  beforeDestroy() {
    this.reset();
  },
  watch: {
    pk(pk) {
      this.setPk(pk);
      this.fetchStorage();
    },
    model(model) {
      this.setModel(model);
      this.fetchStorage();
    },
  },
};
</script>

<style lang="scss">
.showtimes-data-form {
  .form-group.col {
    padding: 12px;

    .col-form-label {
      padding: 0;
      padding-bottom: 8px;
    }
  }

  .btn.btn-primary {
    width: 132px;
    margin-left: 12px;
    margin-top: 12px;
  }
}
</style>
