import { MODEL_NAME_USER_PROMOTIONAL_VIDEO } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import UserPromotionalVideo from './user-promotional-video-class';
import userPromotionalVideoProperties from './user-promotional-video-properties';

/** @extends {DataStorageFactory<UserPromotionalVideo>} */
class UserPromotionalVideoFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_USER_PROMOTIONAL_VIDEO;

  getSingular = () => 'User Promotional Video';
  getPlural = () => 'user promotional videos';

  getIcon = () => 'feather-aperture';

  getTabs = () => [];

  getFilters = () => [];
}

const userPromotionalVideoFactory = new UserPromotionalVideoFactory(
  userPromotionalVideoProperties,
  UserPromotionalVideo.prototype
);

export default userPromotionalVideoFactory;
