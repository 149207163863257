import { MODEL_NAME_FIREBASE_NOTIFICATION_QUEUE } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import FirebaseNotificationQueue from './firebase-notification-queue-class';
import firebaseNotificationQueueProperties from './firebase-notification-queue-properties';

/** @extends {DataStorageFactory<FirebaseNotificationQueue>} */
class FirebaseNotificationQueueFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_FIREBASE_NOTIFICATION_QUEUE;

  getSingular = () => 'Firebase Notification Queue';
  getPlural = () => 'firebase Notification Queues';

  getIcon = () => 'firebase-notification-queue-icon';

  canUpdate = () => false;

  getTabs = () => [];

  getFilters = () => [
    {
      filter: 'success.eq',
      label: 'Filter by the sent status',
      type: 'simple-select-filter',
      description: 'Display emails in the queue based on the sent property.',
      props: {
        options: [
          { label: 'Successfully sent', value: true },
          { label: 'Sending failed', value: false },
        ],
      },
    },
    {
      filter: 'created.range',
      label: 'Filter by the date of creation',
      type: 'date-range-filter',
      description: 'Display Firebase notifications in queue based on their date of creation.',
    },
    {
      filter: 'sent.range',
      label: 'Filter by the date of sending',
      type: 'date-range-filter',
      description: 'Display Firebase notifications in queue based on sent property.',
    },
  ];
}

const firebaseNotificationQueueFactory = new FirebaseNotificationQueueFactory(
  firebaseNotificationQueueProperties,
  FirebaseNotificationQueue.prototype
);

export default firebaseNotificationQueueFactory;
