import DataStorageProperties from '../abstract/data-storage-properties';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';
import date from '../abstract/properties/property-date';
import { MODEL_NAME_CELEBRITY_SUGGESTION } from '../../constants';

const celebritySuggestionEmailProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  celebritySuggestionId: string({
    searchable: true,
    references: MODEL_NAME_CELEBRITY_SUGGESTION,
    pretty: 'Celebrity Suggestion',
  }),
  email: string({
    titleKey: true,
    searchable: true,
  }),
  created: date({
    required: false,
  }),
});

export default celebritySuggestionEmailProperties;
