<template>
  <div>
    <div class="dashboard-stats-cards">
      <div class="card dashboard-stats-card">
        <icon icon="money-icon" class="icon" />
        <div class="content">
          <span class="title">Zostatok na účtoch</span>
          <span class="value">{{ balance }} €</span>
        </div>
      </div>
      <div class="card dashboard-stats-card">
        <icon icon="completed-videos-icon" class="icon" />
        <div class="content">
          <span class="title">Vybavené videá</span>
          <span class="value">{{ completedVideos }}</span>
        </div>
      </div>
      <div class="card dashboard-stats-card">
        <icon icon="recording-success-icon" class="icon" />
        <div class="content">
          <span class="title">Úspešnosť natáčania</span>
          <span class="value">{{ successRate }} %</span>
        </div>
      </div>
      <div class="card dashboard-stats-card">
        <icon icon="recording-time-icon" class="icon" />
        <div class="content">
          <span class="title"> Čas natáčania </span>
          <span class="value">{{ recordingTime }} h</span>
        </div>
      </div>
    </div>
    <div class="card dashboard-requests">
      <div class="dashboard-card-headline">
        <h2>Pending requests</h2>
        <router-link :to="requestFactory.getPath()">
          All requests
          <icon icon="arrow-right-icon" />
        </router-link>
      </div>
      <div class="dashboard-table">
        <data-table
          model="request"
          :filters="{ 'requestStatusId.eq': 1 }"
          hide-new-button
          hide-filters
          hide-aggregates
          :overrideColumns="['title', 'created', 'instructions', 'userId', 'requestStatusId']"
          :userSortOptions="{
            enabled: true,
            initialSortBy: [{ field: 'created', type: 'desc' }],
          }"
        />
      </div>
    </div>
    <div class="dashboard-updates">
      <div class="left">
        <div class="card dashboard-last-videos">
          <div class="dashboard-card-headline">
            <h2>Last videos</h2>
            <router-link :to="videoFactory.getPath()">
              All videos
              <icon icon="arrow-right-icon" />
            </router-link>
          </div>
          <div class="dashboard-videos-grid">
            <span v-if="loading">Loading ...</span>
            <span v-else-if="error"> {{ error }} </span>
            <video-grid-layout v-else-if="videos" :videos="videos" :hasActions="false" />
          </div>
        </div>
        <div class="card dashboard-new-users">
          <div class="dashboard-card-headline">
            <h2>New users</h2>
            <router-link :to="userFactory.getPath()">
              All users
              <icon icon="arrow-right-icon" />
            </router-link>
          </div>
          <div class="dashboard-table">
            <data-table
              :model="userFactory.getModelName()"
              hideAggregates
              hideNewButton
              hideFilters
              :userSortOptions="{
                enabled: true,
                initialSortBy: [{ field: 'created', type: 'desc' }],
              }"
              :limit="5"
              :overrideColumns="[
                'fullname',
                'email',
                'isPublic',
                'isApproved',
                'isVerified',
                'contactVerified',
              ]"
            />
          </div>
        </div>
        <div class="card dashboard-last-payouts">
          <div class="dashboard-card-headline">
            <h2>Last payouts</h2>
            <router-link :to="payoutFactory.getPath()">
              All payouts
              <icon icon="arrow-right-icon" />
            </router-link>
          </div>
          <div class="dashboard-table">
            <data-table
              :model="payoutFactory.getModelName()"
              hideAggregates
              hideNewButton
              hideFilters
              :userSortOptions="{
                enabled: true,
                initialSortBy: [{ field: 'created', type: 'desc' }],
              }"
              :limit="5"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="card dashboard-last-reviews">
          <div class="dashboard-card-headline">
            <h2>Last reviews</h2>
            <router-link to="/orders/reviews">
              All reviews
              <icon icon="arrow-right-icon" />
            </router-link>
          </div>
          <div class="dashboard-last-reviews-grid">
            <span v-if="loading">Loading ...</span>
            <span v-else-if="error"> {{ error }} </span>
            <span v-else-if="reviews.length === 0"> No reviews </span>
            <div v-else v-for="(review, index) in reviews" :key="review.id">
              <div v-if="index > 0" class="reviews-separator"></div>
              <div class="dashboard-review-card">
                <div v-html="displayStars(review.vote)" class="dashboard-stars-row"></div>
                <div class="review-text">{{ review.review }}</div>
                <div class="video-information">
                  <div>
                    <p>Request</p>
                    <router-link :to="requestPath(review)"
                      >Request #{{ review.requestId }}</router-link
                    >
                  </div>
                  <div>
                    <p>Celebrity</p>
                    <router-link :to="userPath(review)">{{ review.userName }}</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  payoutFactory,
  requestFactory,
  userFactory,
  userTotalRecordingTimeFactory,
  userSumBalanceFactory,
  videoFactory,
} from '../../model';
import videoGridLayout from '../../components/layout/VideoGridLayout.vue';
import { isArray } from 'lodash';

export default {
  name: 'dashboard',
  components: {
    videoGridLayout,
  },
  data() {
    return {
      requestFactory,
      videoFactory,
      userFactory,
      payoutFactory,
      videos: null,
      reviews: null,
      users: null,
      payouts: null,
      loading: false,
      error: null,
      balance: 0,
      completedVideos: null,
      successRate: 0,
      recordingTime: 0,
    };
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        this.error = null;

        // fetch the latest four videos
        this.videos = await videoFactory.fetch({
          'isAudio.eq': false,
        });

        if (!isArray(this.videos)) {
          this.videos = [this.videos];
        }

        this.videos.sort((a, b) => {
          return new Date(b.created) - new Date(a.created);
        });

        // save the videos for reviews
        const rawReviews = this.videos;

        // display only 4 videos
        this.videos = this.videos.slice(0, 4);

        // filter the videos that have review
        this.reviews = rawReviews.filter((video) => {
          return video.vote !== null;
        });

        this.reviews = this.reviews.slice(0, 2).map((video) => ({
          requestId: video.requestId,
          userId: video.userId,
          userName: undefined,
          vote: video.vote,
          review: video.review,
          videoId: video.id,
        }));

        for (const review of this.reviews) {
          review['userName'] = await this.fetchUserName(review.userId);
        }

        // fetch the sum of balance_usable for all users - "Zostatok na účtoch"
        const fetchedBalance = (await userSumBalanceFactory.fetch())[0];
        this.balance = fetchedBalance.sumBalanceUsable;

        // fetch the number of completed videos - "Vybavené videá"
        this.completedVideos = (await videoFactory.fetch()).length;

        // fetch the shooting success rate - "Úspešnosť natáčania"
        this.successRate = (
          (this.completedVideos / (await requestFactory.fetch()).length) *
          100
        ).toFixed(2);

        // fetch the number of hours the videos were recorded - "Čas natáčania"
        const fetchedRecordingTime = (await userTotalRecordingTimeFactory.fetch())[0];

        this.recordingTime = parseFloat(fetchedRecordingTime.totalHours).toFixed(2);
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
        this.error = null;
      }
    },
    async fetchUserName(userId) {
      try {
        this.userLoading = true;
        this.userError = null;
        const user = await userFactory.pk(userId);
        return user.fullname;
      } catch (e) {
        this.userError = e;
      } finally {
        this.userLoading = false;
      }
    },
    displayStars(stars) {
      const unfilledStar =
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.97664 0.776352C7.29875 -0.258784 8.70125 -0.258784 9.02336 0.776353L10.1742 4.47484C10.3183 4.93777 10.7314 5.2512 11.1976 5.2512H14.9219C15.9643 5.2512 16.3977 6.64396 15.5544 7.28371L12.5413 9.56951C12.1642 9.85561 12.0064 10.3627 12.1505 10.8257L13.3013 14.5242C13.6234 15.5593 12.4888 16.4201 11.6455 15.7803L8.63247 13.4945C8.25534 13.2084 7.74466 13.2084 7.36753 13.4945L4.35449 15.7803C3.5112 16.4201 2.37655 15.5593 2.69866 14.5242L3.84954 10.8257C3.99359 10.3627 3.83579 9.85561 3.45866 9.56951L0.445614 7.28371C-0.397678 6.64396 0.0357171 5.2512 1.07808 5.2512H4.80241C5.26857 5.2512 5.68171 4.93777 5.82576 4.47484L6.97664 0.776352Z" fill="#5C6270"/></svg>';

      const filledStar =
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.97664 0.776352C7.29875 -0.258784 8.70125 -0.258784 9.02336 0.776353L10.1742 4.47484C10.3183 4.93777 10.7314 5.2512 11.1976 5.2512H14.9219C15.9643 5.2512 16.3977 6.64396 15.5544 7.28371L12.5413 9.56951C12.1642 9.85561 12.0064 10.3627 12.1505 10.8257L13.3013 14.5242C13.6234 15.5593 12.4888 16.4201 11.6455 15.7803L8.63247 13.4945C8.25534 13.2084 7.74466 13.2084 7.36753 13.4945L4.35449 15.7803C3.5112 16.4201 2.37655 15.5593 2.69866 14.5242L3.84954 10.8257C3.99359 10.3627 3.83579 9.85561 3.45866 9.56951L0.445614 7.28371C-0.397678 6.64396 0.0357171 5.2512 1.07808 5.2512H4.80241C5.26857 5.2512 5.68171 4.93777 5.82576 4.47484L6.97664 0.776352Z" fill="#EFAD2B"/></svg>';

      const starsArray = [];
      for (let i = 0; i < stars; i++) {
        starsArray.push(filledStar);
      }

      if (stars < 5) {
        for (let i = 0; i < 5 - stars; i++) {
          starsArray.push(unfilledStar);
        }
      }

      return starsArray.join('');
    },
    requestPath(review) {
      return `/admin/#/model/request/${review.requestId}`;
    },
    userPath(review) {
      return `/admin/#/model/user/${review.userId}`;
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
.dashboard-stats-cards {
  display: flex;
  flex-direction: row;
  gap: 16px;

  .dashboard-stats-card {
    width: calc(100% / 4);
    min-height: 108px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    border-radius: 4px;

    .content {
      display: flex;
      flex-direction: column;

      .title {
        color: #8f95a3;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }

      .value {
        color: #ffffff;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }
    }
  }
}

.dashboard-card-headline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  a {
    color: #8f95a3;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    &:hover {
      color: #ffffff;
      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
  }
}

.dashboard-requests {
  background-color: #121417;
  border: 1px solid #0b0c0e;
  border-radius: 4px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 16px;
}
.dashboard-table {
  margin-top: 12px;

  .showtime-data-table .vgt-wrap__footer {
    display: none !important;
  }
  .table .vgt-wrap {
    padding: 0 !important;
  }
}

.dashboard-updates {
  display: grid;
  grid-template-columns: 3.25fr 1fr;
  column-gap: 16px;
  margin-top: 16px;
  .left {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .dashboard-videos-grid {
      margin-top: 12px;
    }
  }
  .right {
    .dashboard-last-reviews-grid {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-top: 12px;
      .reviews-separator {
        height: 1px;
        background-color: #000000;
        margin-bottom: 12px;
      }
      .dashboard-review-card {
        border-radius: 4px;
        padding: 8px 12px;
        &:hover {
          background-color: #191b1f;
        }

        .dashboard-stars-row {
          display: flex;
          flex-direction: row;
          gap: 8px;
          padding: 4px;
        }

        .review-text {
          padding-top: 8px;
          color: white;
          font-size: 14px;
          line-height: 24px;
        }

        .video-information {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding-top: 24px;

          p {
            font-size: 12px;
            line-height: 16px;
            color: #8f95a3;
          }
          a {
            text-decoration: underline;
            font-size: 14px;
            line-height: 24px;
            color: #b3bdff;
            &:hover {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
</style>
