<template>
  <card class="short" title="Sms editor" description="Allows to edit sms.">
    <template-editor :model="MODEL_NAME_SMS_TEMPLATE" @submit="sendSms" />
  </card>
</template>

<script>
import { MODEL_NAME_SMS_TEMPLATE } from '../constants.js';
import { smsQueueFactory } from '../model/index.js';
import { mapMutations } from 'vuex';
import replaceMultiple from '../utils/replace-multiple.js';

export default {
  name: 'page-sms-editor',
  data() {
    return {
      MODEL_NAME_SMS_TEMPLATE,
    };
  },
  methods: {
    ...mapMutations({
      setStorage: 'dataForm/setStorage',
    }),
    sendSms({ templateVariables, templateContent }) {
      const smsQueueStorage = smsQueueFactory.blank({
        body: replaceMultiple(templateContent.body, templateVariables),
      });

      this.setStorage(smsQueueStorage);

      this.$router.push(`${smsQueueFactory.getPath()}/new`);
    },
  },
};
</script>

<style></style>
