import DataStorageProperties from '../abstract/data-storage-properties';
import date from '../abstract/properties/property-date';
import int from '../abstract/properties/property-int';

const userDateRangeCountProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  userCount: int({}),
  registered: date({}),
  notApprovedCount: int({}),
  isApprovedCount: int({}),
});

export default userDateRangeCountProperties;
