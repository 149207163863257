<template>
  <data-table
    :model="MODEL_NAME_PRODUCT"
    hideNewButton
    hideEditButton
    hideDeleteButton
    hideFilters
    hideAggregates
    :filters="{ 'productId.eq': single.pk() }"
    :overrideColumns="['name']"
  />
</template>

<script>
import { MODEL_NAME_PRODUCT } from '../../constants';

export default {
  name: 'product-addons-tab',
  data() {
    return {
      MODEL_NAME_PRODUCT,
    };
  },
  props: ['single'],
};
</script>
