<template>
  <li class="nav-item dropdown nav-profile">
    <a
      id="profileDropdownToggle"
      class="nav-link dropdown-toggle"
      href="#"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click="toggleProfile"
    >
      <img src="../../assets/avatar.png" alt="profile" />
    </a>
    <div
      :style="{ display: profileVisible ? 'block' : '' }"
      class="dropdown-menu"
      id="profileDropdown"
    >
      <div class="dropdown-header d-flex flex-column align-items-center">
        <div class="figure">
          <img src="../../assets/avatar-large.png" alt="" />
        </div>
        <div class="info text-center">
          <p class="name">{{ session.name }}</p>
          <p class="email">{{ session.email }}</p>
        </div>
      </div>
      <div class="dropdown-body">
        <ul class="profile-nav p-0">
          <li class="nav-item">
            <router-link :to="`/user/${session.id}`" class="top-nav-link">
              <icon icon="non-public-user-icon" />
              <span>User detail</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/change-password" class="top-nav-link">
              <icon icon="lock-icon" />
              <span>Change password</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="dropdown-logout">
        <li class="nav-item">
          <a class="top-nav-link" href="#logout" @click.prevent="logout">
            <icon icon="logout-icon" />
            <span>Log Out</span>
          </a>
        </li>
      </div>
    </div>
  </li>
</template>

<script>
import session from '../../api/session';

export default {
  name: 'navbar-nav-profile',
  data() {
    return {
      session,
      profileVisible: false,
      windowClickHandler: null,
    };
  },
  methods: {
    logout() {
      session.logout();
    },
    toggleProfile() {
      this.profileVisible = !this.profileVisible;
    },
  },
  created() {
    this.windowClickHandler = (e) => {
      if (!this.profileVisible) {
        return; // Nothing to do here
      }

      /** @type {HTMLElement} */
      let element = e.target;

      while (element != null) {
        if (element.id === 'profileDropdown' || element.id === 'profileDropdownToggle') {
          return; // Do not close the dropdown
        }

        element = element.parentElement;
      }

      this.profileVisible = false;
    };

    window.addEventListener('click', this.windowClickHandler);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.windowClickHandler);
  },
};
</script>

<style lang="scss">
#profileDropdown {
  top: 54px;

  .figure {
    margin-bottom: 16px;
  }
  .info {
    padding-bottom: 24px;
    .name {
      color: #ffffff;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
    }

    .email {
      color: #8f95a3;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .dropdown-body,
  .dropdown-logout {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-top: 8px;
  }

  .dropdown-body {
    border-bottom: 1px solid #0d0e10;
  }

  .top-nav-link {
    &:hover {
      background: #2b2f36;
      color: #ffffff;
      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
    display: flex;
    align-items: center;
    gap: 16px;

    border-radius: 4px;
    padding: 8px;
    height: 40px;
    width: 100%;
    color: #8f95a3;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }
}

.navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-header {
  border-bottom: 1px solid #0d0e10 !important;
  padding: 16px 16px 8px !important;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-body
  .profile-nav
  .nav-item {
  width: 100% !important;
  margin: 0px !important;
}

.navbar
  .navbar-content
  .navbar-nav
  .nav-item.nav-profile
  .dropdown-menu
  .dropdown-header
  .figure
  img {
  padding: 0px !important;
  height: 64px !important;
  width: 64px !important;
}

.navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu {
  &::before {
    content: '';
    border: none !important;
    height: 0px !important;
  }
  width: 224px !important;
  max-width: 224px !important;
  background: #121417 !important;
  padding: 0px !important;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.32) !important;
  border-radius: 0px 0px 4px 4px !important;
}

.navbar .navbar-content .navbar-nav .nav-item.nav-profile .dropdown-menu {
  width: 224px !important;
  max-width: 224px !important;
}
</style>
