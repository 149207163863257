import DataStorageProperties from '../abstract/data-storage-properties';
import date from '../abstract/properties/property-date';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';
import text from '../abstract/properties/property-text';
import object from '../abstract/properties/property-object';

const documentTemplateVersionProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  slug: string({
    titleKey: true,
    maxLength: 50,
    searchable: true,
  }),
  type: string({
    maxLength: 50,
    searchable: true,
  }),
  version: int({}),
  created: date({
    defaultValue: () => new Date(),
  }),
  contents: text({
    required: false,
    table: { hidden: true },
    form: { hidden: true },
  }),
  schema: object({
    required: false,
    table: { hidden: true },
  }),
});

export default documentTemplateVersionProperties;
