import { MODEL_NAME_USER_RECORDING_TIME } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import UserRecordingTime from './user-recording-time-class';
import userRecordingTimeProperties from './user-recording-time-properties';

/** @extends {DataStorageFactory<UserRecordingTime>} */
class UserRecordingTimeFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_USER_RECORDING_TIME;

  getSingular = () => 'User Recording Time';
  getPlural = () => 'user Recording Time';

  getIcon = () => 'feather-tag';

  getTabs = () => [];

  getFilters = () => [];

  canDelete = () => false;
  canInsert = () => false;
  canUpdate = () => false;
}

const userRecordingTimeFactory = new UserRecordingTimeFactory(
  userRecordingTimeProperties,
  UserRecordingTime.prototype
);

export default userRecordingTimeFactory;
