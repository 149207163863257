import { MODEL_NAME_DOCUMENT_TEMPLATE_VERSION } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import DocumentTemplateVersion from './document-template-version-class';
import documentTemplateVersionProperties from './document-template-version-properties';

/** @extends {DataStorageFactory<DocumentTemplateVersion>} */
class DocumentTemplateVersionFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_DOCUMENT_TEMPLATE_VERSION;

  getSingular = () => 'Document Template Version';
  getPlural = () => 'document template versions';

  getIcon = () => 'feather-align-center';

  getTabs = () => [
    {
      name: 'Download',
      slug: 'download',
      description: 'Download the contents of the template.',
    },
  ];
}

const documentTemplateVersionFactory = new DocumentTemplateVersionFactory(
  documentTemplateVersionProperties,
  DocumentTemplateVersion.prototype
);

export default documentTemplateVersionFactory;
