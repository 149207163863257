import { MODEL_NAME_CHARITY } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import Charity from './charity-class';
import charityProperties from './charity-properties';

/** @extends {DataStorageFactory<Charity>} */
class CharityFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_CHARITY;

  getSingular = () => 'Charity';
  getPlural = () => 'charities';

  getIcon = () => 'charity-icon';

  getTabs = () => [
    {
      name: 'Users Supporting Charity',
      slug: 'users',
      description: 'The list of users supporting this charity.',
    },
  ];
}

const charityFactory = new CharityFactory(charityProperties, Charity.prototype);

export default charityFactory;
