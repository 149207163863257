<template>
  <div>
    <div class="custom-view-content">
      <div class="icon">
        <icon icon="completed-requests-icon" />
      </div>
      <div>
        <h1>Completed requests</h1>
        <p class="model-description">The list of completed requests.</p>
      </div>
    </div>
    <data-table
      :model="MODEL_NAME_REQUEST"
      hideNewButton
      hideEditButton
      hideDeleteButton
      hideAggregates
      hideFilters
      :filters="{ 'requestStatusId.eq': 2 }"
      :userSortOptions="{
        enabled: true,
        initialSortBy: [{ field: 'created', type: 'desc' }],
      }"
    />
  </div>
</template>

<script>
import { MODEL_NAME_REQUEST } from '../../constants';

export default {
  name: 'completed-requests',
  props: ['single'],
  data() {
    return {
      MODEL_NAME_REQUEST,
    };
  },
};
</script>
