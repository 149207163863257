<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  name: 'bar-chart',
  props: ['datasets', 'labels', 'maxValue'],
  mounted() {
    this.renderChart(
      {
        labels: this.labels,
        datasets: this.datasets,
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                max: this.maxValue,
              },
            },
          ],
        },
      }
    );
  },
  watch: {
    datasets() {
      this.$data._datasets.update();
    },
    labels() {
      this.$data._labels.update();
    },
    maxValue() {
      this.$data._maxValue.update();
    },
  },
};
</script>
