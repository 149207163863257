import DataStorageProperties from '../abstract/data-storage-properties';
import date from '../abstract/properties/property-date';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';
import text from '../abstract/properties/property-text';

const contactFormMessageProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  subject: string({
    titleKey: true,
  }),
  contactEmail: string({}),
  body: text({
    table: { hidden: true },
  }),
  created: date({
    required: false,
  }),
});

export default contactFormMessageProperties;
