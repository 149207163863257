import { modelExists } from '.';
import connector from '../api/connector';

/**
 * Represents a single search request/response.
 */
class Search {
  /**
   * Creates a new Search request for the specified query and sends it.
   *
   * @param {string} q
   * @param {number} limit
   *
   * @returns {Promise<Search>}
   */
  static async forQuery(q, limit) {
    const search = new Search(q, limit);
    await search.fetch();
    return search;
  }

  /**
   * Sends the request and handles the response.
   */
  async fetch() {
    const data = await connector.get(`/search?q=${encodeURIComponent(this.q)}`);

    Object.entries(data.storages).forEach(([model, results]) => {
      if (modelExists(model) && results.length > 0) {
        this.results[model] = results;
        this.resultsBefore[model] = this.total;
        this.total += results.length;
        this.visible += Math.min(results.length, this.limit);
      }
    });
  }

  /**
   * Creates a new instance of Search.
   *
   * @param {string} q     The search query
   * @param {number} limit Maximal number of visible results
   */
  constructor(q, limit) {
    /**
     * The search query.
     *
     * @type {string}
     */
    this.q = q;

    /**
     * Maximal number of visible results.
     *
     * @type {number}
     */
    this.limit = limit;

    /**
     * Map from model name to an array of data storages which match the search
     * query. The data storages are raw - `factory.blank` must be called to
     * turn them into actual typed instances.
     *
     * @type {Object<string, Object>}
     */
    this.results = {};

    /**
     * Map from model name to the number of visible results before this model.
     */
    this.resultsBefore = {};

    /**
     * Total number of results.
     */
    this.total = 0;

    /**
     * Number of visible results.
     */
    this.visible = 0;
  }
}

export default Search;
