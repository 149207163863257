import connector from '../../api/connector';
import UserProductTreeNode from './user-product-tree-node';

class UserProductTree {
  static async forUser(userId) {
    const raw = await connector.get(`/custom/user/${userId}/products-tree`);

    const nodes = raw.map((node) => UserProductTreeNode.create(node, userId));

    return new UserProductTree(userId, nodes);
  }

  async save() {
    console.log(Array.from(this.allNodes));

    await connector.put(
      `/custom/user/${this.userId}/products-tree`,
      Array.from(this.allNodes).map((node) => ({
        price: node.price,
        productId: node.productId,
        enabled: node.enabled,
        isActive: node.isActive,
      }))
    );
  }

  *_allNodesImpl() {
    for (const node of this.nodes) {
      for (const child of node.children) {
        yield child;
      }

      yield node;
    }
  }

  get allNodes() {
    return this._allNodesImpl();
  }

  constructor(userId, nodes) {
    this.userId = userId;

    /**
     * @type {Array<UserProductTreeNode>}
     */
    this.nodes = nodes;
  }
}

export default UserProductTree;
