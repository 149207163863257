<template>
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.9946 1C4.57596 1 1 3.58544 1 6.76092C1 8.4023 1.95071 9.87203 3.474 10.9234L1.89669 15L7.09318 12.3502C7.70898 12.4575 8.34639 12.5218 9.0054 12.5218C13.424 12.5218 17 9.9364 17 6.76092C17 3.58544 13.424 1 9.0054 1H8.9946Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'sms-queue-icon',
};
</script>
