import DataStorageProperties from '../abstract/data-storage-properties';
import int from '../abstract/properties/property-int';
import price from '../abstract/properties/property-price';

const userRecordingTimeProperties = DataStorageProperties.create({
  userId: int({}),
  // type - price is a workaround, we don't have defined float/number type
  totalHours: price({}),
  avgHours: price({}),
  minHours: price({}),
  maxHours: price({}),
});

export default userRecordingTimeProperties;
