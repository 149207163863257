<template>
  <vue-select :options="options" v-model="selected" />
</template>

<script>
import VueSelect from 'vue-select';

export default {
  name: 'simple-select-filter',
  components: {
    VueSelect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    clear: {
      required: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    selected(newValue) {
      this.$emit('input', newValue === null ? null : newValue.value);
    },
    clear() {
      this.selected = null;
    },
  },
};
</script>

<style></style>
