/** @typedef {import('./data-storage-factory').default} DataStorageFactory */

/**
 * Abstract class used as a base for every data storage.
 *
 * DataStorage stores the actual and initial values of the object properties.
 */
class DataStorage {
  /**
   * Current properties.
   *
   * @type {Object}
   */
  _props;

  /**
   * Original properties - assigned in the constructor.
   *
   * @type {Object}
   */
  _original;

  /**
   * The factory which created this DataStorage.
   *
   * @type {DataStorageFactory}
   */
  _factory;

  /**
   * Returns the value saved under the primary key.
   *
   * @returns {string|number}
   */
  pk() {
    return this[this._factory.getPrimaryKey()];
  }

  /**
   * Returns the value saved under the title key.
   *
   * @return {string}
   */
  tk() {
    return this[this._factory.getTitleKey()];
  }

  /**
   * Converts the storage to string with a pretty formatting. Not all
   * properties must be used, but all searchable properties should be used.
   *
   * This method SHOULD be overridden.
   *
   * @returns {string}
   */
  pretty() {
    return this.tk();
  }

  /**
   * `true` if this is a newly created DataStorage by this app - not yet in db.
   *
   * @type {boolean}
   */
  get isNew() {
    return this.pk() == undefined;
  }

  /**
   * Saves the DataStorage to database.
   */
  async save() {
    await this._factory.save(this);

    return this;
  }

  /**
   * Deletes the DataStorage from database.
   */
  async delete() {
    await this._factory.delete(this);

    return this;
  }

  /**
   * Creates a new instance of DataStorage.
   *
   * @param {Object} props
   * @param {DataStorageFactory} factory
   */
  constructor(props, factory) {
    // Factory
    Object.defineProperty(this, '_factory', {
      configurable: false,
      enumerable: false,
      writable: false,
      value: factory,
    });

    // Properties
    Object.defineProperty(this, '_props', {
      configurable: false,
      enumerable: false,
      writable: false,
      value: {},
    });

    // Values
    factory.properties.forEach(({ prop, defaultValue }) => {
      if (props[prop] === undefined) {
        this._props[prop] = defaultValue instanceof Function ? defaultValue() : defaultValue;
      } else {
        this._props[prop] = props[prop];
      }
    });

    // Original
    Object.defineProperty(this, '_original', {
      configurable: false,
      enumerable: false,
      writable: false,
      value: { ...this._props },
    });
  }
}

export default DataStorage;
