import DataStorageProperties from '../abstract/data-storage-properties';
import bool from '../abstract/properties/property-bool';
import int from '../abstract/properties/property-int';
import { MODEL_NAME_CHARITY, MODEL_NAME_USER } from '../../constants';
import date from '../abstract/properties/property-date';
import price from '../abstract/properties/property-price';

const userCharityLogProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  userId: int({
    searchable: true,
    references: MODEL_NAME_USER,
    pretty: 'User',
  }),
  charityId: int({
    searchable: true,
    references: MODEL_NAME_CHARITY,
    pretty: 'Charity',
  }),
  timeStart: date({}),
  timeEnd: date({
    required: false,
  }),
  amount: price({
    defaultValue: 0,
  }),
  isClosed: bool({
    defaultValue: false,
  }),
});

export default userCharityLogProperties;
