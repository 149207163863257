var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dashboard-stats-cards"},[_c('div',{staticClass:"card dashboard-stats-card"},[_c('icon',{staticClass:"icon",attrs:{"icon":"money-icon"}}),_c('div',{staticClass:"content"},[_c('span',{staticClass:"title"},[_vm._v("Zostatok na účtoch")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.balance)+" €")])])],1),_c('div',{staticClass:"card dashboard-stats-card"},[_c('icon',{staticClass:"icon",attrs:{"icon":"completed-videos-icon"}}),_c('div',{staticClass:"content"},[_c('span',{staticClass:"title"},[_vm._v("Vybavené videá")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.completedVideos))])])],1),_c('div',{staticClass:"card dashboard-stats-card"},[_c('icon',{staticClass:"icon",attrs:{"icon":"recording-success-icon"}}),_c('div',{staticClass:"content"},[_c('span',{staticClass:"title"},[_vm._v("Úspešnosť natáčania")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.successRate)+" %")])])],1),_c('div',{staticClass:"card dashboard-stats-card"},[_c('icon',{staticClass:"icon",attrs:{"icon":"recording-time-icon"}}),_c('div',{staticClass:"content"},[_c('span',{staticClass:"title"},[_vm._v(" Čas natáčania ")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.recordingTime)+" h")])])],1)]),_c('div',{staticClass:"card dashboard-requests"},[_c('div',{staticClass:"dashboard-card-headline"},[_c('h2',[_vm._v("Pending requests")]),_c('router-link',{attrs:{"to":_vm.requestFactory.getPath()}},[_vm._v(" All requests "),_c('icon',{attrs:{"icon":"arrow-right-icon"}})],1)],1),_c('div',{staticClass:"dashboard-table"},[_c('data-table',{attrs:{"model":"request","filters":{ 'requestStatusId.eq': 1 },"hide-new-button":"","hide-filters":"","hide-aggregates":"","overrideColumns":['title', 'created', 'instructions', 'userId', 'requestStatusId'],"userSortOptions":{
          enabled: true,
          initialSortBy: [{ field: 'created', type: 'desc' }],
        }}})],1)]),_c('div',{staticClass:"dashboard-updates"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"card dashboard-last-videos"},[_c('div',{staticClass:"dashboard-card-headline"},[_c('h2',[_vm._v("Last videos")]),_c('router-link',{attrs:{"to":_vm.videoFactory.getPath()}},[_vm._v(" All videos "),_c('icon',{attrs:{"icon":"arrow-right-icon"}})],1)],1),_c('div',{staticClass:"dashboard-videos-grid"},[(_vm.loading)?_c('span',[_vm._v("Loading ...")]):(_vm.error)?_c('span',[_vm._v(" "+_vm._s(_vm.error)+" ")]):(_vm.videos)?_c('video-grid-layout',{attrs:{"videos":_vm.videos,"hasActions":false}}):_vm._e()],1)]),_c('div',{staticClass:"card dashboard-new-users"},[_c('div',{staticClass:"dashboard-card-headline"},[_c('h2',[_vm._v("New users")]),_c('router-link',{attrs:{"to":_vm.userFactory.getPath()}},[_vm._v(" All users "),_c('icon',{attrs:{"icon":"arrow-right-icon"}})],1)],1),_c('div',{staticClass:"dashboard-table"},[_c('data-table',{attrs:{"model":_vm.userFactory.getModelName(),"hideAggregates":"","hideNewButton":"","hideFilters":"","userSortOptions":{
              enabled: true,
              initialSortBy: [{ field: 'created', type: 'desc' }],
            },"limit":5,"overrideColumns":[
              'fullname',
              'email',
              'isPublic',
              'isApproved',
              'isVerified',
              'contactVerified' ]}})],1)]),_c('div',{staticClass:"card dashboard-last-payouts"},[_c('div',{staticClass:"dashboard-card-headline"},[_c('h2',[_vm._v("Last payouts")]),_c('router-link',{attrs:{"to":_vm.payoutFactory.getPath()}},[_vm._v(" All payouts "),_c('icon',{attrs:{"icon":"arrow-right-icon"}})],1)],1),_c('div',{staticClass:"dashboard-table"},[_c('data-table',{attrs:{"model":_vm.payoutFactory.getModelName(),"hideAggregates":"","hideNewButton":"","hideFilters":"","userSortOptions":{
              enabled: true,
              initialSortBy: [{ field: 'created', type: 'desc' }],
            },"limit":5}})],1)])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"card dashboard-last-reviews"},[_c('div',{staticClass:"dashboard-card-headline"},[_c('h2',[_vm._v("Last reviews")]),_c('router-link',{attrs:{"to":"/orders/reviews"}},[_vm._v(" All reviews "),_c('icon',{attrs:{"icon":"arrow-right-icon"}})],1)],1),_c('div',{staticClass:"dashboard-last-reviews-grid"},[(_vm.loading)?_c('span',[_vm._v("Loading ...")]):(_vm.error)?_c('span',[_vm._v(" "+_vm._s(_vm.error)+" ")]):(_vm.reviews.length === 0)?_c('span',[_vm._v(" No reviews ")]):_vm._l((_vm.reviews),function(review,index){return _c('div',{key:review.id},[(index > 0)?_c('div',{staticClass:"reviews-separator"}):_vm._e(),_c('div',{staticClass:"dashboard-review-card"},[_c('div',{staticClass:"dashboard-stars-row",domProps:{"innerHTML":_vm._s(_vm.displayStars(review.vote))}}),_c('div',{staticClass:"review-text"},[_vm._v(_vm._s(review.review))]),_c('div',{staticClass:"video-information"},[_c('div',[_c('p',[_vm._v("Request")]),_c('router-link',{attrs:{"to":_vm.requestPath(review)}},[_vm._v("Request #"+_vm._s(review.requestId))])],1),_c('div',[_c('p',[_vm._v("Celebrity")]),_c('router-link',{attrs:{"to":_vm.userPath(review)}},[_vm._v(_vm._s(review.userName))])],1)])])])})],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }