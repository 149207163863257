<script>
import { Pie } from 'vue-chartjs';

export default {
  extends: Pie,
  name: 'pie-chart',
  props: ['chart', 'options', 'labels', 'chartLabel', 'backgroundColor'],
  mounted() {
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: this.chartLabel,
            backgroundColor: this.backgroundColor,
            data: this.chart,
          },
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: false,
      }
    );
  },
  watch: {
    chart() {
      this.$data._chart.update();
    },
    labels() {
      this.$data._labels.update();
    },
  },
};
</script>
