<template>
  <div>
    <span v-if="loading">{{ 'Loading ...' }}</span>
    <span v-else-if="error">{{ error }}</span>
    <template v-else>
      <table class="table actions-table">
        <thead>
          <tr>
            <th>Action</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Send final reminder</td>
            <td>Lorem ipsum dolor sit amet</td>
            <td>
              <button
                @click="sendSms"
                class="btn btn-primary btn-small text-white mr-2"
                :disabled="disabled"
              >
                Send SMS
              </button>
              <button
                @click="sendEmail"
                class="btn btn-primary btn-small text-white"
                :disabled="disabled"
              >
                Send Email
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import smsQueueFactory from '../../model/sms-queue/sms-queue-factory';
import userFactory from '../../model/user/user-factory';
import emailQueueFactory from '../../model/email-queue/email-queue-factory';
import { mapMutations } from 'vuex';

export default {
  name: 'request-actions-tab',
  props: ['single'],
  data() {
    return {
      loading: true,
      error: null,
      user: null,
    };
  },
  methods: {
    ...mapMutations({
      setStorage: 'dataForm/setStorage',
    }),
    async getUser() {
      try {
        this.loading = true;
        this.error = null;
        this.user = await userFactory.pk(this.single.userId);
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    sendSms() {
      const smsQueueStorage = smsQueueFactory.blank({
        // TODO phone number with spaces may create trouble
        // TODO load these from db
        to: this.user.phone,

        body: `Už len 24 hodín na natočenie videa pre ${this.single.recipientName}.`,
      });

      this.setStorage(smsQueueStorage);

      this.$router.push(`${smsQueueFactory.getPath()}/new`);
    },

    sendEmail() {
      const body = `Ahoj ${this.user.fullname},

chceme ti len pripomenúť, že ostávajú už len 24 hodin na vybavenie požiadavky na natočenie videa. Vieme, že si určite pracovne vyťažený, ale nahranie videa Vám zaberie len pár minút a objednávateľa určite veľmi poteší.`;

      const emailQueueStorage = emailQueueFactory.blank({
        to: this.user.email,
        subject: 'Už len 24 hodín na natočenie videa',
        textBody: body,
        htmlBody: body,
      });

      this.setStorage(emailQueueStorage);

      this.$router.push(`${emailQueueFactory.getPath()}/new`);
    },
  },
  computed: {
    disabled() {
      // The request has been completed, cancelled or is expired
      return this.single.requestStatusId >= 2;
    },
  },
  created() {
    this.getUser();
  },
};
</script>
