<template>
  <div :class="computedClass">
    <!-- Sidebar filters -->
    <div v-if="!hideFilters && factory.getFilters().length > 0">
      <div class="model-filters">
        <template v-for="filter in factory.getFilters()">
          <div class="table-filter-row" :key="filter.filter">
            <label class="table-filter-label">
              {{ filter.label }}
            </label>
            <component
              :is="filter.type"
              v-bind="filter.props"
              @input="handleFilterInput(filter.filter, $event)"
              :clear="clearFiltersNanoid"
              :initialValue="userFilters[filter.filter]"
            />
          </div>
        </template>
        <button @click="clearFilters" class="clear-filters-button btn">
          <icon icon="cancel-icon" />
          <span>Clear filters</span>
        </button>
      </div>
    </div>
    <div>
      <!-- Main table -->
      <vue-good-table
        style-class="vgt-table"
        :search-options="searchOptions"
        :sort-options="sortOptions"
        :pagination-options="paginationOptions"
        :select-options="selectOptions"
        :columns="columns"
        :rows="rows"
      >
        <!-- Table row -->
        <template slot="table-row" slot-scope="p">
          <div style="white-space: nowrap" v-if="p.column.field == tk">
            <div class="row-actions">
              <template v-if="!hideEditButton && factory.canUpdate()">
                <a v-if="onEdit" class="row-action" @click="onEdit(p.row[pk], p.row)">
                  <span>{{ editButtonLabel }}</span>
                </a>
                <router-link v-else class="row-action" :to="`${factory.getPath()}/${p.row[pk]}`">
                  <span>{{ editButtonLabel }}</span>
                </router-link>
              </template>
              <template v-if="!hideDeleteButton && factory.canDelete()">
                <a class="row-action" title="Delete row" @click="_delete(p.row[pk], p.row)">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_54_1164)">
                      <path
                        d="M4.66663 11.3334L11.3334 4.66663"
                        stroke="#FF3333"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.3334 11.3334L4.66663 4.66663"
                        stroke="#FF3333"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_54_1164">
                        <rect width="16" height="16" rx="2" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </template>
            </div>
            <a
              v-if="onDetail"
              :style="{
                cursor: disableDetail ? '' : 'pointer',
              }"
              class="title-key-column"
              @click="_detail(p.row[pk], p.row)"
              target="_blank"
              >{{ p.formattedRow[tk] }}
            </a>
            <router-link
              v-else
              :style="{
                cursor: disableDetail ? '' : 'pointer',
              }"
              class="title-key-column"
              :to="`${factory.getPath()}/${p.row[pk]}`"
              >{{ p.formattedRow[tk] }}
            </router-link>
          </div>
          <data-single-display
            v-else-if="factory.properties[p.column.field].references"
            :model="factory.properties[p.column.field].references"
            :pk="p.row[p.column.field]"
          />
          <template v-else-if="factory.properties[p.column.field].connection">
            <data-single-connection-display
              :model="model"
              :connection="factory.properties[p.column.field].connection"
              :pk="p.row[factory.getPrimaryKey()]"
            />
          </template>
          <div v-else-if="p.column.html" v-html="p.formattedRow[p.column.field]"></div>
          <div v-else>
            {{ p.formattedRow[p.column.field] }}
          </div>
        </template>

        <!-- Empty state -->
        <template slot="emptystate">
          <div>
            <template v-if="error">An error has ocurred.</template>
            <template v-else-if="loading">Loading data...</template>
            <template v-else>{{ emptyText }}</template>
          </div>
        </template>
      </vue-good-table>

      <footer class="showtime-data-table-footer mt-3">
        <button
          v-if="!hideNewButton && factory.canInsert()"
          class="btn btn-sm btn-primary text-white mr-2"
          @click="_new"
        >
          New {{ factory.getSingular() }}
        </button>
      </footer>

      <!-- <div v-if="!hideAggregates && hasAggregateColumns" class="mt-3 pt-3">
        <h5>Aggregates</h5>
        <div
          class="d-flex mt-2"
          v-for="(aggregate, key) in userAggregates"
          :key="`aggregate-row-${key}`"
        >
          <vue-select
            :options="['Min', 'Max', 'Avg', 'Sum']"
            style="width: 100px"
            v-model="aggregate.aggregateFunction"
            :clearable="false"
            @input="maybeAddAggregateRow"
          />
          <span class="bracket">(</span
          ><vue-select
            :options="aggregatedColumns"
            style="width: 200px"
            v-model="aggregate.aggregatedColumn"
            :clearable="false"
            @input="maybeAddAggregateRow"
          /><span class="bracket">) = </span>
          <data-aggregate-display
            :model="model"
            :aggregatedProperty="aggregate.aggregatedColumn"
            :aggregateFunction="aggregate.aggregateFunction"
            :filters="userFilters"
            :reloadToken="lastFetchAttempt"
          >
            <input
              slot-scope="{ value }"
              class="form-control"
              type="text"
              readonly
              style="width: 200px; margin-left: 4px"
              :value="value"
            />
          </data-aggregate-display>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import VueSelect from 'vue-select';
import deepEqual from 'deep-equal';
import { VueGoodTable } from 'vue-good-table';
import getFactory from '../../model';
import SimpleSelectFilter from './data-filters/SimpleSelectFilter.vue';
import DataSelectFilter from './data-filters/DataSelectFilter.vue';
import DateRangeFilter from './data-filters/DateRangeFilter.vue';
import { nanoid } from 'nanoid/non-secure';
import Vue from 'vue';

export default {
  name: 'data-table',
  components: {
    VueGoodTable,
    SimpleSelectFilter,
    DataSelectFilter,
    DateRangeFilter,
    VueSelect,
  },
  props: {
    // Model
    model: {
      type: String,
      required: true,
    },
    // Data properties
    filters: {
      type: Object,
      default: () => ({}),
    },
    userSortOptions: {
      type: Object,
      default: null,
    },
    // the maximum number of displayed rows
    limit: {
      type: Number,
      default: 10,
    },
    displayPagination: {
      type: Boolean,
      default: true,
    },
    // Table settings properties
    showAll: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: 'No data',
    },
    disableDetail: {
      type: Boolean,
      default: false,
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
    hideAggregates: {
      type: Boolean,
      default: false,
    },
    overrideColumns: {
      type: Array,
      default: null,
    },
    // New
    hideNewButton: {
      type: Boolean,
      default: false,
    },
    // Edit
    hideEditButton: {
      type: Boolean,
      default: false,
    },
    editButtonLabel: {
      type: String,
      default: 'Edit',
    },
    // Delete
    hideDeleteButton: {
      type: Boolean,
      default: false,
    },
    deleteButtonLabel: {
      type: String,
      default: 'Delete',
    },
    // Events
    onNew: Function,
    onEdit: Function,
    onDelete: Function,
    onDetail: Function,
  },
  computed: {
    factory() {
      return getFactory(this.model);
    },
    computedClass() {
      const classes = ['showtime-data-table', `showtime-data-table-${this.model}`];

      if (this.hideFilters) {
        classes.push('hide-filters');
      }

      if (this.isPaginated) {
        classes.push('paginated');
      }

      return classes;
    },
    columns() {
      const descriptors = this.factory.tableDescriptor();

      if (this.overrideColumns === null) {
        return descriptors;
      }

      return descriptors.map((descriptor) => ({
        ...descriptor,
        hidden: !this.overrideColumns.includes(descriptor.field),
      }));
    },
    pk() {
      return this.factory.getPrimaryKey();
    },
    tk() {
      return this.factory.getTitleKey();
    },
    isPaginated() {
      return Array.isArray(this.response) && this.response.length > this.limit;
    },
    searchOptions() {
      return {
        enabled: false,
        skipDiacritics: true,
        externalQuery: this.search,
      };
    },
    sortOptions() {
      return this.userSortOptions === null
        ? {
            enabled: true,
            initialSortBy: { field: this.pk, type: 'desc' },
          }
        : this.userSortOptions;
    },
    paginationOptions() {
      return {
        enabled: !this.showAll && Array.isArray(this.response) && this.response.length > 0,
        mode: 'records',
        perPage: this.limit,
        position: 'bottom',
        perPageDropdown: [this.limit],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        infoFn: (params) =>
          this.isPaginated
            ? `Showing ${params.firstRecordOnPage} to ${params.lastRecordOnPage} of ${params.totalRecords} entries`
            : `Showing ${params.totalRecords} of ${params.totalRecords} entries`,
      };
    },
    selectOptions() {
      return {
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
      };
    },
    rows() {
      return (this.response || []).map(({ _props }) => _props);
    },
    hasAggregateColumns() {
      return this.aggregatedColumns.length > 0;
    },
    aggregatedColumns() {
      return this.columns.filter((col) => col.meta.canAggregate).map((col) => col.field);
    },
    shouldDisplayAggregatedColumns() {
      return !this.hideAggregates && this.hasAggregateColumns;
    },
  },
  data() {
    return {
      lastFetchAttempt: null,
      search: '',
      loading: true,
      response: null,
      error: null,
      userFilters: {},
      userAggregates: {},
      clearFiltersNanoid: null,
    };
  },
  methods: {
    async defaultNew() {
      this.$router.push(`${this.factory.getPath()}/new`);
    },
    async defaultEdit(pk) {
      this.$router.push(`${this.factory.getPath()}/${pk}`);
    },
    async defaultDelete(pk) {
      const confirmation = await this.$modal.confirm(
        `Delete ${this.factory.getSingular()} with ID ${pk}?`
      );

      if (confirmation) {
        try {
          this.loading = true;
          this.response = null;
          await this.factory.delete(pk);
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      }
    },
    async defaultDetail(pk) {
      if (!this.disableDetail) {
        this.$router.push(`${this.factory.getPath()}/${pk}`);
      }
    },
    async _new() {
      await (this.onNew || this.defaultNew)();
    },
    async _delete(pk, row) {
      await (this.onDelete || this.defaultDelete)(pk, row);
    },
    async _detail(pk, row) {
      await (this.onDetail || this.defaultDetail)(pk, row);
    },
    clearFilters() {
      this.search = '';
      this.clearFiltersNanoid = nanoid();
    },
    clearAggregates() {
      for (const aggregate of this.factory.getDefaultAggregates()) {
        Vue.set(this.userAggregates, nanoid(), aggregate);
      }

      this.addAggregateRow();
    },
    maybeAddAggregateRow() {
      console.log(this.userAggregates);
      for (const aggregate of Object.values(this.userAggregates)) {
        // empty aggregate row
        if (!aggregate.aggregateFunction && !aggregate.aggregatedColumn) {
          return;
        }
      }

      this.addAggregateRow();
    },
    addAggregateRow() {
      Vue.set(this.userAggregates, nanoid(), { aggregateFunction: null, aggregatedColumn: null });
    },
    async fetchData() {
      this.error = null;
      this.lastFetchAttempt = Date.now();

      try {
        this.loading = true;
        this.response = null;
        this.response = await this.factory.fetch(this.userFilters);
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    handleFilterInput(filter, value) {
      this.userFilters[filter] = value;
      this.fetchData();
    },
  },
  watch: {
    'factory.lastChange': 'fetchData',
    model() {
      this.userFilters = {};
      this.userAggregates = {};
      this.clearFilters();
      this.clearAggregates();
      this.fetchData();
    },
    async filters(newValue, oldValue) {
      if (!deepEqual(newValue, oldValue)) {
        await this.fetchData();
      }
    },
  },
  async created() {
    Object.entries(this.filters).forEach(([key, value]) => Vue.set(this.userFilters, key, value));

    await this.fetchData();

    this.clearAggregates();
  },
};
</script>

<style lang="scss">
$spacing-gap: 15px;

span.bracket {
  font-size: 24px;
}

.showtime-data-table {
  .vgt-responsive {
    background-color: #121417;
    padding: 12px 16px;
    border-radius: 4px;
    overflow-x: auto;
  }

  table.vgt-table {
    border: 0;
    background-color: transparent;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
  }

  table.vgt-table td,
  table.vgt-table th {
    color: inherit;
    padding: 0.75em 0.3em;
  }

  table.vgt-table th span {
    white-space: nowrap;
  }

  .vgt-inner-wrap {
    box-shadow: none;
  }

  .vgt-wrap__footer {
    border: 0;
    padding-left: 6px;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 16px !important;

    background: transparent;
  }

  .vgt-table thead {
    margin-bottom: 12px;
    th {
      height: 40px;
      background: #22252b;
      padding: 0.5em 1.5em 0.5em 0.5em;
      border-bottom: none;

      font-weight: 500;

      color: #ffffff;
    }

    th:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    th:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .vgt-table tbody {
    &::before {
      line-height: 12px;
      content: '\200C';
      display: block;
    }
    tr {
      td {
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 12px;
        padding-bottom: 12px;
        border-bottom: none;
      }
    }
  }
  .add-new-btn {
    margin-right: 10px;
  }

  .row-actions {
    display: none;
  }

  tr:hover .row-actions {
    display: inline-block;
    transition: opacity 80ms ease-in-out;
    color: rgb(160, 160, 160);

    .row-action {
      padding: 3px 5px;

      text-decoration: none;
    }
    a.row-action {
      cursor: pointer;
    }
  }

  tr:hover td {
    background-color: #191b1f;
  }

  th:hover {
    th:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    th:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .title-key-column {
    color: #b3bdff;
    text-decoration: underline;
    &:hover {
      color: #ffffff;
    }
  }

  // Table filters
  .showtime-data-table-filters {
    align-self: flex-start;

    padding: 15px;
    margin-left: $spacing-gap;
    border-radius: 4px;

    background: #f8f9fb;

    .spacer {
      width: 100%;
      height: 1px;

      margin-top: 0.75rem;
      margin-bottom: 0.75rem;

      background: #eceff3;
    }
  }

  .table-filter-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #e8ebf1;

    button {
      padding-top: 0;
      padding-bottom: 0;

      transform: translateX(1rem);
    }
  }

  .footer__row-count {
    display: none;
  }

  .footer__navigation {
    display: grid;
    grid-template-columns: 998fr 1fr 1fr;

    float: none !important;
  }

  .footer__navigation__page-info {
    margin-left: 0 !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #8f95a3 !important;
  }

  .footer__navigation__page-btn {
    display: none !important;

    font-size: 14px !important;
    line-height: 24px !important;

    border-radius: 4px !important;
    height: 40px !important;

    font-weight: 600 !important;
    background: #22252b !important;
    color: #8f95a3 !important;

    cursor: pointer !important;
    transform: translateY(-0.1rem);

    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;

    &:first-of-type {
      padding-right: 12px !important;
      span.chevron::after {
        border-right-color: #8f95a3 !important;
      }
    }

    &:last-of-type {
      padding-left: 16px !important;

      span.chevron::after {
        border-left-color: #8f95a3 !important;
      }
    }

    &.disabled {
      cursor: not-allowed !important;
      background: #121417 !important;
      color: #272a30 !important;
      span.chevron::after {
        border-right-color: #272a30 !important;
        border-left-color: #272a30 !important;
      }
    }
    span {
      font-size: 0.9rem !important;
    }

    span.chevron {
      margin-left: -4px;
      margin-right: -4px;
    }
  }

  &.paginated .footer__navigation__page-btn {
    display: block !important;
  }
}

.model-filters {
  margin-bottom: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 12px;

  .table-filter-row {
    min-width: 202px;
    flex-grow: 0;
    flex-shrink: 0;

    .data-select-area {
      .v-select {
        // override of the styles of the v-select
        // default styles for filter selects
        background-color: #121417;
        border: 1px solid #343842;

        input {
          height: 22px !important;
          background-color: #121417 !important;
          &::placeholder {
            color: #5c6270 !important;
          }
          &:hover {
            &::placeholder {
              color: #8f95a3 !important;
            }
          }
        }
        .vs__dropdown-toggle {
          background-color: #121417 !important;
          border-color: transparent;
        }
      }
    }

    // override of the styles of the v-select
    // default styles for filter selects
    // the filters are either wrapped in .data-select-area or not
    .v-select {
      background-color: #121417;
      border: 1px solid #343842;
      input {
        height: 22px !important;
        background-color: #121417 !important;
        &:focus,
        &:hover {
          border-color: transparent !important;
        }
        &::placeholder {
          color: #5c6270 !important;
        }
      }

      .vs__dropdown-toggle {
        background-color: #121417;

        &:focus-within {
          color: #fff;
          background-color: #121417;
          border-color: #304dff;
          border-width: 1px;
        }

        border-color: transparent;
      }

      .vs__actions {
        svg {
          fill: #8f95a3;
        }
      }

      // hover
      &:hover {
        border-color: #343842;
        border: 1px solid #343842;
        background: #121417;
        input:hover {
          border-color: transparent !important;
        }
      }
    }

    // active
    .vs__selected {
      // TODO add here the blue/purple border
      color: #ffffff !important;
    }

    .vs--open .vs__dropdown-menu {
      background: #121417;
      border: 1px solid #343842;
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.32);
      margin-top: 8px;
      border-radius: 4px;
      padding: 8px;
      li {
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #8f95a3 !important;
        padding-left: 12px !important;
        padding-right: 12px;
        padding-top: 4px;
        padding-bottom: 4px;
        &:hover {
          // TODO the hover is sometimes of different color of blue
          background-color: #304dff;
          color: #ffffff !important;
        }
      }
    }
  }

  .clear-filters-button {
    height: 32px;
    width: 122px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 8px 12px 8px 12px;
    gap: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8f95a3;
    &:hover {
      color: #ffffff;
      svg path {
        stroke: #ffffff;
      }
    }
  }
}

// TODO this is only temporary
.showtime-data-table-admin .showtime-data-table-footer button {
  display: none;
}
</style>
