import { MODEL_NAME_REQUEST_STATUS } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import RequestStatus from './request-status-class';
import requestStatusProperties from './request-status-properties';

/** @extends {DataStorageFactory<RequestStatus>} */
class RequestStatusFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_REQUEST_STATUS;

  getSingular = () => 'Request Status';
  getPlural = () => 'request Statuses';

  getIcon = () => 'feather-bar-chart-2';

  getTabs = () => [];
}

const requestStatusFactory = new RequestStatusFactory(
  requestStatusProperties,
  RequestStatus.prototype
);

export default requestStatusFactory;
