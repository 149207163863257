import { MODEL_NAME_USER_CHARITY_LOG } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import UserCharityLog from './user-charity-log-class';
import userCharityLogProperties from './user-charity-log-properties';

/** @extends {DataStorageFactory<UserCharityLog>} */
class UserCharityLogFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_USER_CHARITY_LOG;

  getSingular = () => 'User Charity Log';
  getPlural = () => 'User Charity Logs';

  getIcon = () => 'feather-user';

  getTabs = () => [];

  getFilters = () => [];
}

const userCharityLogFactory = new UserCharityLogFactory(
  userCharityLogProperties,
  UserCharityLog.prototype
);

export default userCharityLogFactory;
