<template>
  <data-table
    :model="MODEL_NAME_USER_BILLING_ADDRESS"
    hideFilters
    hideNewButton
    :filters="{ 'userId.eq': single.pk() }"
    :overrideColumns="['name', 'address', 'zip', 'city', 'ico', 'dic', 'isVatPayer', 'icdph']"
  />
</template>

<script>
import { MODEL_NAME_USER_BILLING_ADDRESS } from '../../constants';
export default {
  name: 'user-billing-address-tab',
  props: ['single'],
  data() {
    return {
      MODEL_NAME_USER_BILLING_ADDRESS,
    };
  },
};
</script>
