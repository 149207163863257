import { MODEL_NAME_ADMIN } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import Admin from './admin-class';
import adminProperties from './admin-properties';

/** @extends {DataStorageFactory<Admin>} */
class AdminFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_ADMIN;

  getSingular = () => 'Admin';
  getPlural = () => 'admins';

  getIcon = () => 'admin-icon';

  getTabs = () => [];
}

const adminFactory = new AdminFactory(adminProperties, Admin.prototype);

export default adminFactory;
