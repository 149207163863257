<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_211_2486)">
      <path
        d="M10.89 16.72C14.1098 16.72 16.72 14.1098 16.72 10.89C16.72 7.67018 14.1098 5.06 10.89 5.06C7.67018 5.06 5.06 7.67018 5.06 10.89C5.06 14.1098 7.67018 16.72 10.89 16.72Z"
        stroke="#8F95A3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.9999 15L18.9399 18.94"
        stroke="#8F95A3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_211_2486">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'search-icon',
};
</script>
