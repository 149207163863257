<template>
  <div>
    <video controls width="350">
      <source :src="single.webmUrl" type="video/webm" />
      <source :src="single.mp4Url" type="video/mp4" />

      Your browser does not support embedded videos.
    </video>
  </div>
</template>

<script>
export default {
  name: 'video-play-tab',
  props: ['single'],
};
</script>

<style></style>
