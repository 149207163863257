/**
 * Replaces all keys by their values in template
 *
 * @param {string} template
 * @param {Object<string, string>} variables
 * @returns
 */
const replaceMultiple = (template, variables) =>
  Object.entries(variables).reduce(
    (text, [key, value]) => text.replace(new RegExp(key, 'g'), value),
    template
  );
export default replaceMultiple;
