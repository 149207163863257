<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.97664 1.77635C8.29875 0.741216 9.70125 0.741216 10.0234 1.77635L11.1742 5.47484C11.3183 5.93777 11.7314 6.2512 12.1976 6.2512H15.9219C16.9643 6.2512 17.3977 7.64396 16.5544 8.28371L13.5413 10.5695C13.1642 10.8556 13.0064 11.3627 13.1505 11.8257L14.3013 15.5242C14.6234 16.5593 13.4888 17.4201 12.6455 16.7803L9.63247 14.4945C9.25534 14.2084 8.74466 14.2084 8.36753 14.4945L5.35449 16.7803C4.5112 17.4201 3.37655 16.5593 3.69866 15.5242L4.84954 11.8257C4.99359 11.3627 4.83579 10.8556 4.45866 10.5695L1.44561 8.28371C0.602322 7.64396 1.03572 6.2512 2.07808 6.2512H5.80241C6.26857 6.2512 6.68171 5.93777 6.82576 5.47484L7.97664 1.77635Z"
      stroke="#8F95A3"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: 'review-icon',
};
</script>
