import { render, staticRenderFns } from "./FeatherSun.vue?vue&type=template&id=918f648a&"
import script from "./FeatherSun.vue?vue&type=script&lang=js&"
export * from "./FeatherSun.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports