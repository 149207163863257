<template>
  <component :is="icon"></component>
</template>

<script>
import FeatherActivity from './FeatherActivity.vue';
import FeatherAirplay from './FeatherAirplay.vue';
import FeatherAlertCircle from './FeatherAlertCircle.vue';
import FeatherAlertOctagon from './FeatherAlertOctagon.vue';
import FeatherAlertTriangle from './FeatherAlertTriangle.vue';
import FeatherAlignCenter from './FeatherAlignCenter.vue';
import FeatherAlignJustify from './FeatherAlignJustify.vue';
import FeatherAlignLeft from './FeatherAlignLeft.vue';
import FeatherAlignRight from './FeatherAlignRight.vue';
import FeatherAnchor from './FeatherAnchor.vue';
import FeatherAperture from './FeatherAperture.vue';
import FeatherArchive from './FeatherArchive.vue';
import FeatherArrowDownCircle from './FeatherArrowDownCircle.vue';
import FeatherArrowDownLeft from './FeatherArrowDownLeft.vue';
import FeatherArrowDownRight from './FeatherArrowDownRight.vue';
import FeatherArrowDown from './FeatherArrowDown.vue';
import FeatherArrowLeftCircle from './FeatherArrowLeftCircle.vue';
import FeatherArrowLeft from './FeatherArrowLeft.vue';
import FeatherArrowRightCircle from './FeatherArrowRightCircle.vue';
import FeatherArrowRight from './FeatherArrowRight.vue';
import FeatherArrowUpCircle from './FeatherArrowUpCircle.vue';
import FeatherArrowUpLeft from './FeatherArrowUpLeft.vue';
import FeatherArrowUpRight from './FeatherArrowUpRight.vue';
import FeatherArrowUp from './FeatherArrowUp.vue';
import FeatherAtSign from './FeatherAtSign.vue';
import FeatherAward from './FeatherAward.vue';
import FeatherBarChart_2 from './FeatherBarChart_2.vue';
import FeatherBarChart from './FeatherBarChart.vue';
import FeatherBatteryCharging from './FeatherBatteryCharging.vue';
import FeatherBattery from './FeatherBattery.vue';
import FeatherBellOff from './FeatherBellOff.vue';
import FeatherBell from './FeatherBell.vue';
import FeatherBluetooth from './FeatherBluetooth.vue';
import FeatherBold from './FeatherBold.vue';
import FeatherBookOpen from './FeatherBookOpen.vue';
import FeatherBook from './FeatherBook.vue';
import FeatherBookmark from './FeatherBookmark.vue';
import FeatherBox from './FeatherBox.vue';
import FeatherBriefcase from './FeatherBriefcase.vue';
import FeatherCalendar from './FeatherCalendar.vue';
import FeatherCameraOff from './FeatherCameraOff.vue';
import FeatherCamera from './FeatherCamera.vue';
import FeatherCast from './FeatherCast.vue';
import FeatherCheckCircle from './FeatherCheckCircle.vue';
import FeatherCheckSquare from './FeatherCheckSquare.vue';
import FeatherCheck from './FeatherCheck.vue';
import FeatherChevronDown from './FeatherChevronDown.vue';
import FeatherChevronLeft from './FeatherChevronLeft.vue';
import FeatherChevronRight from './FeatherChevronRight.vue';
import FeatherChevronUp from './FeatherChevronUp.vue';
import FeatherChevronsDown from './FeatherChevronsDown.vue';
import FeatherChevronsLeft from './FeatherChevronsLeft.vue';
import FeatherChevronsRight from './FeatherChevronsRight.vue';
import FeatherChevronsUp from './FeatherChevronsUp.vue';
import FeatherChrome from './FeatherChrome.vue';
import FeatherClipboard from './FeatherClipboard.vue';
import FeatherClock from './FeatherClock.vue';
import FeatherCloudDrizzle from './FeatherCloudDrizzle.vue';
import FeatherCloudLightning from './FeatherCloudLightning.vue';
import FeatherCloudOff from './FeatherCloudOff.vue';
import FeatherCloudRain from './FeatherCloudRain.vue';
import FeatherCloudSnow from './FeatherCloudSnow.vue';
import FeatherCloud from './FeatherCloud.vue';
import FeatherCode from './FeatherCode.vue';
import FeatherCodepen from './FeatherCodepen.vue';
import FeatherCodesandbox from './FeatherCodesandbox.vue';
import FeatherCoffee from './FeatherCoffee.vue';
import FeatherColumns from './FeatherColumns.vue';
import FeatherCommand from './FeatherCommand.vue';
import FeatherCompass from './FeatherCompass.vue';
import FeatherCopy from './FeatherCopy.vue';
import FeatherCornerDownLeft from './FeatherCornerDownLeft.vue';
import FeatherCornerDownRight from './FeatherCornerDownRight.vue';
import FeatherCornerLeftDown from './FeatherCornerLeftDown.vue';
import FeatherCornerLeftUp from './FeatherCornerLeftUp.vue';
import FeatherCornerRightDown from './FeatherCornerRightDown.vue';
import FeatherCornerRightUp from './FeatherCornerRightUp.vue';
import FeatherCornerUpLeft from './FeatherCornerUpLeft.vue';
import FeatherCornerUpRight from './FeatherCornerUpRight.vue';
import FeatherCpu from './FeatherCpu.vue';
import FeatherCreditCard from './FeatherCreditCard.vue';
import FeatherCrop from './FeatherCrop.vue';
import FeatherCrosshair from './FeatherCrosshair.vue';
import FeatherDatabase from './FeatherDatabase.vue';
import FeatherDelete from './FeatherDelete.vue';
import FeatherDisc from './FeatherDisc.vue';
import FeatherDollarSign from './FeatherDollarSign.vue';
import FeatherDownloadCloud from './FeatherDownloadCloud.vue';
import FeatherDownload from './FeatherDownload.vue';
import FeatherDroplet from './FeatherDroplet.vue';
import FeatherEdit_2 from './FeatherEdit_2.vue';
import FeatherEdit_3 from './FeatherEdit_3.vue';
import FeatherEdit from './FeatherEdit.vue';
import FeatherExternalLink from './FeatherExternalLink.vue';
import FeatherEyeOff from './FeatherEyeOff.vue';
import FeatherEye from './FeatherEye.vue';
import FeatherFacebook from './FeatherFacebook.vue';
import FeatherFastForward from './FeatherFastForward.vue';
import FeatherFeather from './FeatherFeather.vue';
import FeatherFigma from './FeatherFigma.vue';
import FeatherFileMinus from './FeatherFileMinus.vue';
import FeatherFilePlus from './FeatherFilePlus.vue';
import FeatherFileText from './FeatherFileText.vue';
import FeatherFile from './FeatherFile.vue';
import FeatherFilm from './FeatherFilm.vue';
import FeatherFilter from './FeatherFilter.vue';
import FeatherFlag from './FeatherFlag.vue';
import FeatherFolderMinus from './FeatherFolderMinus.vue';
import FeatherFolderPlus from './FeatherFolderPlus.vue';
import FeatherFolder from './FeatherFolder.vue';
import FeatherFrown from './FeatherFrown.vue';
import FeatherGift from './FeatherGift.vue';
import FeatherGitBranch from './FeatherGitBranch.vue';
import FeatherGitCommit from './FeatherGitCommit.vue';
import FeatherGitMerge from './FeatherGitMerge.vue';
import FeatherGitPullRequest from './FeatherGitPullRequest.vue';
import FeatherGithub from './FeatherGithub.vue';
import FeatherGitlab from './FeatherGitlab.vue';
import FeatherGlobe from './FeatherGlobe.vue';
import FeatherGrid from './FeatherGrid.vue';
import FeatherHardDrive from './FeatherHardDrive.vue';
import FeatherHash from './FeatherHash.vue';
import FeatherHeadphones from './FeatherHeadphones.vue';
import FeatherHeart from './FeatherHeart.vue';
import FeatherHelpCircle from './FeatherHelpCircle.vue';
import FeatherHexagon from './FeatherHexagon.vue';
import FeatherHome from './FeatherHome.vue';
import FeatherImage from './FeatherImage.vue';
import FeatherInbox from './FeatherInbox.vue';
import FeatherInfo from './FeatherInfo.vue';
import FeatherInstagram from './FeatherInstagram.vue';
import FeatherItalic from './FeatherItalic.vue';
import FeatherKey from './FeatherKey.vue';
import FeatherLayers from './FeatherLayers.vue';
import FeatherLayout from './FeatherLayout.vue';
import FeatherLifeBuoy from './FeatherLifeBuoy.vue';
import FeatherLink_2 from './FeatherLink_2.vue';
import FeatherLink from './FeatherLink.vue';
import FeatherLinkedin from './FeatherLinkedin.vue';
import FeatherList from './FeatherList.vue';
import FeatherLoader from './FeatherLoader.vue';
import FeatherLock from './FeatherLock.vue';
import FeatherLogIn from './FeatherLogIn.vue';
import FeatherLogOut from './FeatherLogOut.vue';
import FeatherMail from './FeatherMail.vue';
import FeatherMapPin from './FeatherMapPin.vue';
import FeatherMap from './FeatherMap.vue';
import FeatherMaximize_2 from './FeatherMaximize_2.vue';
import FeatherMaximize from './FeatherMaximize.vue';
import FeatherMic from './FeatherMic.vue';
import FeatherMinusCircle from './FeatherMinusCircle.vue';
import FeatherMinusSquare from './FeatherMinusSquare.vue';
import FeatherMinus from './FeatherMinus.vue';
import FeatherMonitor from './FeatherMonitor.vue';
import FeatherMoon from './FeatherMoon.vue';
import FeatherMoreHorizontal from './FeatherMoreHorizontal.vue';
import FeatherMoreVertical from './FeatherMoreVertical.vue';
import FeatherMousePointer from './FeatherMousePointer.vue';
import FeatherMove from './FeatherMove.vue';
import FeatherMusic from './FeatherMusic.vue';
import FeatherNavigation_2 from './FeatherNavigation_2.vue';
import FeatherNavigation from './FeatherNavigation.vue';
import FeatherOctagon from './FeatherOctagon.vue';
import FeatherPackage from './FeatherPackage.vue';
import FeatherPaperclip from './FeatherPaperclip.vue';
import FeatherPauseCircle from './FeatherPauseCircle.vue';
import FeatherPause from './FeatherPause.vue';
import FeatherPenTool from './FeatherPenTool.vue';
import FeatherPercent from './FeatherPercent.vue';
import FeatherPhoneCall from './FeatherPhoneCall.vue';
import FeatherPhoneForwarded from './FeatherPhoneForwarded.vue';
import FeatherPhoneIncoming from './FeatherPhoneIncoming.vue';
import FeatherPhoneMissed from './FeatherPhoneMissed.vue';
import FeatherPhoneOff from './FeatherPhoneOff.vue';
import FeatherPhoneOutgoing from './FeatherPhoneOutgoing.vue';
import FeatherPhone from './FeatherPhone.vue';
import FeatherPieChart from './FeatherPieChart.vue';
import FeatherPlayCircle from './FeatherPlayCircle.vue';
import FeatherPlay from './FeatherPlay.vue';
import FeatherPlusCircle from './FeatherPlusCircle.vue';
import FeatherPlusSquare from './FeatherPlusSquare.vue';
import FeatherPlus from './FeatherPlus.vue';
import FeatherPocket from './FeatherPocket.vue';
import FeatherPower from './FeatherPower.vue';
import FeatherPrinter from './FeatherPrinter.vue';
import FeatherRadio from './FeatherRadio.vue';
import FeatherRefreshCcw from './FeatherRefreshCcw.vue';
import FeatherRotateCw from './FeatherRotateCw.vue';
import FeatherRss from './FeatherRss.vue';
import FeatherSave from './FeatherSave.vue';
import FeatherScissors from './FeatherScissors.vue';
import FeatherSearch from './FeatherSearch.vue';
import FeatherSend from './FeatherSend.vue';
import FeatherServer from './FeatherServer.vue';
import FeatherSettings from './FeatherSettings.vue';
import FeatherShare_2 from './FeatherShare_2.vue';
import FeatherShare from './FeatherShare.vue';
import FeatherShieldOff from './FeatherShieldOff.vue';
import FeatherShield from './FeatherShield.vue';
import FeatherShoppingBag from './FeatherShoppingBag.vue';
import FeatherShoppingCart from './FeatherShoppingCart.vue';
import FeatherShuffle from './FeatherShuffle.vue';
import FeatherSidebar from './FeatherSidebar.vue';
import FeatherSkipBack from './FeatherSkipBack.vue';
import FeatherSkipForward from './FeatherSkipForward.vue';
import FeatherSlack from './FeatherSlack.vue';
import FeatherSlash from './FeatherSlash.vue';
import FeatherSliders from './FeatherSliders.vue';
import FeatherSmartphone from './FeatherSmartphone.vue';
import FeatherSmile from './FeatherSmile.vue';
import FeatherSpeaker from './FeatherSpeaker.vue';
import FeatherSquare from './FeatherSquare.vue';
import FeatherStar from './FeatherStar.vue';
import FeatherStopCircle from './FeatherStopCircle.vue';
import FeatherSun from './FeatherSun.vue';
import FeatherSunrise from './FeatherSunrise.vue';
import FeatherSunset from './FeatherSunset.vue';
import FeatherTablet from './FeatherTablet.vue';
import FeatherTag from './FeatherTag.vue';
import FeatherTarget from './FeatherTarget.vue';
import FeatherTerminal from './FeatherTerminal.vue';
import FeatherThermometer from './FeatherThermometer.vue';
import FeatherThumbsDown from './FeatherThumbsDown.vue';
import FeatherThumbsUp from './FeatherThumbsUp.vue';
import FeatherToggleLeft from './FeatherToggleLeft.vue';
import FeatherToggleRight from './FeatherToggleRight.vue';
import FeatherTrash_2 from './FeatherTrash_2.vue';
import FeatherTrash from './FeatherTrash.vue';
import FeatherTrello from './FeatherTrello.vue';
import FeatherTrendingDown from './FeatherTrendingDown.vue';
import FeatherTrendingUp from './FeatherTrendingUp.vue';
import FeatherTriangle from './FeatherTriangle.vue';
import FeatherTruck from './FeatherTruck.vue';
import FeatherTv from './FeatherTv.vue';
import FeatherTwitter from './FeatherTwitter.vue';
import FeatherType from './FeatherType.vue';
import FeatherUmbrella from './FeatherUmbrella.vue';
import FeatherUnderline from './FeatherUnderline.vue';
import FeatherUnlock from './FeatherUnlock.vue';
import FeatherUploadCloud from './FeatherUploadCloud.vue';
import FeatherUpload from './FeatherUpload.vue';
import FeatherUserCheck from './FeatherUserCheck.vue';
import FeatherUserPlus from './FeatherUserPlus.vue';
import FeatherUserMinus from './FeatherUserMinus.vue';
import FeatherUserX from './FeatherUserX.vue';
import FeatherUser from './FeatherUser.vue';
import FeatherUsers from './FeatherUsers.vue';
import FeatherVideoOff from './FeatherVideoOff.vue';
import FeatherVideo from './FeatherVideo.vue';
import FeatherVoicemail from './FeatherVoicemail.vue';
import FeatherVolume_1 from './FeatherVolume_1.vue';
import FeatherVolume_2 from './FeatherVolume_2.vue';
import FeatherVolumeX from './FeatherVolumeX.vue';
import FeatherVolume from './FeatherVolume.vue';
import FeatherWatch from './FeatherWatch.vue';
import FeatherWifiOff from './FeatherWifiOff.vue';
import FeatherWifi from './FeatherWifi.vue';
import FeatherWind from './FeatherWind.vue';
import FeatherXCircle from './FeatherXCircle.vue';
import FeatherXOctagon from './FeatherXOctagon.vue';
import FeatherXSquare from './FeatherXSquare.vue';
import FeatherX from './FeatherX.vue';
import FeatherYoutube from './FeatherYoutube.vue';
import FeatherZapOff from './FeatherZapOff.vue';
import FeatherZap from './FeatherZap.vue';
import FeatherZoomIn from './FeatherZoomIn.vue';
import FeatherZoomOut from './FeatherZoomOut.vue';
import DashboardIcon from './DashboardIcon.vue';
import SearchIcon from './SearchIcon.vue';
import ArrowRightIcon from './ArrowRightIcon.vue';
import CompletedRequestsIcon from './CompletedRequestsIcon.vue';
import AllRequestsIcon from './AllRequestsIcon.vue';
import VideoIcon from './VideoIcon.vue';
import PayoutIcon from './PayoutIcon.vue';
import InvoiceIcon from './InvoiceIcon.vue';
import PublicUserIcon from './PublicUserIcon.vue';
import NonPublicUserIcon from './NonPublicUserIcon.vue';
import NotApprovedUserIcon from './NotApprovedUserIcon.vue';
import ProductIcon from './ProductIcon.vue';
import CharityIcon from './CharityIcon.vue';
import TagIcon from './TagIcon.vue';
import OccasionIcon from './OccasionIcon.vue';
import BasicStatisticsIcon from './BasicStatisticsIcon.vue';
import UserStatisticsIcon from './UserStatisticsIcon.vue';
import UserStatisticsTwoIcon from './UserStatisticsTwoIcon.vue';
import RequestStatisticsIcon from './RequestStatisticsIcon.vue';
import ProductStatisticsIcon from './ProductStatisticsIcon.vue';
import ContactFormMessageIcon from './ContactFormMessageIcon.vue';
import NewsletterAddressIcon from './NewsletterAddressIcon.vue';
import CelebritySuggestionIcon from './CelebritySuggestionIcon.vue';
import AdminIcon from './AdminIcon.vue';
import DocumentTemplateIcon from './DocumentTemplateIcon.vue';
import EmailQueueIcon from './EmailQueueIcon.vue';
import SmsQueueIcon from './SmsQueueIcon.vue';
import FirebaseNotificationQueueIcon from './FirebaseNotificationQueueIcon.vue';
import ReviewIcon from './ReviewIcon.vue';
import LockIcon from './LockIcon.vue';
import LogoutIcon from './LogoutIcon.vue';
import CancelIcon from './CancelIcon.vue';
import CheckIcon from './CheckIcon.vue';
import MoneyIcon from './MoneyIcon.vue';
import CompletedVideosIcon from './CompletedVideosIcon.vue';
import RecordingSuccessIcon from './RecordingSuccessIcon.vue';
import RecordingTimeIcon from './RecordingTimeIcon.vue';

export default {
  name: 'icon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  components: {
    FeatherActivity,
    FeatherAirplay,
    FeatherAlertCircle,
    FeatherAlertOctagon,
    FeatherAlertTriangle,
    FeatherAlignCenter,
    FeatherAlignJustify,
    FeatherAlignLeft,
    FeatherAlignRight,
    FeatherAnchor,
    FeatherAperture,
    FeatherArchive,
    FeatherArrowDownCircle,
    FeatherArrowDownLeft,
    FeatherArrowDownRight,
    FeatherArrowDown,
    FeatherArrowLeftCircle,
    FeatherArrowLeft,
    FeatherArrowRightCircle,
    FeatherArrowRight,
    FeatherArrowUpCircle,
    FeatherArrowUpLeft,
    FeatherArrowUpRight,
    FeatherArrowUp,
    FeatherAtSign,
    FeatherAward,
    FeatherBarChart_2,
    FeatherBarChart,
    FeatherBatteryCharging,
    FeatherBattery,
    FeatherBellOff,
    FeatherBell,
    FeatherBluetooth,
    FeatherBold,
    FeatherBookOpen,
    FeatherBook,
    FeatherBookmark,
    FeatherBox,
    FeatherBriefcase,
    FeatherCalendar,
    FeatherCameraOff,
    FeatherCamera,
    FeatherCast,
    FeatherCheckCircle,
    FeatherCheckSquare,
    FeatherCheck,
    FeatherChevronDown,
    FeatherChevronLeft,
    FeatherChevronRight,
    FeatherChevronUp,
    FeatherChevronsDown,
    FeatherChevronsLeft,
    FeatherChevronsRight,
    FeatherChevronsUp,
    FeatherChrome,
    FeatherClipboard,
    FeatherClock,
    FeatherCloudDrizzle,
    FeatherCloudLightning,
    FeatherCloudOff,
    FeatherCloudRain,
    FeatherCloudSnow,
    FeatherCloud,
    FeatherCode,
    FeatherCodepen,
    FeatherCodesandbox,
    FeatherCoffee,
    FeatherColumns,
    FeatherCommand,
    FeatherCompass,
    FeatherCopy,
    FeatherCornerDownLeft,
    FeatherCornerDownRight,
    FeatherCornerLeftDown,
    FeatherCornerLeftUp,
    FeatherCornerRightDown,
    FeatherCornerRightUp,
    FeatherCornerUpLeft,
    FeatherCornerUpRight,
    FeatherCpu,
    FeatherCreditCard,
    FeatherCrop,
    FeatherCrosshair,
    FeatherDatabase,
    FeatherDelete,
    FeatherDisc,
    FeatherDollarSign,
    FeatherDownloadCloud,
    FeatherDownload,
    FeatherDroplet,
    FeatherEdit_2,
    FeatherEdit_3,
    FeatherEdit,
    FeatherExternalLink,
    FeatherEyeOff,
    FeatherEye,
    FeatherFacebook,
    FeatherFastForward,
    FeatherFeather,
    FeatherFigma,
    FeatherFileMinus,
    FeatherFilePlus,
    FeatherFileText,
    FeatherFile,
    FeatherFilm,
    FeatherFilter,
    FeatherFlag,
    FeatherFolderMinus,
    FeatherFolderPlus,
    FeatherFolder,
    FeatherFrown,
    FeatherGift,
    FeatherGitBranch,
    FeatherGitCommit,
    FeatherGitMerge,
    FeatherGitPullRequest,
    FeatherGithub,
    FeatherGitlab,
    FeatherGlobe,
    FeatherGrid,
    FeatherHardDrive,
    FeatherHash,
    FeatherHeadphones,
    FeatherHeart,
    FeatherHelpCircle,
    FeatherHexagon,
    FeatherHome,
    FeatherImage,
    FeatherInbox,
    FeatherInfo,
    FeatherInstagram,
    FeatherItalic,
    FeatherKey,
    FeatherLayers,
    FeatherLayout,
    FeatherLifeBuoy,
    FeatherLink_2,
    FeatherLink,
    FeatherLinkedin,
    FeatherList,
    FeatherLoader,
    FeatherLock,
    FeatherLogIn,
    FeatherLogOut,
    FeatherMail,
    FeatherMapPin,
    FeatherMap,
    FeatherMaximize_2,
    FeatherMaximize,
    FeatherMic,
    FeatherMinusCircle,
    FeatherMinusSquare,
    FeatherMinus,
    FeatherMonitor,
    FeatherMoon,
    FeatherMoreHorizontal,
    FeatherMoreVertical,
    FeatherMousePointer,
    FeatherMove,
    FeatherMusic,
    FeatherNavigation_2,
    FeatherNavigation,
    FeatherOctagon,
    FeatherPackage,
    FeatherPaperclip,
    FeatherPauseCircle,
    FeatherPause,
    FeatherPenTool,
    FeatherPercent,
    FeatherPhoneCall,
    FeatherPhoneForwarded,
    FeatherPhoneIncoming,
    FeatherPhoneMissed,
    FeatherPhoneOff,
    FeatherPhoneOutgoing,
    FeatherPhone,
    FeatherPieChart,
    FeatherPlayCircle,
    FeatherPlay,
    FeatherPlusCircle,
    FeatherPlusSquare,
    FeatherPlus,
    FeatherPocket,
    FeatherPower,
    FeatherPrinter,
    FeatherRadio,
    FeatherRefreshCcw,
    FeatherRotateCw,
    FeatherRss,
    FeatherSave,
    FeatherScissors,
    FeatherSearch,
    FeatherSend,
    FeatherServer,
    FeatherSettings,
    FeatherShare_2,
    FeatherShare,
    FeatherShieldOff,
    FeatherShield,
    FeatherShoppingBag,
    FeatherShoppingCart,
    FeatherShuffle,
    FeatherSidebar,
    FeatherSkipBack,
    FeatherSkipForward,
    FeatherSlack,
    FeatherSlash,
    FeatherSliders,
    FeatherSmartphone,
    FeatherSmile,
    FeatherSpeaker,
    FeatherSquare,
    FeatherStar,
    FeatherStopCircle,
    FeatherSun,
    FeatherSunrise,
    FeatherSunset,
    FeatherTablet,
    FeatherTag,
    FeatherTarget,
    FeatherTerminal,
    FeatherThermometer,
    FeatherThumbsDown,
    FeatherThumbsUp,
    FeatherToggleLeft,
    FeatherToggleRight,
    FeatherTrash_2,
    FeatherTrash,
    FeatherTrello,
    FeatherTrendingDown,
    FeatherTrendingUp,
    FeatherTriangle,
    FeatherTruck,
    FeatherTv,
    FeatherTwitter,
    FeatherType,
    FeatherUmbrella,
    FeatherUnderline,
    FeatherUnlock,
    FeatherUploadCloud,
    FeatherUpload,
    FeatherUserCheck,
    FeatherUserPlus,
    FeatherUserMinus,
    FeatherUserX,
    FeatherUser,
    FeatherUsers,
    FeatherVideoOff,
    FeatherVideo,
    FeatherVoicemail,
    FeatherVolume_1,
    FeatherVolume_2,
    FeatherVolumeX,
    FeatherVolume,
    FeatherWatch,
    FeatherWifiOff,
    FeatherWifi,
    FeatherWind,
    FeatherXCircle,
    FeatherXOctagon,
    FeatherXSquare,
    FeatherX,
    FeatherYoutube,
    FeatherZapOff,
    FeatherZap,
    FeatherZoomIn,
    FeatherZoomOut,
    DashboardIcon,
    SearchIcon,
    ArrowRightIcon,
    CompletedRequestsIcon,
    AllRequestsIcon,
    VideoIcon,
    PayoutIcon,
    InvoiceIcon,
    PublicUserIcon,
    NonPublicUserIcon,
    NotApprovedUserIcon,
    ProductIcon,
    CharityIcon,
    TagIcon,
    OccasionIcon,
    BasicStatisticsIcon,
    UserStatisticsIcon,
    UserStatisticsTwoIcon,
    RequestStatisticsIcon,
    ProductStatisticsIcon,
    ContactFormMessageIcon,
    NewsletterAddressIcon,
    CelebritySuggestionIcon,
    AdminIcon,
    DocumentTemplateIcon,
    EmailQueueIcon,
    SmsQueueIcon,
    FirebaseNotificationQueueIcon,
    ReviewIcon,
    LockIcon,
    LogoutIcon,
    CancelIcon,
    CheckIcon,
    MoneyIcon,
    CompletedVideosIcon,
    RecordingSuccessIcon,
    RecordingTimeIcon,
  },
};
</script>
