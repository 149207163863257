<template>
  <div>
    <input
      class="form-control"
      placeholder="https://example.com"
      v-model="valueStorage"
      v-bind="$attrs"
      type="text"
    />
    <button class="mt-2" type="button" @click="open">Upload a file</button>
    <modal
      v-if="isOpened"
      heading="File upload"
      confirmButtonText="Upload"
      @close="close"
      @confirm="confirm"
    >
      <template slot="body">
        <input type="file" id="file-input" @change="saveFile" ref="file" />
      </template>
    </modal>
  </div>
</template>

<script>
import connector from '../../api/connector';
import Modal from '../modals/Modal.vue';

export default {
  name: 'url-input',
  props: ['value', 'properties', 'type'],
  components: {
    Modal,
  },
  data() {
    return {
      valueStorage: this.value,
      isOpened: false,
    };
  },
  methods: {
    open() {
      this.isOpened = true;
    },
    close() {
      this.isOpened = false;
    },
    async confirm() {
      const { path } = await connector.post('/custom/file/upload', {
        file: this.fileContents,
        type: this.file.type,
      });
      if (path) {
        this.valueStorage = path;
        this.close();
      }
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async saveFile(e) {
      if (e.target.files && e.target.files[0]) {
        this.file = e.target.files[0];

        try {
          const dataUri = await this.toBase64(this.file);
          this.fileContents = dataUri.split(',')[1];
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
  watch: {
    valueStorage() {
      this.$emit('input', this.valueStorage);
    },
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.valueStorage = newValue;
      }
    },
  },
};
</script>
