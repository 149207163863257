import { MODEL_NAME_USER_REQUEST_SUCCESS_FAIL_COUNT } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import UserRequestSuccessFailCount from './user-request-success-fail-count-class';
import userRequestSuccessFailCountProperties from './user-request-success-fail-count-properties';

/** @extends {DataStorageFactory<UserRequestSuccessFailCount>} */
class UserRequestSuccessFailCountFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_USER_REQUEST_SUCCESS_FAIL_COUNT;

  getSingular = () => 'User Request Success Fail Count';
  getPlural = () => 'user Request Success Fail Counts';

  getIcon = () => 'feather-user';

  getTabs = () => [];

  getFilters = () => [];
}

const userRequestSuccessFailCountFactory = new UserRequestSuccessFailCountFactory(
  userRequestSuccessFailCountProperties,
  UserRequestSuccessFailCount.prototype
);

export default userRequestSuccessFailCountFactory;
