import { MODEL_NAME_PAYOUT, MODEL_NAME_USER } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import Payout from './payout-class';
import payoutProperties from './payout-properties';

/** @extends {DataStorageFactory<Payout>} */
class PayoutFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_PAYOUT;

  getSingular = () => 'Payout';
  getPlural = () => 'payouts';

  getIcon = () => 'payout-icon';

  getTabs = () => [];

  getFilters = () => [
    {
      filter: 'userId.eq',
      label: 'Filter by user',
      type: 'data-select-filter',
      description: 'Display payouts for given user',
      props: {
        model: MODEL_NAME_USER,
        multiple: false,
        placeholder: 'All users',
      },
    },
    {
      filter: 'isApproved.eq',
      label: "Filter by the payout's state of approval",
      type: 'simple-select-filter',
      description: 'Display payouts based on their state of approval',
      props: {
        options: [
          { label: 'Approved', value: true },
          { label: 'Not approved', value: false },
        ],
      },
    },
    {
      filter: 'isCompleted.eq',
      label: "Filter by the payout's state of completion",
      type: 'simple-select-filter',
      description: 'Display payouts based on their state of completion',
      props: {
        options: [
          { label: 'Completed', value: true },
          { label: 'Not completed', value: false },
        ],
      },
    },
    {
      filter: 'completed.range',
      label: 'Filter payouts by date range',
      type: 'date-range-filter',
      description: 'Display payouts based on their day of completion',
    },
  ];

  getDefaultAggregates = () => [
    {
      aggregateFunction: 'Avg',
      aggregatedColumn: 'amount',
    },
    {
      aggregateFunction: 'Sum',
      aggregatedColumn: 'amount',
    },
  ];
}

const payoutFactory = new PayoutFactory(payoutProperties, Payout.prototype);

export default payoutFactory;
