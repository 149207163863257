import { MODEL_NAME_TAG, MODEL_NAME_USER } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import User from './user-class';
import userProperties from './user-properties';

/** @extends {DataStorageFactory<User>} */
class UserFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_USER;

  getSingular = () => 'User';
  getPlural = () => 'users';

  getIcon = () => 'feather-user';

  getTabs = () => [
    {
      name: 'Billing Address',
      slug: 'billing-address',
      description: "User's billing address.",
    },
    {
      name: 'Tags',
      slug: 'tags',
      description: 'Tags associated with the user.',
    },
    {
      name: 'Requests',
      slug: 'requests',
      description: 'Requested videos.',
    },
    {
      name: 'Videos',
      slug: 'videos',
      description: 'Profile and completed videos.',
    },
    {
      name: 'Products',
      slug: 'products',
      description: 'Products the user provides.',
    },
    {
      name: 'Verifications and Consents',
      slug: 'verifications',
      description: "The state of the user's verifications and consents.",
    },
    {
      name: 'Payouts',
      slug: 'payouts',
      description: 'The list of requested and fulfilled payouts to the user.',
    },
    {
      name: 'Supported Charities',
      slug: 'supported-charities',
      description: 'The charities the user has supported.',
    },
  ];

  getConnections = () => [{ name: 'tags', model: MODEL_NAME_TAG }];

  getFilters = () => [
    {
      filter: 'isPublic.eq',
      label: 'Filter by visibility',
      type: 'simple-select-filter',
      description: 'Display users based on their profile visibility.',
      props: {
        options: [
          { label: 'Public', value: true },
          { label: 'Not public', value: false },
        ],
      },
    },
    {
      filter: 'isApproved.eq',
      label: 'Filter by approval',
      type: 'simple-select-filter',
      description: 'Display users based on the approval from admins.',
      props: {
        options: [
          { label: 'Approved', value: true },
          { label: 'Not approved', value: false },
        ],
      },
    },
    {
      filter: 'contactVerified.eq',
      label: 'Filter by state of verification',
      type: 'simple-select-filter',
      description: 'Display users based on their verification status.',
      props: {
        options: [
          { label: 'Verified', value: true },
          { label: 'Not verified', value: false },
        ],
      },
    },
    {
      filter: 'expressMode.eq',
      label: 'Filter by express mode',
      type: 'simple-select-filter',
      description: 'Display users based on their provision of the express mode.',
      props: {
        options: [
          { label: 'Express', value: true },
          { label: 'Not express', value: false },
        ],
      },
    },
    {
      filter: 'tags.eq',
      label: 'Filter by associated tags',
      type: 'data-select-filter',
      description: 'Display users based on their associated tags.',
      props: {
        model: MODEL_NAME_TAG,
        multiple: false,
        placeholder: 'All tags',
      },
    },
  ];

  getDefaultAggregates = () => [
    {
      aggregateFunction: 'Avg',
      aggregatedColumn: 'charityFee',
    },
    {
      aggregateFunction: 'Avg',
      aggregatedColumn: 'balanceUsable',
    },
    {
      aggregateFunction: 'Sum',
      aggregatedColumn: 'balanceUsable',
    },
  ];
}

const userFactory = new UserFactory(userProperties, User.prototype);

export default userFactory;
