<template>
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.32 2.61001C13.77 1.06001 11.25 1.06001 9.69002 2.61001C9.40002 2.90001 9.18002 3.24001 9.00002 3.59001C8.82002 3.24001 8.60002 2.91001 8.31002 2.61001C6.76002 1.06001 4.24002 1.06001 2.68002 2.61001C1.13002 4.16001 1.13002 6.68001 2.68002 8.24001L9.00002 14.56L15.32 8.24001C16.87 6.69001 16.87 4.17001 15.32 2.61001Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'charity-icon',
};
</script>
