<template>
  <form id="navbar-search-bar" class="search-form" @submit.prevent ref="form">
    <div class="input-group">
      <div class="input-group-prepend d-flex align-items-center">
        <div class="input-group-text">
          <icon icon="search-icon" />
        </div>
      </div>
      <input
        ref="input"
        type="text"
        class="form-control"
        placeholder="Search here ..."
        v-model="q"
        @focus="(visible = true), (focus = true)"
        @blur="onBlur"
      />
    </div>

    <div class="search-results" v-show="visible">
      <!-- Header - total number of results -->
      <div class="d-flex justify-content-between">
        <h5 class="text-muted font-weight-normal">
          <div v-if="error">{{ error }}</div>
          <div v-else-if="loading">Loading ...</div>
          <div v-else-if="results">
            Found {{ total }} record{{ total === 1 ? '' : 's' }} matching &bdquo;{{ q }}&rdquo;
          </div>
          <div v-else>Please, enter at least {{ minLength }} characters...</div>
        </h5>
      </div>

      <!-- One section for every model -->
      <div class="mt-4" v-if="!loading && results && total > 0">
        <template v-for="(storages, model) in results.results">
          <navbar-search-section
            :key="`navbar-search-section-${model}`"
            @open-result="blur"
            :model="model"
            :results="storages"
            :limit="limitPerSection"
            :startIndex="results.resultsBefore[model]"
            :highlighted="cursor.position"
            :q="q"
          />
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import NavbarSearchSection from './NavbarSearchSection.vue';
import Search from '../../model/search';
import Cursor from '../../classes/cursor';
import { nanoid } from 'nanoid/non-secure';
import { isChildOf } from '../../utils/dom';

export default {
  name: 'navbar-search',
  components: {
    NavbarSearchSection,
  },
  props: {
    minLength: {
      type: Number,
      default: 3,
    },
    delay: {
      type: Number,
      default: 500,
    },
    limitPerSection: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      uuid: nanoid(),

      q: '',
      lastSearchQuery: '',

      visible: false,
      focus: false,

      results: null,
      error: null,
      loading: false,

      cursor: new Cursor(),

      windowClickHandler: ({ target }) =>
        isChildOf(target, ({ id }) => id === 'navbar-search-bar') ? null : (this.visible = false),
    };
  },
  computed: {
    total() {
      return this.results.total;
    },
  },
  methods: {
    onBlur() {
      this.focus = false;

      if (!this.results) {
        this.visible = false;
      }
    },
    search() {
      clearTimeout(this.timeout);

      this.loading = true;
      this.cursor.reset();
      this.error = null;
      this.timeout = setTimeout(() => this.sendRequest(this.q), this.delay);
    },
    async sendRequest(q) {
      if (q.length >= this.minLength) {
        try {
          this.results = await Search.forQuery(q, this.limitPerSection);
          this.lastSearchQuery = q;
          this.cursor.setMax(this.results.visible);
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      } else {
        this.results = null;
        this.loading = false;
      }
    },
    blur() {
      this.visible = false;
      this.$refs.input.blur();
    },
    keyboardEscape(e) {
      if (this.visible) {
        e.preventDefault();
        this.blur();
      }
    },
    keyboardShortcut(e) {
      if (!this.focus && !(document.activeElement instanceof HTMLFormElement)) {
        e.preventDefault();
        this.$refs.input.focus();
      }
    },
    keyboardDown(e) {
      if (this.visible && this.total > 0) {
        e.preventDefault();
        this.cursor.next();
      }
    },
    keyboardUp(e) {
      if (this.visible && this.total > 0) {
        e.preventDefault();
        this.cursor.prev();
      }
    },
    keyboardEnter(e) {
      if (this.visible && this.total > 0 && this.cursor.position > -1) {
        e.preventDefault();

        const els = this.$refs.form.getElementsByClassName(`search-result-${this.cursor.position}`);
        if (els.length > 0) {
          els[0].click();
        }
      }
    },
  },
  created() {
    this.$keyboard.on('down', 'Escape', this.uuid, (e) => this.keyboardEscape(e));
    this.$keyboard.on('down', ['/', '{'], this.uuid, (e) => this.keyboardShortcut(e));
    this.$keyboard.on('down', 'ArrowDown', this.uuid, (e) => this.keyboardDown(e));
    this.$keyboard.on('down', 'ArrowUp', this.uuid, (e) => this.keyboardUp(e));
    this.$keyboard.on('down', 'Enter', this.uuid, (e) => this.keyboardEnter(e));

    window.addEventListener('click', this.windowClickHandler);
  },
  beforeDestroy() {
    this.$keyboard.off(this.uuid);
    window.removeEventListener('click', this.windowClickHandler);
  },
  watch: {
    q() {
      this.visible = true;
      this.search();
    },
  },
};
</script>

<style lang="scss">
#navbar-search-bar {
  input[type='search'] {
    font-size: 1.1em;
  }

  .search-results {
    position: absolute;
    top: 59px;
    left: 20%;

    width: 60%;

    padding: 20px;
    border-radius: 0 0 4px 4px;

    background-color: white;
    box-shadow: 0 5px 10px 0 rgba(183, 192, 206, 0.2);
  }

  a {
    margin-bottom: 3px;

    color: inherit;

    transition: color 200ms ease-in-out;

    mark {
      transition: color 200ms ease-in-out;
    }

    &:hover,
    &:focus,
    &:focus mark,
    &:hover mark {
      color: #727cf5;
    }
  }

  svg.feather {
    width: 14px;
    height: 14px;

    margin-right: 6px;
    margin-top: 2px;
  }

  .highlighted {
    color: #727cf5;
    outline: 1px dotted #727cf5;
  }
}
</style>
