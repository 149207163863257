<template>
  <nav class="navbar">
    <div class="navbar-content">
      <navbar-search />
      <ul class="navbar-nav">
        <navbar-nav-profile />
      </ul>
    </div>
  </nav>
</template>

<script>
import NavbarSearch from './NavbarSearch.vue';
import NavbarNavProfile from './NavbarNavProfile.vue';

export default {
  name: 'dashboard-layout-navbar',
  components: {
    NavbarSearch,
    NavbarNavProfile,
  },
};
</script>
