<template>
  <div class="user-stats-cards">
    <div class="card user-stats-card">
      <icon icon="money-icon" class="icon" />
      <div class="title">Zostatok</div>
      <div class="value">{{ balanceUsable }} €</div>
    </div>
    <div class="card user-stats-card">
      <icon icon="completed-videos-icon" class="icon" />
      <div class="title">Vybavené videá</div>
      <div class="value">{{ completedVideos }}</div>
    </div>
    <div class="card user-stats-card">
      <icon icon="recording-success-icon" class="icon" />
      <div class="title">Úspešnosť</div>
      <div class="value">{{ successRate }} %</div>
    </div>
    <div class="card user-stats-card">
      <icon icon="recording-time-icon" class="icon" />
      <div class="title">Čas natáč.</div>
      <div class="value">{{ recordingTime }} h</div>
    </div>
  </div>
</template>

<script>
import { requestFactory, userFactory, userRecordingTimeFactory, videoFactory } from '../../model';

export default {
  name: 'user-stats-card',
  props: ['pk'],
  data() {
    return {
      loading: false,
      error: null,
      user: null,
      balanceUsable: 0,
      completedVideos: 0,
      successRate: 0,
      recordingTime: 0,
    };
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        this.user = (await userFactory.fetch({ 'pk.eq': this.pk }))[0];

        this.balanceUsable = this.user.balanceUsable;

        const requests = await requestFactory.fetch({ 'userId.eq': this.pk });

        const videos = await videoFactory.fetch({ 'requestId.in': requests.map((r) => r.id) });
        this.completedVideos = videos.length;
        this.successRate = ((this.completedVideos / requests.length) * 100).toFixed(2);

        const fetchedRecordingTime = (
          await userRecordingTimeFactory.fetch({
            'userId.eq': this.pk,
          })
        )[0];

        this.recordingTime = parseFloat(fetchedRecordingTime.totalHours).toFixed(2);
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.user-stats-cards {
  display: flex;
  flex-direction: row;
  gap: 8px;

  .user-stats-card {
    width: calc(100% / 4);
    min-height: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    gap: 10px;
    border-radius: 4px;

    .icon {
      height: 32px;
      width: 32px;
    }

    .title {
      color: #8f95a3;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
    }

    .value {
      white-space: nowrap;
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}
</style>
