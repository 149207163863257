<template>
  <div>
    <div class="custom-view-content">
      <div class="icon">
        <icon icon="public-user-icon" />
      </div>
      <div>
        <h1>Public users</h1>
        <p class="model-description">The list of public users.</p>
      </div>
    </div>
    <data-table
      :model="MODEL_NAME_USER"
      hideNewButton
      hideEditButton
      hideDeleteButton
      hideAggregates
      hideFilters
      :filters="{ 'isPublic.eq': true }"
    />
  </div>
</template>

<script>
import { MODEL_NAME_USER } from '../../constants';

export default {
  name: 'public-users',
  props: ['single'],
  data() {
    return {
      MODEL_NAME_USER,
    };
  },
};
</script>
