<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons/check" clip-path="url(#clip0_339_3294)">
      <path
        id="Vector"
        d="M5.33008 12.76L9.27008 16.7L18.6701 7.3"
        stroke="#454A68"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_339_3294">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'check-icon',
};
</script>
