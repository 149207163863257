<template>
  <div class="w-100 mx-0 auth-page row w-100">
    <div class="col-md-6 col-xl-4 mx-auto">
      <div class="card mb">
        <div class="row">
          <div class="col-md-12 pl-md-0">
            <div class="auth-form-wrapper showtimes-login-form">
              <a href="#" class="showtimes-admin-logo">
                <img src="../assets/logo.svg" alt="logo" />
              </a>
              <form class="forms-sample" @submit.prevent="onSubmit">
                <div class="form-group mb-4">
                  <label for="email">Email</label>
                  <input
                    v-model="email"
                    required
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                  />
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <input
                    v-model="password"
                    required
                    type="password"
                    class="form-control"
                    id="password"
                    autocomplete="current-password"
                    placeholder="Password"
                  />
                </div>
                <div class="mt-4 d-flex justify-content-start align-items-center">
                  <button class="login-button btn btn-sm btn-primary text-white">
                    <span> Login </span>
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_211_677)">
                        <path
                          d="M10.8201 6.64001L16.1801 12L10.8201 17.36"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_211_677">
                          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import session from '../api/session';

export default {
  name: 'page-login',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    onSubmit() {
      session.login(this.email, this.password);
    },
  },
};
</script>
