import { MODEL_NAME_CELEBRITY_SUGGESTION } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import CelebritySuggestion from './celebrity-suggestion-class';
import celebritySuggestionProperties from './celebrity-suggestion-properties';

/** @extends {DataStorageFactory<CelebritySuggestion>} */
class CelebritySuggestionFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_CELEBRITY_SUGGESTION;

  getSingular = () => 'Celebrity Suggestion';
  getPlural = () => 'celebrity Suggestions';

  getIcon = () => 'celebrity-suggestion-icon';

  getTabs = () => [
    {
      name: 'Email Suggestions',
      slug: 'email-suggestions',
      description: 'The list of people who have submitted their request for a celebrity.',
    },
  ];
}

const celebritySuggestionFactory = new CelebritySuggestionFactory(
  celebritySuggestionProperties,
  CelebritySuggestion.prototype
);

export default celebritySuggestionFactory;
