<template>
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 16.5V13.62C1.5 11.9 2.9 10.5 4.62 10.5H6.5"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 8.5C9.933 8.5 11.5 6.933 11.5 5C11.5 3.067 9.933 1.5 8 1.5C6.067 1.5 4.5 3.067 4.5 5C4.5 6.933 6.067 8.5 8 8.5Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.7964 15.2835L14.2329 15.72L13.97 15.9829L13.5335 15.5464L13.2648 15.2777L12.934 15.4647C12.7345 15.5774 12.5434 15.6597 12.33 15.7172L11.96 15.8168V16.2V16.82H11.59V16.2V15.8168L11.22 15.7172C11.0013 15.6583 10.8034 15.5753 10.6243 15.4695L10.2906 15.2723L10.0164 15.5464L9.57998 15.9829L9.31709 15.72L9.75353 15.2835L10.0223 15.0148L9.83526 14.684C9.72255 14.4845 9.64023 14.2934 9.58279 14.08L9.48317 13.71H9.09998H8.47998V13.34H9.09998H9.48317L9.58279 12.97C9.64167 12.7513 9.72464 12.5534 9.83044 12.3743L10.0277 12.0406L9.75353 11.7664L9.31709 11.33L9.57998 11.0671L10.0164 11.5035L10.2852 11.7723L10.616 11.5853C10.8154 11.4725 11.0066 11.3902 11.22 11.3328L11.59 11.2332V10.85V10.23H11.96V10.85V11.2332L12.33 11.3328C12.5487 11.3917 12.7466 11.4746 12.9256 11.5804L13.2594 11.7777L13.5335 11.5035L13.97 11.0671L14.2329 11.33L13.7964 11.7664L13.5277 12.0352L13.7147 12.366C13.8274 12.5654 13.9097 12.7566 13.9672 12.97L14.0668 13.34H14.45H15.07V13.71H14.45H14.0668L13.9672 14.08C13.9083 14.2987 13.8253 14.4966 13.7195 14.6756L13.5223 15.0094L13.7964 15.2835ZM9.89998 13.53C9.89998 14.5661 10.7438 15.41 11.78 15.41C12.8161 15.41 13.66 14.5661 13.66 13.53C13.66 12.4938 12.8161 11.65 11.78 11.65C10.7438 11.65 9.89998 12.4938 9.89998 13.53Z"
      fill="white"
      stroke="#8F95A3"
    />
  </svg>
</template>

<script>
export default {
  name: 'admin-icon',
};
</script>
