<template>
  <div>
    <div>
      <p>Select a template</p>
      <data-select class="mt-2" :model="model" v-model="selected" />
    </div>
    <span class="mt-3 mb-3" v-if="loading">Loading ...</span>
    <span class="mt-3 mb-3" v-else-if="error">{{ error }}</span>
    <template v-else-if="templateContent">
      <div class="template-body mt-2" v-html="displayText"></div>
      <div class="row mt-2">
        <div class="col-3">
          <label>Key</label>
        </div>
        <div class="col-9">
          <label>Value</label>
        </div>
      </div>
      <template v-for="(value, key) in templateVariables">
        <div :key="key" class="mt-2 row">
          <div class="col-3">
            <text-input type="text" :value="key" class="mr-2" disabled />
          </div>
          <div class="col-9">
            <text-input
              type="text"
              v-model="templateVariables[key]"
              placeholder="Replace the variable with something"
            />
          </div>
        </div>
      </template>
      <button
        class="btn btn-primary btn-sm submit text-white mt-2"
        :hidden="loading"
        :disabled="disabled"
        @click="submit"
      >
        Enqueue
      </button>
    </template>
  </div>
</template>

<script>
/**
 * Retrieves template from given model. The factory for the model must
 * implement `getTemplateBody`.
 */
import TextInput from '../inputs/TextInput.vue';
import getFactory from '../../model';
import Vue from 'vue';

export default {
  name: 'template-editor',
  components: {
    TextInput,
  },
  props: {
    model: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      selected: null,
      templateContent: null,
      templateVariables: {},
    };
  },
  methods: {
    async getTemplateContent() {
      try {
        this.loading = true;
        this.error = null;
        this.templateContent = await this.factory.pk(this.selected);
        this.getTemplateVariables();
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    getTemplateVariables() {
      this.templateVariables = {};
      this.templateBody.match(/(__.+?__)/g).forEach((matched) => {
        Vue.set(this.templateVariables, matched, '');
      });
    },
    submit() {
      this.$emit('submit', {
        templateVariables: this.templateVariables,
        templateContent: this.templateContent,
      });
    },
  },
  computed: {
    factory() {
      return getFactory(this.model);
    },
    templateBody() {
      return this.factory.getTemplateBody(this.templateContent);
    },
    displayText() {
      return Object.entries(this.templateVariables)
        .reduce((text, [key, value]) => {
          return text.replace(
            new RegExp(key, 'g'),
            `<mark class="${value ? 'yellow' : 'red'}">${value || key}</mark>`
          );
        }, this.templateBody)
        .replace(/\n/g, '<br />');
    },
    disabled() {
      return !Object.values(this.templateVariables).every(Boolean);
    },
  },
  watch: {
    selected() {
      this.getTemplateContent();
    },
  },
};
</script>

<style lang="scss">
.template-body {
  padding: 10px;
  border: 1px solid #ddd;
  mark.yellow {
    background-color: yellow;
  }

  mark.red {
    background-color: red;
  }
}
</style>
