<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill="#241F29" />
    <g clip-path="url(#clip0_188_7197)">
      <path
        d="M30 42.075C36.6688 42.075 42.075 36.6688 42.075 30C42.075 23.3311 36.6688 17.925 30 17.925C23.3311 17.925 17.925 23.3311 17.925 30C17.925 36.6688 23.3311 42.075 30 42.075Z"
        stroke="#B93CF3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35.37 30H30V24.24"
        stroke="#B93CF3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_188_7197">
        <rect width="36" height="36" fill="white" transform="translate(12 12)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'recording-time-icon',
};
</script>
