import { MODEL_NAME_SMS_TEMPLATE } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import SmsTemplate from './sms-template-class';
import smsTemplateProperties from './sms-template-properties';

/** @extends {DataStorageFactory<SmsTemplate>} */
class SmsTemplateFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_SMS_TEMPLATE;

  getSingular = () => 'Sms Template';
  getPlural = () => 'sms Templates';

  getIcon = () => 'feather-archive';

  getTabs = () => [];

  /**
   *
   * @param {SmsTemplate} smsTemplate
   */
  getTemplateBody = (smsTemplate) => smsTemplate.body;
}

const smsTemplateFactory = new SmsTemplateFactory(smsTemplateProperties, SmsTemplate.prototype);

export default smsTemplateFactory;
