import { MODEL_NAME_USER, MODEL_NAME_VIDEO } from '../../constants';
import DataStorageProperties from '../abstract/data-storage-properties';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';

const userPromotionalVideoProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  userId: int({
    references: MODEL_NAME_USER,
    pretty: 'User',
    table: { hidden: true },
  }),
  videoId: string({
    references: MODEL_NAME_VIDEO,
    pretty: 'Video',
  }),
  ord: int({
    table: { hidden: true },
  }),
});

export default userPromotionalVideoProperties;
