<template>
  <select class="form-control" v-model="valueStorage" v-bind="$attrs">
    <template v-for="(text, value) in options">
      <option :key="value" :value="value">{{ text }}</option>
    </template>
  </select>
</template>

<script>
export default {
  name: 'select-input',
  props: {
    value: {
      default: '',
    },
    options: {
      type: Object,
      required: true,
    },
    properties: {},
    type: {},
  },
  data() {
    return {
      valueStorage: this.value,
    };
  },
  watch: {
    valueStorage() {
      this.$emit('input', this.valueStorage);
    },
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.valueStorage = newValue;
      }
    },
  },
};
</script>
