class UserProductTreeNode {
  static create(node, userId) {
    const children = node.children.map((child) => UserProductTreeNode.create(child, userId));

    const productId = node.product.id;

    const productName = node.product.name;
    console.log(node.userProduct);

    const enabled = !!node.userProduct;

    const isActive = enabled && node.userProduct.isActive;

    const price = enabled ? node.userProduct.price : null;

    return new UserProductTreeNode({ productId, productName, price, enabled, children, isActive });
  }

  constructor({ productId, productName, price, enabled, children, isActive }) {
    this.productId = productId;

    this.productName = productName;

    /**
     * @type {Array<UserProductTreeNode>}
     */
    this.children = children;

    this.enabled = enabled;

    this.isActive = isActive;

    this.price = price;
  }
}

export default UserProductTreeNode;
