import {
  MODEL_NAME_REQUEST_STATUS,
  MODEL_NAME_USER,
  MODEL_NAME_REQUEST_INVOICE,
} from '../../constants';
import DataStorageProperties from '../abstract/data-storage-properties';
import date from '../abstract/properties/property-date';
import int from '../abstract/properties/property-int';
import price from '../abstract/properties/property-price';
import string from '../abstract/properties/property-string';
import text from '../abstract/properties/property-text';
import bool from '../abstract/properties/property-bool';
import url from '../abstract/properties/property-url';

const requestProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  title: string({
    titleKey: true,
    readonly: true,
    required: false,
    form: { ignoreWhenNew: true },
    table: {
      width: '153px',
    },
  }),
  created: date({
    defaultValue: null,
    required: false,
    readonly: true,
    form: { ignoreWhenNew: true },
    table: {
      width: '159px',
    },
  }),
  contact: string({
    pretty: 'Requestor',
    readonly: true,
    required: false,
    form: { ignoreWhenNew: true, ignore: true },
    table: { hidden: true },
  }),
  donorName: string({
    maxLength: 100,
    required: false,
    searchable: true,
    pretty: 'Donor',
    table: { hidden: true },
    form: { ignore: true },
  }),
  recipientName: string({
    maxLength: 100,
    searchable: true,
    pretty: 'Recipient',
    table: { hidden: true },
    form: { ignore: true },
  }),
  occasionName: string({
    maxLength: 50,
    required: true,
    searchable: true,
    pretty: 'Occasion',
    table: { hidden: true },
  }),
  instructions: text({
    table: {
      width: '424px',
    },
  }),
  contactEmail: string({
    maxLength: 100,
    searchable: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  contactPhone: string({
    maxLength: 50,
    table: { hidden: true },
    form: { ignore: true },
  }),
  contactFullname: string({
    maxLength: 100,
    searchable: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  userId: int({
    references: MODEL_NAME_USER,
    pretty: 'Celebrity',
    table: {
      width: '228px',
    },
  }),
  requestStatusId: int({
    references: MODEL_NAME_REQUEST_STATUS,
    pretty: 'Request Status',
    required: false,
    defaultValue: null,
    table: {
      width: '164px',
    },
  }),
  uuid: string({
    table: { hidden: true },
    required: false,
    defaultValue: null,
  }),
  amount: price({
    min: 0,
    precision: 2,
    defaultValue: 0,
    required: true,
    table: { hidden: true },
    canAggregate: true,
    form: { ignore: true },
  }),
  amountUser: price({
    min: 0,
    defaultValue: 0,
    precision: 2,
    required: true,
    table: { hidden: true },
    canAggregate: true,
    form: { ignore: true },
  }),
  stripePiId: string({
    maxLength: 255,
    required: false,
    table: { hidden: true },
    form: { ignore: true },
  }),
  stripePiSecret: string({
    maxLength: 255,
    required: false,
    table: { hidden: true },
    form: { ignore: true },
  }),
  modExpress: bool({
    defaultValue: false,
    table: { hidden: true },
  }),
  modNoWatermark: bool({
    defaultValue: false,
    table: { hidden: true },
  }),
  paid: date({
    required: false,
    table: { hidden: true },
    form: { ignore: true },
  }),
  completed: date({
    required: false,
    table: { hidden: true },
  }),
  notified: date({
    required: false,
    table: { hidden: true },
  }),
  modAudio: bool({
    defaultValue: false,
    table: { hidden: true },
  }),
  invoiceUrl: url({
    table: { hidden: true },
    required: false,
    form: { ignore: true },
  }),
  completitionStep: int({
    table: { hidden: true },
    required: false,
    form: { ignore: true },
  }),
  requestInvoiceId: int({
    table: { hidden: true },
    references: MODEL_NAME_REQUEST_INVOICE,
    pretty: 'Request Invoice',
    required: false,
    form: { ignore: true },
  }),
  lang: string({
    defaultValue: 'sk',
  }),
  requestedLang: string({
    defaultValue: 'sk',
  }),
});

export default requestProperties;
