/**
 * Returns a new function which collects parameters over a short period of time
 * specified by the delay parameter. Every time the function is called, the
 * timeout is reset.
 *
 * After `delay` milliseconds had elapsed with no function calls, the following
 * happens:
 *   1. Cumulate function is called with the collected parameters. The
 *      parameters are passed as an array of ParamType.
 *   2. If the function resolves, the results (of type CumulateType) are
 *      distributed using the distribute function which for every parameter
 *      value returns some value of type ReturnType. The stored promises are
 *      resolved.
 *   3. If the function rejects, all promises are rejected and the error is
 *      passed.
 *
 * @template ParamType
 * @template CumulateType
 * @template ReturnType
 *
 * @param {{
 *   cumulate: (param: Array<ParamType>) => Promise<CumulateType>,
 *   distribute: (res: CumulateType, param: ParamType) => ReturnType,
 *   delay: number,
 * }}
 */
export const delayedParameterCollector = ({ cumulate, distribute, delay }) => {
  /**
   * The active promises.
   *
   * @type {Array<{
   *   resolve: (res: ParamType) => void,
   *   reject: (err: Error) => void,
   *   param: ParamType
   * }>}
   */
  let promises = [];

  /**
   * The active timeout assigned by `setTimeout`.
   *
   * @type {number}
   */
  let timeout = null;

  /**
   * Runs the cumulate function and resolves or rejects all waiting promises.
   */
  const resolvePromises = () => {
    const toResolve = promises;

    promises = [];
    timeout = null;

    cumulate(toResolve.map(({ param }) => param))
      .then((res) => toResolve.forEach(({ param, resolve }) => resolve(distribute(res, param))))
      .catch((err) => toResolve.forEach(({ reject }) => reject(err)));
  };

  /**
   * The decorated function - this will be returned.
   *
   * @param {ParamType} param
   *
   * @returns {Promise<ReturnType>}
   */
  const decorated = (param) => {
    return new Promise((resolve, reject) => {
      promises.push({ param, resolve, reject });

      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(resolvePromises, delay);
    });
  };

  return decorated;
};
