<template>
  <span>
    <span v-if="error"></span>
    <span v-else-if="loading">{{ loadingText }}</span>
    <template v-else-if="connections.length > 0">
      <data-single-display
        v-for="(pk, i) in connections"
        :key="`${connection}-${pk}`"
        :prefix="i > 0 ? separator : ''"
        :loading-text="i === 0 ? loadingText : ''"
        :model="remoteModel"
        :pk="pk"
      />
    </template>
    <span v-else>-</span>
  </span>
</template>

<script>
import connector from '../../api/connector';
import getFactory from '../../model';

export default {
  name: 'data-single-connection-display',
  props: {
    model: {
      type: String,
      required: true,
    },
    pk: {
      type: [Number, String],
      required: true,
    },
    connection: {
      type: String,
      required: true,
    },
    separator: {
      type: String,
      default: ', ',
    },
    loadingText: {
      type: String,
      default: 'Loading ...',
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      connections: null,
    };
  },
  computed: {
    factory() {
      return getFactory(this.model);
    },
    remoteModel() {
      return this.factory.getConnectionModel(this.connection);
    },
    connectionsUrl() {
      return `/crud/${this.factory.getModelName()}/${this.pk}/connection/${this.connection}`;
    },
  },
  methods: {
    async fetch() {
      try {
        this.error = null;
        this.loading = true;
        this.connections = await connector.get(this.connectionsUrl);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetch();
  },
};
</script>
