import { MODEL_NAME_REQUEST, MODEL_NAME_USER, MODEL_NAME_VIDEO_STATUS } from '../../constants';
import DataStorageProperties from '../abstract/data-storage-properties';
import date from '../abstract/properties/property-date';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';
import bool from '../abstract/properties/property-bool';
import url from '../abstract/properties/property-url';

const videoProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  title: string({
    titleKey: true,
    readonly: true,
    required: false,
    form: { ignoreWhenNew: true },
  }),
  reviewTitle: string({
    pretty: 'Review',
    readonly: true,
    required: false,
    form: { ignore: true },
    table: { hidden: true },
  }),
  requestId: int({
    references: MODEL_NAME_REQUEST,
    pretty: 'Request',
  }),
  userId: int({
    references: MODEL_NAME_USER,
    pretty: 'User',
  }),
  videoStatusId: int({
    pretty: 'Video status',
    references: MODEL_NAME_VIDEO_STATUS,
  }),
  progress: int({
    required: false,
    form: { ignoreWhenNew: true },
    table: { hidden: true },
  }),
  worker: string({
    maxLength: 50,
    required: false,
    table: { hidden: true },
    form: { ignoreWhenNew: true },
  }),
  originalUrl: url({
    table: { hidden: true },
  }),
  webmUrl: url({
    required: false,
    table: { hidden: true },
    form: { ignoreWhenNew: true },
  }),
  mp4Url: url({
    required: false,
    table: { hidden: true },
    form: { ignoreWhenNew: true },
  }),
  thumbnailUrl: url({
    required: false,
    table: { hidden: true },
    form: { ignoreWhenNew: true },
  }),
  created: date({
    defaultValue: () => new Date(),
    readonly: true,
    table: { hidden: true },
  }),
  processed: date({
    required: false,
    form: { ignoreWhenNew: true },
  }),
  video: string({
    maxLength: 255,
    required: false,
    table: { hidden: true },
  }),
  progressText: string({
    maxLength: 255,
    required: false,
    form: { ignoreWhenNew: true },
    table: { hidden: true },
  }),
  watermark: bool({
    required: false,
    table: { hidden: true },
    form: { ignoreWhenNew: true },
  }),
  uuid: string({
    maxLength: 50,
    required: false,
    table: { hidden: true },
  }),
  voteCode: string({
    maxLength: 50,
    required: false,
    table: { hidden: true },
  }),
  vote: int({
    required: false,
    table: { hidden: true },
  }),
  review: string({
    required: false,
    table: { hidden: true },
  }),
  original: url({
    required: false,
    table: { hidden: true },
    form: { ignoreWhenNew: true },
  }),
  isAudio: bool({
    defaultValue: false,
  }),
  mp3Url: url({
    required: false,
    table: { hidden: true },
    form: { ignoreWhenNew: true },
  }),
  oggUrl: url({
    required: false,
    table: { hidden: true },
    form: { ignoreWhenNew: true },
  }),
  voteSent: date({
    pretty: 'Created',
    required: false,
    table: { hidden: true },
  }),
  reviewSent: date({
    required: false,
    table: { hidden: true },
  }),
  reviewNotificationSent: date({
    required: false,
    readonly: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
});

export default videoProperties;
