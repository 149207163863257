<template>
  <vue-select :options="options" v-model="selected" />
</template>

<script>
import VueSelect from 'vue-select';
import DateBuilder from '../../../utils/date-builder';

const dateCalculators = [
  // anytime
  () => null,
  // today
  () => [DateBuilder.now().dayStart().build(), DateBuilder.now().dayEnd().build()],
  // yesterday
  () => [
    DateBuilder.now().subtractDays(1).dayStart().build(),
    DateBuilder.now().subtractDays(1).dayEnd().build(),
  ],
  // this week
  () => [DateBuilder.now().weekStart().build(), DateBuilder.now().weekEnd().build()],
  // last week
  () => [
    DateBuilder.now().subtractWeeks(1).weekStart().build(),
    DateBuilder.now().subtractWeeks(1).weekEnd().build(),
  ],
  // this month
  () => [DateBuilder.now().monthStart().build(), DateBuilder.now().monthEnd().build()],
  // last month
  () => [
    DateBuilder.now().subtractMonths(1).monthStart().build(),
    DateBuilder.now().subtractMonths(1).monthEnd().build(),
  ],
  // last three months
  () => [
    DateBuilder.now().subtractMonths(2).monthStart().build(),
    DateBuilder.now().monthEnd().build(),
  ],
  // last 6 months
  () => [
    DateBuilder.now().subtractMonths(5).monthStart().build(),
    DateBuilder.now().monthEnd().build(),
  ],
  // this year
  () => [DateBuilder.now().yearStart().build(), DateBuilder.now().yearEnd().build()],
  // last year
  () => [
    DateBuilder.now().subtractYears(1).yearStart().build(),
    DateBuilder.now().subtractYears(1).yearEnd().build(),
  ],
];

const ANYTIME = 0;
const TODAY = 1;
const YESTERDAY = 2;
const THIS_WEEK = 3;
const LAST_WEEK = 4;
const THIS_MONTH = 5;
const LAST_MONTH = 6;
const LAST_3_MONTHS = 7;
const LAST_6_MONTHS = 8;
const THIS_YEAR = 9;
const LAST_YEAR = 10;

const options = [
  { label: 'Anytime', value: ANYTIME },
  { label: 'Today', value: TODAY },
  { label: 'Yesterday', value: YESTERDAY },
  { label: 'This week', value: THIS_WEEK },
  { label: 'Last week', value: LAST_WEEK },
  { label: 'This month', value: THIS_MONTH },
  { label: 'Last month', value: LAST_MONTH },
  { label: 'Last 3 months', value: LAST_3_MONTHS },
  { label: 'Last 6 months', value: LAST_6_MONTHS },
  { label: 'This year', value: THIS_YEAR },
  { label: 'Last year', value: LAST_YEAR },
];

export default {
  name: 'date-range-filter',
  components: {
    VueSelect,
  },
  props: {
    clear: {},
  },
  data() {
    return {
      selected: null,
      options,
      range: null,
    };
  },
  watch: {
    selected() {
      this.range = this.selected ? dateCalculators[this.selected.value]() : null;
      this.$emit('input', this.range);
    },
    clear() {
      this.selected = null;
      this.range = null;
    },
  },
};
</script>

<style></style>
