<template>
  <!-- TODO implement -->
  <div class="user-videos-tab">
    <div class="card videos-on-profile">
      <p class="title">Videos on the user's profile</p>
      <div class="mt-2">
        <span v-if="loading">Loading ...</span>
        <span v-else-if="error"> {{ error }} </span>
        <video-grid-layout
          v-else-if="promotionalVideos"
          :videos="promotionalVideos"
          :promotionalVideos="promotionalVideosIds"
          :profileVideoId="single.profileVideoId"
          :disabled="disabled"
          :error="error"
          :hasActions="true"
          @setProfileVideo="setProfileVideo"
          @addPromotionalVideo="addPromotionalVideo"
          @removePromotionalVideo="removePromotionalVideo"
        />
        <span v-else>
          <b>No videos have been added to the user's profile!</b>
        </span>
      </div>
    </div>
    <div class="card recorded-videos mt-3">
      <p class="title">Recorded videos of the user</p>
      <div>
        <span v-if="loading">Loading ...</span>
        <span v-else-if="error"> {{ error }} </span>
        <video-grid-layout
          v-else-if="videos"
          :videos="videos"
          :promotionalVideos="promotionalVideosIds"
          :profileVideoId="single.profileVideoId"
          :disabled="disabled"
          :error="error"
          :hasActions="true"
          @setProfileVideo="setProfileVideo"
          @addPromotionalVideo="addPromotionalVideo"
          @removePromotionalVideo="removePromotionalVideo"
        />
        <span v-else>
          <b>No videos have been recorded!</b>
        </span>
      </div>
      <data-table
        :model="MODEL_NAME_VIDEO"
        hideNewButton
        hideDeleteButton
        hideFilters
        :filters="{ 'userId.eq': single.pk() }"
        :overrideColumns="['title', 'webmUrl']"
      />
    </div>
  </div>
</template>

<script>
import { MODEL_NAME_VIDEO, MODEL_NAME_USER_PROMOTIONAL_VIDEO } from '../../constants';
import { userPromotionalVideoFactory } from '../../model';
import videoFactory from '../../model/video/video-factory';
import { isArray, isEmpty } from 'lodash';
import videoGridLayout from '../../components/layout/VideoGridLayout.vue';
import modal from '../../plugins/modal/modal-component';

export default {
  name: 'user-videos-tab',
  props: ['single'],
  components: {
    videoGridLayout,
  },
  data() {
    return {
      MODEL_NAME_VIDEO,
      MODEL_NAME_USER_PROMOTIONAL_VIDEO,
      loading: false,
      error: null,
      videos: null,
      promotionalVideos: null,
      promotionalVideosIds: null,
      disabled: false,
    };
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        this.error = null;
        // retrieve only videos, not audios
        this.videos = await videoFactory.fetch({
          'userId.eq': this.single.id,
          'isAudio.eq': false,
        });

        if (!isArray(this.videos)) {
          this.videos = [this.videos];
        }

        this.promotionalVideosIds = await userPromotionalVideoFactory.fetch({
          'userId.eq': this.single.id,
        });

        // if some promotional videos exist
        if (this.promotionalVideosIds && !isEmpty(this.promotionalVideosIds)) {
          // in case there exists only one promotional video
          if (!isArray(this.promotionalVideosIds)) {
            // no need to sort here
            this.promotionalVideosIds = [this.promotionalVideosIds];
          } else {
            this.promotionalVideosIds.sort((first, second) => {
              if (first.id < second.id) {
                return -1;
              }
              if (first.id > second.id) {
                return 1;
              }
              return 0;
            });
          }

          // retrieve the Video model so that the videos can be rendered
          this.promotionalVideos = await videoFactory.pkMultiple(
            this.promotionalVideosIds.map((video) => video.videoId)
          );

          if (!isArray(this.promotionalVideos)) {
            this.promotionalVideos = [this.promotionalVideos];
          }
        } else {
          // if no videos exist, display error message
          this.promotionalVideos = undefined;
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    async setProfileVideo(video) {
      try {
        this.disabled = true;
        this.error = null;
        this.single.profileVideoId = video.id;
        this.single.profileVideoUrl = video.webmUrl;
        await this.single.save();
      } catch (e) {
        this.error = e;
      } finally {
        this.disabled = false;
        modal.alert('The profile video has been set!');
      }
    },
    async addPromotionalVideo(video) {
      try {
        this.disabled = true;
        this.error = null;
        const videoId = video.id;
        const userId = this.single.id;
        const promotionalVideosLength = this.promotionalVideosIds.length;

        // -1 since we want to start from 0 if no videos were added
        let lastOrd = -1;
        if (promotionalVideosLength > 0) {
          lastOrd = this.promotionalVideosIds[promotionalVideosLength - 1].ord;
        }

        // update the database
        await userPromotionalVideoFactory
          .blank({
            userId,
            videoId,
            ord: lastOrd + 1,
          })
          .save();

        // update the local state
        this.promotionalVideos.push(video);

        this.getData();
      } catch (e) {
        this.error = e;
        console.log(e);
      } finally {
        this.disabled = false;
        modal.alert('The promotional videos have been updated!');
      }
    },
    async removePromotionalVideo(video) {
      try {
        this.disabled = true;
        this.error = null;

        const videoToRemove = this.promotionalVideosIds.find((v) => v.videoId === video.id);

        await userPromotionalVideoFactory.delete(videoToRemove);
        this.promotionalVideos.filter((v) => v.id !== video.id);

        this.getData();
      } catch (e) {
        this.error = e;
      } finally {
        this.disabled = false;
        modal.alert('The promotional videos have been updated!');
      }
    },
  },
  created() {
    this.getData();
  },
  watch: {
    single() {
      this.getData();
    },
  },
};
</script>

<style lang="scss">
.user-videos-tab {
  color: white;

  .card {
    background-color: #121417;
    border-radius: 4px;
    border: 1px solid #0b0c0e;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
    }
  }
}
</style>
