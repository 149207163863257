<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-corner-right-up"
  >
    <polyline points="10 9 15 4 20 9"></polyline>
    <path d="M4 20h7a4 4 0 0 0 4-4V4"></path>
  </svg>
</template>

<script>
export default {
  name: 'icon-feather-corner-right-up',
};
</script>
