import modal from './modal-component';

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        $modal() {
          return modal;
        },
      },
    });
  },
};
