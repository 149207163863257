<template>
  <input
    class="form-control"
    placeholder="Example: +421 999 888 777"
    v-model="valueStorage"
    v-bind="$attrs"
    type="tel"
  />
</template>

<script>
export default {
  name: 'tel-input',
  props: ['value', 'properties', 'type'],
  data() {
    return {
      valueStorage: this.value,
    };
  },
  watch: {
    valueStorage() {
      this.$emit('input', this.valueStorage);
    },
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.valueStorage = newValue;
      }
    },
  },
};
</script>
