/** @typedef {import('../data-storage-property-constructor-params').default} DataStoragePropertyConstructorParams */

import merge from 'lodash/merge';
import { PROPERTY_TYPE_STRING } from '../../../constants';
import DataStorageProperty from '../data-storage-property';
import { HOST } from '../../../environment';

/**
 * The default parameters for the constructor of PropertyUrl.
 */
class PropertyUrlConstructorParams {
  /**
   * The maximal number of characters.
   *
   * @type {number}
   */
  maxLength = 255;

  table = {
    formatFn: (value) => `<a href="${HOST}${value}" target="_blank">${value}</a>`,
    html: true,
  };
}

/** @typedef {DataStoragePropertyConstructorParams & PropertyUrlConstructorParams} ConstructorParams */

/**
 * A property of type string (varchar).
 */
class PropertyUrl extends DataStorageProperty {
  propertyType = () => PROPERTY_TYPE_STRING;
  tableColumnType = () => 'string';
  formComponentName = () => 'url-input';

  /**
   * Creates a new instance of PropertyUrl.
   *
   * @param {ConstructorParams} params
   */
  constructor(params = {}) {
    params = merge(new PropertyUrlConstructorParams(), params);

    super(params);

    this.maxLength = params.maxLength;
  }
}

/** @param {ConstructorParams} params */
const url = (params = {}) => new PropertyUrl(params);

export default url;
