<template>
  <!-- TODO display currency with the number-->
  <span>
    <input
      class="form-control"
      v-model="valueStorage"
      v-bind="$attrs"
      type="number"
      :min="min"
      :max="max"
      :step="step"
    />
  </span>
</template>

<script>
export default {
  name: 'price-input',
  props: ['value', 'properties', 'type', 'min', 'max', 'step'],
  data() {
    return {
      valueStorage: this.value,
    };
  },
  watch: {
    valueStorage() {
      this.$emit('input', Number(this.valueStorage));
    },
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.valueStorage = Number(newValue);
      }
    },
  },
};
</script>
