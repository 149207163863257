<template>
  <data-table
    :model="MODEL_NAME_REQUEST"
    hideNewButton
    hideEditButton
    hideDeleteButton
    hideFilters
    hideAggregates
    :filters="{ 'pk.in': single.pk() }"
    :overrideColumns="[
      'amount',
      'amountUser',
      'stripePiId',
      'stripePiSecret',
      'modExpress',
      'modNoWatermark',
      'paid',
      'requestInvoiceId',
      'invoiceUrl',
    ]"
  />
</template>

<script>
import { MODEL_NAME_REQUEST } from '../../constants';

export default {
  name: 'request-payments-tab',
  data() {
    return {
      MODEL_NAME_REQUEST,
    };
  },
  props: ['single'],
};
</script>
