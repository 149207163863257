<template>
  <data-table
    :model="MODEL_NAME_USER"
    hideNewButton
    hideEditButton
    hideDeleteButton
    hideFilters
    hideAggregates
    :filters="{ 'pk.in': single.pk() }"
    :overrideColumns="[
      'contactVerified',
      'smsCode',
      'emailCode',
      'consentTermsAndConditions',
      'consentPersonalData',
      'consentCommunityPolicy',
      'consentFrameworkContract',
    ]"
  />

  <!-- displays user's verifications -->
</template>

<script>
import { MODEL_NAME_USER } from '../../constants';

export default {
  name: 'user-verifications-tab',
  data() {
    return {
      MODEL_NAME_USER,
    };
  },
  props: ['single'],
};
</script>
