<template>
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.65001 13.04C3.69383 13.04 4.54001 12.1938 4.54001 11.15C4.54001 10.1062 3.69383 9.26001 2.65001 9.26001C1.60619 9.26001 0.76001 10.1062 0.76001 11.15C0.76001 12.1938 1.60619 13.04 2.65001 13.04Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.35 4.73996C18.3938 4.73996 19.24 3.89378 19.24 2.84996C19.24 1.80614 18.3938 0.959961 17.35 0.959961C16.3062 0.959961 15.46 1.80614 15.46 2.84996C15.46 3.89378 16.3062 4.73996 17.35 4.73996Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.98999 9.81993L7.64999 6.15993L10.84 9.34993L16.01 4.17993"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'product-statistics-icon',
};
</script>
