import { MODEL_NAME_NEWSLETTER_ADDRESS } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import NewsletterAddress from './newsletter-address-class';
import newsletterAddressProperties from './newsletter-address-properties';

/** @extends {DataStorageFactory<NewsletterAddress>} */
class NewsletterAddressFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_NEWSLETTER_ADDRESS;

  getSingular = () => 'Newsletter Address';
  getPlural = () => 'newsletter Addresses';

  getIcon = () => 'newsletter-address-icon';

  getTabs = () => [];
}

const newsletterAddressFactory = new NewsletterAddressFactory(
  newsletterAddressProperties,
  NewsletterAddress.prototype
);

export default newsletterAddressFactory;
