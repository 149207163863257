import { MODEL_NAME_DOCUMENT_TEMPLATE } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import DocumentTemplate from './document-template-class';
import documentTemplateProperties from './document-template-properties';

/** @extends {DataStorageFactory<DocumentTemplate>} */
class DocumentTemplateFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_DOCUMENT_TEMPLATE;

  getSingular = () => 'Document Template';
  getPlural = () => 'document templates';

  getIcon = () => 'document-template-icon';

  getTabs = () => [
    {
      name: 'Versions',
      slug: 'versions',
      description: 'The list of the document template versions.',
    },
  ];

  canUpdate = () => false;
  canInsert = () => false;
  canDelete = () => false;
}

const documentTemplateFactory = new DocumentTemplateFactory(
  documentTemplateProperties,
  DocumentTemplate.prototype
);

export default documentTemplateFactory;
