/** @typedef {import('../data-storage-property-constructor-params').default} DataStoragePropertyConstructorParams */

import merge from 'lodash/merge';
import { PROPERTY_TYPE_PRICE } from '../../../constants';
import DataStorageProperty from '../data-storage-property';

/**
 * The default parameters for the constructor of PropertyPrice.
 */
class PropertyPriceConstructorParams {
  /**
   * The minimal value the price can take.
   *
   * @type {number}
   */
  min = undefined;

  /**
   * The maximal value the price can take.
   *
   * @type {number}
   */
  max = undefined;

  /**
   * The precision of the price.
   * @type {number}
   */
  precision = undefined;
}

/** @typedef {DataStoragePropertyConstructorParams & PropertyPriceConstructorParams} ConstructorParams */

/**
 * A property of type price.
 */
class PropertyPrice extends DataStorageProperty {
  propertyType = () => PROPERTY_TYPE_PRICE;
  tableColumnType = () => 'number';
  formComponentName = () => 'price-input';

  /**
   * Creates a new instance of PropertyPrice.
   *
   * @param {ConstructorParams} params
   */
  constructor(params = {}) {
    params = merge(new PropertyPriceConstructorParams(), params);

    super(params);

    this.min = params.min;
    this.max = params.max;
    this.precision = params.precision;
  }

  _formDescriptorImpl() {
    const descriptor = super._formDescriptorImpl();

    descriptor.componentProps.min = this.min;
    descriptor.componentProps.max = this.max;
    descriptor.componentProps.precision = this.precision;
    descriptor.componentProps.step = 1 / 10 ** this.precision;

    return descriptor;
  }
}

/** @param {ConstructorParams} params */
const price = (params = {}) => new PropertyPrice(params);

export default price;
