<template>
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.13 2.16V11.84C1.13 12.7 2.08 13.21 2.8 12.75L10.37 7.91C11.04 7.48 11.04 6.51 10.37 6.09L2.8 1.25C2.08 0.790001 1.13 1.31 1.13 2.16Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'video-icon',
};
</script>
