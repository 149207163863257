<template>
  <div>
    <div>
      <span v-if="loading">Loading ...</span>
      <span v-else-if="errors"> {{ errors }} </span>
      <card v-else title="User statistics" description="Holds user statistics">
        <data-table
          :model="MODEL_NAME_USER_REQUEST_SUCCESS_FAIL_COUNT"
          hideAggregates
          hideNewButton
          hideFilters
          :userSortOptions="{
            enabled: true,
            multipleColumns: true,
            initialSortBy: [
              { field: 'successful', type: 'desc' },
              { field: 'cancelled', type: 'asc' },
            ],
          }"
        />
      </card>
    </div>
  </div>
</template>

<script>
import { MODEL_NAME_USER_REQUEST_SUCCESS_FAIL_COUNT } from '../../constants';

export default {
  name: 'page-user-stats',
  data: () => ({
    loading: false,
    errors: null,
    MODEL_NAME_USER_REQUEST_SUCCESS_FAIL_COUNT,
  }),
};
</script>

<style></style>
