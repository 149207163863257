<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.99 11.8101L14.8 8.00006L10.99 4.19006"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3 8.00012H4.55005"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.72995 1.39001H3.20995C2.09995 1.39001 1.19995 2.29001 1.19995 3.40001V12.6C1.19995 13.71 2.09995 14.61 3.20995 14.61H7.72995"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'icon-logout-icon',
};
</script>
