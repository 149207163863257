// Layouts
import SimpleLayout from '../layouts/simple-layout/SimpleLayout.vue';
import DashboardLayout from '../layouts/dashboard-layout/DashboardLayout.vue';

// 404
import NotFound from '../views/404.vue';
const notFound = {
  path: '*',
  component: NotFound,
  meta: { contentClass: 'd-flex align-items-center justify-content-center' },
};

// Login
import Login from '../views/Login.vue';
const login = {
  path: '/login',
  component: Login,
  meta: { contentClass: 'd-flex align-items-center justify-content-center' },
};

// Home
import home from './home';

// Model
import ModelList from '../views/ModelList.vue';
import ModelSingle from '../views/ModelSingle.vue';

import getFactory from '../model';

const modelList = {
  path: '/model/:model',
  component: ModelList,
  meta: {
    breadcrumbs: (route) => {
      const factory = getFactory(route.params.model);

      return [{ path: home.path, name: home.name }, { name: factory.getPlural() }];
    },
  },
};

const modelSingle = {
  path: '/model/:model/:pk',
  component: ModelSingle,
  meta: {
    breadcrumbs: async (route) => {
      const factory = getFactory(route.params.model);

      if (route.params.pk === 'new') {
        return [
          { path: home.path, name: home.name },
          { path: factory.getPath(), name: factory.getPlural() },
          { name: 'new' },
        ];
      }
      return [
        { path: home.path, name: home.name },
        { path: factory.getPath(), name: factory.getPlural() },
        { name: (await factory.pk(route.params.pk)).tk() },
      ];
    },
  },
};

const modelSingleTab = {
  path: '/model/:model/:pk/:tab',
  component: ModelSingle,
  meta: {
    breadcrumbs: async (route) => {
      const factory = getFactory(route.params.model);

      return [
        { path: home.path, name: home.name },
        { path: factory.getPath(), name: factory.getPlural() },
        { name: (await factory.pk(route.params.pk)).tk() },
        { name: factory.getTabName(route.params.tab) },
      ];
    },
  },
};

import EmailEditor from '../views/EmailEditor.vue';

const emailEditor = {
  path: '/email-editor',
  component: EmailEditor,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'Email Editor' }];
    },
  },
};

import SmsEditor from '../views/SmsEditor.vue';

const smsEditor = {
  path: '/sms-editor',
  component: SmsEditor,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'Sms Editor' }];
    },
  },
};

import BasicStats from '../views/stats/BasicStats.vue';

const basicStats = {
  path: '/stats/basic',
  component: BasicStats,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'Basic Statistics' }];
    },
  },
};

import UserStats from '../views/stats/UserStats.vue';

const userStats = {
  path: '/stats/user',
  component: UserStats,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'User Statistics' }];
    },
  },
};

import UserAdvancedStats from '../views/stats/UserAdvancedStats.vue';

const userAdvancedStats = {
  path: '/stats/user-advanced',
  component: UserAdvancedStats,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'User Advanced Statistics' }];
    },
  },
};

import RequestStats from '../views/stats/RequestStats.vue';

const requestStats = {
  path: '/stats/request',
  component: RequestStats,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'Request Statistics' }];
    },
  },
};

import ProductStats from '../views/stats/ProductStats.vue';

const productStats = {
  path: '/stats/product',
  component: ProductStats,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'Product Statistics' }];
    },
  },
};

import PublicUsers from '../views/user-pages/PublicUsers.vue';

const publicUsers = {
  path: '/users/public-users',
  component: PublicUsers,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'Public Users' }];
    },
  },
};

import NonPublicUsers from '../views/user-pages/NonPublicUsers.vue';

const nonPublicUsers = {
  path: '/users/non-public-users',
  component: NonPublicUsers,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'Non-public Users' }];
    },
  },
};

import NotApprovedUsers from '../views/user-pages/NotApprovedUsers.vue';

const notApprovedUsers = {
  path: '/users/not-approved-users',
  component: NotApprovedUsers,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'Not Approved Users' }];
    },
  },
};

import CompletedRequests from '../views/order-pages/CompletedRequests.vue';

const completedRequests = {
  path: '/orders/completed-requests',
  component: CompletedRequests,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'Completed Requests' }];
    },
  },
};

import Reviews from '../views/order-pages/Reviews.vue';

const reviews = {
  path: '/orders/reviews',
  component: Reviews,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'Reviews' }];
    },
  },
};

import Videos from '../views/order-pages/Videos.vue';

const videos = {
  path: '/orders/videos',
  component: Videos,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'Videos' }];
    },
  },
};

import Audios from '../views/order-pages/Audios.vue';

const audios = {
  path: '/orders/audios',
  component: Audios,
  meta: {
    breadcrumbs: () => {
      return [{ path: home.path, name: home.name }, { name: 'Audios' }];
    },
  },
};

import Dashboard from '../views/home/Dashboard.vue';

const dashboard = {
  path: '/dashboard',
  component: Dashboard,
  meta: {
    breadcrumbs: () => [],
  },
};

// Actual routes
const routes = [
  { path: '/', redirect: '/home' }, // Initial redirection
  {
    path: '',
    component: DashboardLayout,
    children: [
      home,
      modelList,
      modelSingle,
      modelSingleTab,
      emailEditor,
      smsEditor,
      basicStats,
      userStats,
      userAdvancedStats,
      requestStats,
      productStats,
      publicUsers,
      nonPublicUsers,
      notApprovedUsers,
      completedRequests,
      reviews,
      videos,
      audios,
      dashboard,
    ],
  },
  {
    path: '',
    component: SimpleLayout,
    children: [login, notFound],
  },
];

export default routes;
