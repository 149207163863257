import { MODEL_NAME_REQUEST_DATE_RANGE_COUNT } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import RequestDateRangeCount from './request-date-range-count-class';
import requestDateRangeCountProperties from './request-date-range-count-properties';

/** @extends {DataStorageFactory<RequestDateRangeCount>} */
class RequestDateRangeCountFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_REQUEST_DATE_RANGE_COUNT;

  getSingular = () => 'Request Date Range';
  getPlural = () => 'request Date Ranges';

  getIcon = () => 'feather-bar-chart-2';

  getTabs = () => [];
}

const requestDateRangeCountFactory = new RequestDateRangeCountFactory(
  requestDateRangeCountProperties,
  RequestDateRangeCount.prototype
);

export default requestDateRangeCountFactory;
