<template>
  <input class="form-control" v-model="valueStorage" v-bind="$attrs" type="number" />
</template>

<script>
export default {
  name: 'number-input',
  props: ['value', 'properties', 'type'],
  data() {
    return {
      valueStorage: this.value,
    };
  },
  watch: {
    valueStorage() {
      this.$emit('input', Number(this.valueStorage));
    },
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.valueStorage = Number(newValue);
      }
    },
  },
};
</script>
