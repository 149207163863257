<template>
  <date-picker
    class="date-input"
    v-model="valueStorage"
    monday-first
    bootstrap-styling
    minimum-view="day"
    maximum-view="month"
    v-bind="$attrs"
  />
</template>

<script>
import DatePicker from 'vuejs-datepicker';

export default {
  name: 'date-input',
  components: {
    DatePicker,
  },
  props: ['value', 'properties', 'type'],
  data() {
    return {
      valueStorage: this.value ? new Date(this.value) : undefined,
    };
  },
  watch: {
    valueStorage() {
      this.$emit('input', this.valueStorage);
    },
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.valueStorage = newValue;
      }
    },
  },
};
</script>

<style lang="scss">
.date-input .form-control:read-only {
  background-color: white;
}
</style>
