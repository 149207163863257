<template>
  <data-table
    :model="MODEL_NAME_DOCUMENT_TEMPLATE_VERSION"
    hideNewButton
    hideEditButton
    hideDeleteButton
    hideFilters
    :filters="{ 'slug.eq': single.slug }"
  />
</template>

<script>
import { MODEL_NAME_DOCUMENT_TEMPLATE_VERSION } from '../../constants';

export default {
  name: 'document-template-versions-tab',
  data() {
    return {
      MODEL_NAME_DOCUMENT_TEMPLATE_VERSION,
    };
  },
  props: ['single'],
};
</script>

<style></style>
