<template>
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.16 7.51001H2.84001C1.82933 7.51001 1.01001 8.32933 1.01001 9.34001V14.88C1.01001 15.8907 1.82933 16.71 2.84001 16.71H13.16C14.1707 16.71 14.99 15.8907 14.99 14.88V9.34001C14.99 8.32933 14.1707 7.51001 13.16 7.51001Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.99991 1.29004C10.1199 1.29004 11.8499 3.01004 11.8499 5.14004V7.51004H4.15991V5.14004C4.15991 3.02004 5.87991 1.29004 8.00991 1.29004H7.99991Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 10.8401V13.3801"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'lock-icon',
};
</script>
