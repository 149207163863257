import DataStorageProperties from '../abstract/data-storage-properties';
import bool from '../abstract/properties/property-bool';
import date from '../abstract/properties/property-date';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';

const newsletterAddressProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  email: string({
    searchable: true,
    titleKey: true,
  }),
  code: string({
    table: { hidden: true },
  }),
  signed: date({
    required: false,
    defaultValue: () => new Date(),
    table: { hidden: true },
  }),
  unsigned: date({
    required: false,
    table: { hidden: true },
  }),
  isSigned: bool({
    defaultValue: true,
  }),
});

export default newsletterAddressProperties;
