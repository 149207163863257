<template>
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 7.90173H0.989929V18.8162H15V7.90173Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00067 8.91467V17.8033"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.4566 13.3647H14.5448"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.7671 5.47515C13.0038 4.23846 13.4865 2.71609 12.8453 2.07484C12.204 1.43359 10.6817 1.9163 9.44498 3.15299C8.20829 4.38968 7.72558 5.91205 8.36683 6.55329C9.00808 7.19454 10.5304 6.71184 11.7671 5.47515Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.62989 6.56408C8.27114 5.92283 7.78843 4.40046 6.55174 3.16377C5.31505 1.92708 3.79268 1.44438 3.15144 2.08562C2.51019 2.72687 2.99289 4.24924 4.22958 5.48593C5.46627 6.72262 6.98864 7.20532 7.62989 6.56408Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'occasion-icon',
};
</script>
