/** @typedef {import('../data-storage-property-constructor-params').default} DataStoragePropertyConstructorParams */

import merge from 'lodash/merge';
import { PROPERTY_TYPE_DATE } from '../../../constants';
import DataStorageProperty from '../data-storage-property';
import { format } from 'date-fns';

/**
 * The default parameters for the constructor of PropertyDate.
 */
class PropertyDateConstructorParams {
  table = {
    formatFn: (value) =>
      value === null
        ? '-'
        : `<div class="property-date-formatted"><span class="font-normal">${format(
            new Date(value),
            'dd. MM. yyyy • '
          )}</span>${format(new Date(value), 'HH:mm:ss')}</div>`,
    html: true,
  };
}

/** @typedef {DataStoragePropertyConstructorParams & PropertyDateConstructorParams} ConstructorParams */

/**
 * A property of type date.
 */
class PropertyDate extends DataStorageProperty {
  propertyType = () => PROPERTY_TYPE_DATE;
  tableColumnType = () => 'string';
  formComponentName = () => 'date-input';

  /**
   * Creates a new instance of PropertyDate.
   *
   * @param {ConstructorParams} params
   */
  constructor(params = {}) {
    params = merge(new PropertyDateConstructorParams(), params);

    super(params);
  }
}

/** @param {ConstructorParams} params */
const date = (params = {}) => new PropertyDate(params);

export default date;
