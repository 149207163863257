<template>
  <div v-if="loading">Loading...</div>
  <div v-else-if="error">{{ error }}</div>
  <div v-else>
    <video controls width="170" class="video">
      <source :src="webmUrl()" type="video/webm" />
      <source :src="mp4Url()" type="video/mp4" />
      Your browser does not support embedded videos.
    </video>
    <div class="mt-2">
      <div class="video-info-title">Request</div>
      <router-link :to="requestPath()" class="video-info-link"
        >Request #{{ video.requestId }}
      </router-link>
    </div>
    <div class="mt-1" v-if="!hasActions">
      <div class="video-info-title">Recorded by</div>
      <span v-if="loading">Loading ...</span>
      <span v-else-if="error"> {{ error }} </span>
      <router-link :to="userPath()" class="video-info-link" v-else>{{
        video.userName
      }}</router-link>
    </div>
    <div v-if="hasActions">
      <div v-if="privacy" class="private-video">
        {{ `Video ${privacy ? 'je súkromné' : ''}` }}
      </div>
      <div v-else class="video-actions">
        <div>
          <button
            @click="setProfileVideo()"
            class="btn w-full btn-primary btn-small text-white mr-2"
            :disabled="isProfileVideo() || disabled"
          >
            Set profile video
          </button>
        </div>
        <div>
          <button
            @click="addPromotionalVideo()"
            class="btn w-full btn-primary btn-small text-white mr-2"
            :disabled="canAdd() || isProfileVideo() || disabled"
          >
            Add promotional
          </button>
        </div>
        <div>
          <button
            @click="removePromotionalVideo()"
            class="btn w-full btn-primary btn-small text-white mr-2"
            :disabled="!canAdd() || disabled"
          >
            Remove promotional
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PRIVATE_VIDEO_PRODUCT_ID } from '../../constants';
import { requestProductFactory } from '../../model';

export default {
  name: 'video-box',
  props: ['video', 'hasActions', 'disabled', 'promotionalVideos', 'profileVideoId'],
  data() {
    return {
      isPrivate: true,
      loading: false,
      error: null,
    };
  },
  methods: {
    setProfileVideo() {
      this.$emit('setProfileVideo', this.video);
    },
    addPromotionalVideo() {
      this.$emit('addPromotionalVideo', this.video);
    },
    removePromotionalVideo() {
      this.$emit('removePromotionalVideo', this.video);
    },
    isProfileVideo() {
      return this.video.id === this.profileVideoId;
    },
    async getPrivacyStatus() {
      this.loading = true;
      const productsForVideo = await requestProductFactory.fetch({
        'requestId.eq': this.video.requestId,
      });

      const res = productsForVideo.find(
        (product) => product.productId === PRIVATE_VIDEO_PRODUCT_ID
      );

      this.isPrivate = res !== undefined;
      this.loading = false;
    },
    canAdd() {
      return this.promotionalVideos
        ? this.promotionalVideos.find((v) => v.videoId === this.video.id)
        : false;
    },
    webmUrl() {
      return this.video.webmUrl;
      // NOTE this is for testing purposes
      // return `http://dev.showtimes.sk${this.video.webmUrl}`;
    },
    mp4Url() {
      return this.video.mp4Url;
      // NOTE this is for testing purposes
      // return `http://dev.showtimes.sk${this.video.mp4Url}`;
    },
    requestPath() {
      return `/model/request/${this.video.requestId}`;
    },
    userPath() {
      return `/model/user/${this.video.userId}`;
    },
  },
  computed: {
    privacy() {
      return this.isPrivate;
    },
  },
  async created() {
    await this.getPrivacyStatus();
  },
};
</script>
