import globalComponents from './global-components';
import modal from './plugins/modal/modal-plugin';
import keyboardTracker from './plugins/keyboard-tracker';

export default {
  install(Vue) {
    Vue.use(globalComponents);
    Vue.use(modal);
    Vue.use(keyboardTracker);
  },
};
