/** @typedef {import('./data-storage').default} DataStorage */
/** @typedef {import('./data-storage-properties.js').default} DataStorageProperties  */

import memoize from 'lodash/memoize';
import connector from '../../api/connector';
import DataStorage from './data-storage';
import { delayedParameterCollector } from '../../utils/decorators/delayed-parameter-collector';
import uniq from 'lodash.uniq';
import { thisBinder } from '../../utils/decorators/this-binder';

/**
 * Abstract class used as a base for every factory class.
 *
 * DataStorageFactory is responsible for:
 *  - creating DataStorage instances
 *  - fetching data from API
 *  - updating the API
 *
 * The DataStorage template parameter must be a class which inherits from
 * the DataStorage class.
 *
 * @template {DataStorage} StorageType
 */
class DataStorageFactory {
  /**
   * Returns the name of the primary key.
   *
   * @returns {string}
   */
  _getPrimaryKeyImpl() {
    console.debug('DataStorageFactory._getPrimaryKeyImpl');

    const prop = this.properties.find((prop) => prop.primaryKey);

    if (!prop) {
      throw new Error('No primary key column');
    }

    return prop.prop;
  }

  getPrimaryKey = memoize(this._getPrimaryKeyImpl);

  /**
   * Returns the name of the title key. Title key is the key which will be
   * displayed first in the data table component.
   *
   * @returns {string}
   */
  _getTitleKeyImpl() {
    console.debug('DataStorageFactory._getTitleKeyImpl');

    const prop = this.properties.find((prop) => prop.titleKey);

    if (!prop) {
      return this.getPrimaryKey();
    }

    return prop.prop;
  }

  getTitleKey = memoize(this._getTitleKeyImpl);

  /**
   * Returns the columns config used by data-table.
   */
  _tableDescriptorImpl() {
    console.debug('DataStorageFactory._tableDescriptorImpl');

    const descriptor = this.properties.tableDescriptor();

    if (descriptor.length > 0) {
      descriptor[0].label = this.getSingular();
    }

    return descriptor;
  }

  tableDescriptor = memoize(this._tableDescriptorImpl);

  /**
   * Returns the full config used by data-form.
   */
  _formDescriptorImpl() {
    console.debug('DataStorageFactory._formDescriptorImpl');

    return this.properties.formDescriptor();
  }

  formDescriptor = memoize(this._formDescriptorImpl);

  /**
   * Returns the name of the model.
   *
   * This method MUST be overridden.
   *
   * @returns {string}
   */
  getModelName() {
    throw new Error('Not implemented');
  }

  /**
   * Returns the singular name of the type.
   *
   * This method MUST be overridden.
   *
   * @returns {string}
   */
  getSingular() {
    throw new Error('Not implemented');
  }

  /**
   * Returns the plural name of the type.
   *
   * This method MUST be overridden.
   *
   * @returns {string}
   */
  getPlural() {
    throw new Error('Not implemented');
  }

  /**
   * Returns the name of the icon used by the model.
   *
   * This method MUST be overridden.
   *
   * @returns {string}
   */
  getIcon() {
    throw new Error('Not implemented');
  }

  /**
   * Returns the indication whether data can be inserted into the model.
   *
   * This method CAN be overridden
   *
   * @returns {boolean}
   */
  canInsert() {
    return true;
  }

  /**
   * Returns the indication whether the model can be updated.
   *
   * This method CAN be overridden
   *
   * @returns {boolean}
   */
  canUpdate() {
    return true;
  }

  /**
   * Returns the indication whether the model can be deleted.
   *
   * This method CAN be overridden
   *
   * @returns {boolean}
   */
  canDelete() {
    return true;
  }

  /**
   * Returns the base path for the model.
   *
   * @returns {string}
   */
  getPath() {
    return `/model/${this.getModelName()}`;
  }

  /**
   * Returns the tabs used on the model detail page.
   *
   * This method MUST be overridden.
   *
   * @returns {Array<{
   *   name: string,
   *   slug: string,
   *   description: string,
   *   shouldDisplay: (single: DataStorage) => boolean,
   *   className: string | undefined
   * }>}
   */
  getTabs() {
    throw new Error('Not implemented');
  }

  /**
   * Returns tab name based on the slug.
   *
   * @param {string} slug
   * @returns {string}
   */
  getTabName(slug) {
    return this.getTabs().filter((tab) => tab.slug === slug)[0].name;
  }

  /**
   * Returns tab description based on the slug.
   *
   * @param {string} slug
   * @returns {string}
   */
  getTabDescription(slug) {
    return this.getTabs().filter((tab) => tab.slug === slug)[0].description;
  }

  /**
   * Returns the connections associated with given model.
   *
   * This method MUST be overridden.
   *
   * @returns {Array<{
   *   name: string,
   *   model: string,
   * }>}
   */
  getConnections() {
    throw new Error('Not implemented');
  }

  /**
   * Returns connection model based on the connection name.
   *
   * @param {string} connectionName
   * @returns {string}
   */
  getConnectionModel(connectionName) {
    return this.getConnections().filter((connection) => connection.name === connectionName)[0]
      .model;
  }

  /**
   * Returns the filters of the model.
   *
   * This method SHOULD be overridden.
   *
   * @returns {Array<{
   *   filter: string,
   *   label: string,
   *   type: string,
   *   description: string,
   *   props: Object
   * }>}
   */
  getFilters() {
    return [];
  }

  /**
   * Returns the default aggregates of the model.
   *
   * This method SHOULD be overridden.
   *
   * @returns  {Array<{
   *   aggregateFunction: string,
   *   aggregatedColumn: string,
   * }>}
   */
  getDefaultAggregates() {
    return [];
  }

  /**
   * Returns true if the `dataStorage` was created by this factory.
   *
   * @param {DataStorage} dataStorage
   */
  isFactoryFor(dataStorage) {
    return dataStorage._factory.getModelName() === this.getModelName();
  }

  /**
   * Initiates the record turning it into an instance of DataStorage.
   *
   * @param {Object} record
   *
   * @returns {StorageType}
   */
  _init(record) {
    return new this.storagePrototype.constructor(record, this);
  }

  /**
   * Initiates multiple records turning it into an instance of DataStorage.
   *
   * @param {Array<Object>}
   *
   * @returns {Array<StorageType>}
   */
  _initMultiple(records) {
    return records.map((record) => this._init(record));
  }

  /**
   * Fetches all records.
   *
   * @param {Object.<string, string|number>} filters
   *
   * @returns {Promise<Array<StorageType>>}
   */
  async fetch(filters = {}) {
    const params = {};
    Object.entries(filters).forEach(([key, value]) => {
      params[`filters[${key}]`] = value;
    });

    const response = await connector.get(`/crud/${this.getModelName()}`, {
      params,
    });

    return this._initMultiple(response);
  }

  /**
   * Returns an array of key-value pairs for all records of the type. The key
   * MUST be the primary key. The value can be any reasonable string
   * representing the object.
   *
   * This is useful for example for html <select> input - the keys uniquely
   * identify the records and values represent human-readable identifiers.
   *
   * @param {Object} filters
   *
   * @returns {Promise<Array<[string|number|Array<string>|Array<number>,string]>>}
   */
  async keyValuePairs(filters = {}) {
    const data = await this.fetch(filters);
    return data.map((storage) => [storage.pk(), storage.pretty()]);
  }

  /**
   * Fetches multiple records specified by their primary keys.
   *
   * @param {Array<number|string>} pks
   *
   * @returns {Promise<Array<StorageType>>}
   */
  async pkMultiple(pks) {
    return await this.fetch({ 'pk.in': uniq(pks).join(',') });
  }

  /**
   * Fetches multiple records specified by their primary keys. Returns a map
   * where keys are the primary keys and values are the fetched storages.
   *
   * @param {Array<number|string>} pks
   *
   * @returns {Promise<Object<string, StorageType>>}
   */
  async pkMultipleMap(pks) {
    const response = await this.fetch({ 'pk.in': uniq(pks).join(',') });

    const map = {};
    response.forEach((storage) => (map[storage.pk()] = storage));

    return map;
  }

  /**
   * Fetches a single record specified by primary key.
   *
   * @type {(pk: number|string) => Promise<StorageType>}
   */
  pk = delayedParameterCollector({
    cumulate: thisBinder(this.pkMultipleMap, this),
    distribute: (map, pk) => map[pk],
    delay: 50,
  });

  /**
   * Saves the specified data storage to db.
   *
   * @param {DataStorage} dataStorage
   */
  async save(dataStorage) {
    let response;

    if (dataStorage.isNew) {
      response = await connector.post(`/crud/${this.getModelName()}/new`, dataStorage._props);
    } else {
      response = await connector.put(
        `/crud/${this.getModelName()}/${dataStorage.pk()}/update`,
        dataStorage._props
      );
    }

    Object.assign(dataStorage._props, response);
    this.signalChange();
  }

  /**
   * Deletes the specified data storage from db.
   *
   * @param {DataStorage | number | string} dataStorageOrPk
   */
  async delete(dataStorageOrPk) {
    if (dataStorageOrPk instanceof DataStorage) {
      dataStorageOrPk = dataStorageOrPk.pk();
    }

    await connector.post(`/crud/${this.getModelName()}/${dataStorageOrPk}/delete`);

    this.signalChange();
  }

  /**
   * Creates a new blank instance of StorageType.
   *
   * @param {PropertiesType} params
   *
   * @returns {StorageType}
   */
  blank(params = {}) {
    return this._init(params);
  }

  /**
   * Returns an empty instance of StorageType with the specified primary key.
   * Such object can be used to access the methods of the StorageType instance
   * which require only the primary key.
   *
   * Object created by this function should NEVER be saved.
   *
   * @param {string|number} pk
   *
   * @returns {StorageType}
   */
  pkMock(pk) {
    return Object.freeze(this.blank({ [this.getPrimaryKey()]: pk }));
  }

  /**
   * Signals that the data managed by this factory has changed.
   */
  signalChange() {
    this.lastChange = Date.now();
    window.dispatchEvent(new CustomEvent('dataStorageFactory.signalChange'));
  }

  /**
   * Extends the storage prototype with getters and setters.
   */
  _extendStoragePrototype() {
    this.properties.forEach(({ prop }) =>
      Object.defineProperty(this.storagePrototype, prop, {
        enumerable: true,
        configurable: false,
        get() {
          return this._props[prop];
        },
        set(value) {
          this._props[prop] = value;
        },
      })
    );
  }

  /**
   * Creates a new instance of DataStorageFactory.
   *
   * @template {DataStorageProperties} PropertiesType
   *
   * @param {PropertiesType} properties
   * @param {StorageType} storagePrototype
   */
  constructor(properties, storagePrototype) {
    /**
     * The timestamp of the last data modification.
     *
     * @type {number}
     */
    this.lastChange = null;

    /**
     * The descriptors of the properties.
     */
    this.properties = properties;

    /**
     * The prototype of the StorageType.
     *
     * @type {StorageType}
     */
    this.storagePrototype = storagePrototype;

    this._extendStoragePrototype();
  }
}

export default DataStorageFactory;
