import DataStorageProperties from '../abstract/data-storage-properties';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';
import text from '../abstract/properties/property-text';

const emailTemplateProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  slug: string({
    maxLength: 255,
    titleKey: true,
  }),
  subject: string({
    maxLength: 255,
  }),
  // TODO - for future, move the bodies to separate tabs with dedicated editor
  textBody: text({ table: { hidden: true } }),
  htmlBody: text({ table: { hidden: true } }),
});

export default emailTemplateProperties;
