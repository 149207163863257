<template>
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.67529 13L6.93503 7.74026L10.2597 11.0649L18.3246 3"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5584 3H18.3246V8.76624"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.08002 1.68994H6.76002"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.08002 4.51001H4.42002"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'user-statistics-two-icon',
};
</script>
