<template>
  <div>
    <card title="Request statistics" description="Holds request statistics">
      <div class="showtime-data-table">
        <div class="left">
          <div class="d-flex" style="flex-wrap: wrap; flex-direction: row; justify-content: left">
            <span v-if="loading">Loading ...</span>
            <span v-else-if="errors"> {{ errors }} </span>
            <data-stats
              v-else
              :model="MODEL_NAME_REQUEST"
              title="Počet žiadostí"
              aggregatedProperty="id"
              aggregateFunction="Count"
              :filters="{ 'completed.range': range }"
              suffix="ks"
              :precision="0"
              :reloadToken="reloadToken"
              statsIcon="feather-check-circle"
            />
            <data-stats
              :model="MODEL_NAME_REQUEST"
              title="Vybavené žiadosti"
              aggregatedProperty="id"
              aggregateFunction="Count"
              :filters="{ 'requestStatusId.eq': 2, 'completed.range': range }"
              suffix="ks"
              :precision="0"
              :reloadToken="reloadToken"
              statsIcon="feather-activity"
            />
            <data-stats
              :model="MODEL_NAME_REQUEST"
              title="Neúspešné žiadosti"
              aggregatedProperty="id"
              aggregateFunction="Count"
              :filters="{ 'requestStatusId.in': [3, 4], 'completed.range': range }"
              suffix="ks"
              :precision="0"
              :reloadToken="reloadToken"
              statsIcon="feather-alert-triangle"
            />
            <data-stats
              :model="MODEL_NAME_REQUEST"
              title="Čakajúce žiadosti"
              aggregatedProperty="id"
              aggregateFunction="Count"
              :filters="{ 'requestStatusId.in': [0, 1], 'completed.range': range }"
              suffix="pcs"
              :precision="0"
              :reloadToken="reloadToken"
              statsIcon="feather-loader"
            />
            <data-stats
              :model="MODEL_NAME_REQUEST"
              title="Úspešnosť vybavenia žiadostí"
              aggregatedProperty="id"
              aggregateFunction="Count"
              :filters="{ 'requestStatusId.eq': 2, 'completed.range': range }"
              suffix="%"
              :transformFn="(countId) => Math.round((countId / totalRequests) * 100)"
              :reloadToken="reloadToken"
              statsIcon="feather-arrow-up"
            />
          </div>
        </div>
        <aside class="right showtime-data-table-filters">
          <header class="table-filter-header">
            <h5>Filters</h5>
          </header>
          <div class="table-filter-row">
            <label class="table-filter-label">
              Filter the statistics by date range <br />
              <small class="text-muted"> Display the statistics in given date range </small>
            </label>
            <date-range-filter @input="saveDateRange" :clear="clearFiltersNanoid" />
          </div>

          <div class="spacer"></div>
          <footer class="table-filter-footer">
            <button @click="clearFilters" class="btn btn-sm btn-light">Clear filters</button>
          </footer>
        </aside>
      </div>
      <div class="showtime-data-table mt-5">
        <div class="left">
          <span v-if="loading">Loading ...</span>
          <span v-else-if="errors"> {{ errors }} </span>
          <bar-chart
            v-else
            :datasets="[
              { label: 'Prírastok nových žiadostí', backgroundColor: randomColor, data: chart },
            ]"
            :maxValue="maxValue"
            :labels="labels"
          />
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { MODEL_NAME_REQUEST, MODEL_NAME_REQUEST_DATE_RANGE_COUNT } from '../../constants';
import { requestFactory } from '../../model';
import BarChart from '../../components/charts/BarChart.vue';
import DateRangeFilter from '../../components/data/data-filters/DateRangeFilter.vue';
import { nanoid } from 'nanoid/non-secure';
import getFactory from '../../model';
import { randomColor as rc } from '../../utils/random-color';

export default {
  name: 'page-request-stats',
  components: {
    BarChart,
    DateRangeFilter,
  },
  data() {
    return {
      loading: false,
      errors: null,
      MODEL_NAME_REQUEST,
      totalRequests: null,
      range: null,
      reloadToken: null,
      clearFiltersNanoid: null,
      requestCount: null,
      model: MODEL_NAME_REQUEST_DATE_RANGE_COUNT,
      chart: [],
      labels: [],
    };
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        this.errors = null;
        this.chart = [];
        this.labels = [];

        this.totalRequests = (await requestFactory.fetch()).length;

        this.requestCount = await this.factory.fetch({ 'requestPaid.range': this.range });

        // sort the entries by paid prop, the earliest first
        this.requestCount.sort(
          (a, b) => new Date(a.requestPaid).getTime() - new Date(b.requestPaid).getTime()
        );

        this.requestCount.forEach((item) => {
          this.chart.push(item.requestCount);
          this.labels.push(item.requestPaid.split('T')[0]);
        });
      } catch (e) {
        this.errors = e;
      } finally {
        this.loading = false;
      }
    },
    saveDateRange(dateRange) {
      this.range = dateRange;
      this.reloadToken = nanoid();
      this.fetchData();
    },
    clearFilters() {
      this.clearFiltersNanoid = nanoid();
      this.reloadToken = nanoid();
    },
  },
  computed: {
    randomColor() {
      return rc();
    },
    factory() {
      return getFactory(this.model);
    },
    maxValue() {
      return this.chart.length > 0 ? Math.max(...this.chart) + 2 : 10;
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>

<style></style>
