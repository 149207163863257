import DataStorageProperties from '../abstract/data-storage-properties';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';
import text from '../abstract/properties/property-text';

const charityProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  name: string({
    titleKey: true,
    maxLength: 50,
    searchable: true,
  }),
  logoUrl: string({
    maxLength: 255,
    table: { hidden: true },
  }),
  webUrl: string({
    maxLength: 255,
    defaultValue: null,
    required: false,
    table: { hidden: true },
  }),
  description: text({
    table: { hidden: true },
    required: false,
  }),
});

export default charityProperties;
