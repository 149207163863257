var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{attrs:{"model":_vm.MODEL_NAME_REQUEST,"hideNewButton":"","hideEditButton":"","hideDeleteButton":"","hideFilters":"","hideAggregates":"","filters":{ 'pk.in': _vm.single.pk() },"overrideColumns":[
    'amount',
    'amountUser',
    'stripePiId',
    'stripePiSecret',
    'modExpress',
    'modNoWatermark',
    'paid',
    'requestInvoiceId',
    'invoiceUrl' ]}})}
var staticRenderFns = []

export { render, staticRenderFns }