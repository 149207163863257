import merge from 'lodash/merge';
import memoize from 'lodash/memoize';
import DataStoragePropertyConstructorParams from './data-storage-property-constructor-params';

/**
 * The base class for every property type.
 *
 * Property types are defined in `src/model/abstract/properties`.
 */
class DataStorageProperty {
  /**
   * Returns the name of the type.
   *
   * This method MUST be overridden.
   *
   * @returns {string}
   */
  propertyType() {
    throw new Error('Not implemented');
  }

  /**
   * Returns the name of the column type which should be used in data-table.
   *
   * This method MUST be overridden.
   *
   * @returns {string}
   */
  tableColumnType() {
    throw new Error('Not implemented');
  }

  /**
   * Returns the name of the component which should be used for the type.
   *
   * This method MUST be overridden.
   *
   * @returns {string}
   */
  formComponentName() {
    throw new Error('Not implemented');
  }

  /**
   * Compares two property values.
   *
   * This method SHOULD be overridden.
   *
   * @param {*} a
   * @param {*} b
   */
  areEqual(a, b) {
    return a == b;
  }

  /**
   * Returns the property descriptor used by data-table.
   */
  _tableDescriptorImpl() {
    console.debug('DataStorageProperty._tableDescriptorImpl');

    const descriptor = {
      field: this.prop,
      label: this.pretty,
      order: this.titleKey ? 1 : 2,
      hidden: this.table.hidden,
      type: this.tableColumnType(),
      html: this.table.html,
      width: this.table.width,
      meta: {
        references: this.references,
        primaryKey: this.primaryKey,
        canAggregate: this.canAggregate,
      },
    };

    descriptor.formatFn = (value) => {
      if (value === null) {
        // NOTE NULL or '-'?
        return '-';
      }

      return this.table.formatFn(value);
    };

    return descriptor;
  }

  tableDescriptor = memoize(this._tableDescriptorImpl);

  /**
   * Returns the form descriptor used by data-form
   */
  _formDescriptorImpl() {
    console.debug('DataStorageProperty._formDescriptorImpl');

    const descriptor = {
      prop: this.prop,
      label: this.pretty,
      required: this.required,
      readonly: this.readonly,
      ignore: this.form.ignore,
      ignoreWhenNew: this.form.ignoreWhenNew,
      ignoreWhenUpdate: this.form.ignoreWhenUpdate,
      disableWhenUpdate: this.form.disableWhenUpdate,
      fieldHint: this.fieldHint,
      type: this.formComponentName(),
      componentProps: {},
    };

    if (this.references) {
      descriptor.type = 'data-select';
      descriptor.componentProps.model = this.references;
    }

    return descriptor;
  }

  formDescriptor = memoize(this._formDescriptorImpl);

  /**
   * Creates a new instance of DataStorageProperty
   *
   * @param {DataStoragePropertyConstructorParams} params
   */
  constructor(params = {}) {
    params = merge(new DataStoragePropertyConstructorParams(), params);

    /**
     * The property key in DataStorage.
     *
     * Will be assigned in `DataStorageProperties.create()`.
     *
     * @type {string}
     */
    this.prop = undefined;

    this.primaryKey = params.primaryKey;
    this.titleKey = params.titleKey;
    this.pretty = params.pretty;
    this.defaultValue = params.defaultValue;
    this.required = params.required;
    this.readonly = params.readonly;
    this.references = params.references;
    this.searchable = params.searchable;
    this.table = params.table;
    this.form = params.form;
    this.canAggregate = params.canAggregate;
    this.fieldHint = params.fieldHint;
  }
}

export default DataStorageProperty;
