import { MODEL_NAME_USER_SUM_BALANCE } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import UserSumBalance from './user-sum-balance-class';
import userSumBalanceProperties from './user-sum-balance-properties';

/** @extends {DataStorageFactory<UserSumBalance>} */
class UserSumBalanceFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_USER_SUM_BALANCE;

  getSingular = () => 'User Sum Balance';
  getPlural = () => 'user Sum Balance';

  getIcon = () => 'feather-tag';

  getTabs = () => [];

  getFilters = () => [];

  canDelete = () => false;
  canInsert = () => false;
  canUpdate = () => false;
}

const userSumBalanceFactory = new UserSumBalanceFactory(
  userSumBalanceProperties,
  UserSumBalance.prototype
);

export default userSumBalanceFactory;
