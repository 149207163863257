import { MODEL_NAME_OCCASION } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import Occasion from './occasion-class';
import occasionProperties from './occasion-properties';

/** @extends {DataStorageFactory<Occasion>} */
class OccasionFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_OCCASION;

  getSingular = () => 'Occasion';
  getPlural = () => 'occasions';

  getIcon = () => 'feather-calendar';

  getTabs = () => [];
}

const occasionFactory = new OccasionFactory(occasionProperties, Occasion.prototype);

export default occasionFactory;
