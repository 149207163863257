import DataStorageProperties from '../abstract/data-storage-properties';
import bool from '../abstract/properties/property-bool';
import url from '../abstract/properties/property-url';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';

const tagProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  name: string({
    titleKey: true,
    maxLength: 50,
    searchable: true,
  }),
  slug: string({
    maxLength: 50,
    searchable: true,
  }),
  isCategory: bool(),
  icon: url({
    required: false,
    table: { hidden: true },
  }),
});

export default tagProperties;
