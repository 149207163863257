<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { connectorState } from './api/connector';
import session from './api/session.js';

export default {
  name: 'App',
  data() {
    return {
      connectorState,
      session,
    };
  },
  methods: {
    loginRoute() {
      if (this.$route.fullPath.startsWith('/reset-password/')) {
        return; // Do nothing
      }

      if (!this.session.jwt && this.$route.fullPath !== '/login') {
        this.$router.push('/login');
      }

      if (this.session.jwt && this.$route.fullPath === '/login') {
        this.$router.push('/');
      }
    },
  },
  watch: {
    'session.jwt': 'loginRoute',
    'connectorState.lastRequest': 'loginRoute',
  },
  created() {
    console.debug('App.created');
    document.body.classList.add('sidebar-dark');
  },
  mounted() {
    console.debug('App.mounted');
  },
};
</script>
<style lang="scss">
// todo: move this to scss files
$spacing-gap: 15px;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  10: $spacer * 15,
) !default;

body {
  margin: 0;
  min-height: 100vh;
  color: #8f95a3;
}

#app {
  code {
    padding: 0;
  }

  .table > :not(caption) > * > * {
    background-color: transparent;
    box-shadow: none;
  }

  h1 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #ffffff;
  }

  h2 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
  }
}

.model-description {
  font-size: 14px;
  line-height: 24px;
  color: #8f95a3;
  font-weight: 300;
}

// inputs
input,
textarea,
select {
  // default
  background-color: #191b1f !important;
  color: #fff !important;
  border-color: transparent !important;
  border-radius: 4px !important;
  padding: 8px 12px !important;
  height: 40px !important;

  &::placeholder {
    font-size: 14px !important;
    line-height: 24px !important;
    color: #5c6270 !important;
  }

  // disabled
  &:disabled {
    color: #8f95a3 !important;
    cursor: not-allowed !important;
    &::placeholder {
      color: #8f95a3 !important;
    }
  }

  // hover
  &:hover {
    border-color: #343842 !important;
  }

  // active
  &:focus {
    color: #fff !important;
    background-color: #121417 !important;
    border-color: #304dff !important;
    border-width: 1px !important;
  }
}

textarea {
  height: 150px !important;
}

label {
  color: #8f95a3 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  margin: 0px !important;
  font-weight: 400 !important;
  margin-bottom: 8px !important;
}

// vue-select
.data-select-area {
  .v-select {
    // default
    background-color: #191b1f;
    border-radius: 4px;
    border: 1px solid transparent;
    color: #ffffff;

    input {
      height: 32px !important;

      &:focus,
      &:hover {
        border-color: transparent !important;
      }

      &::placeholder {
        color: #ffffff !important;
      }
    }

    .vs__dropdown-toggle {
      background-color: #191b1f;

      &:focus-within {
        color: #fff;
        background-color: #121417;
        border-color: #304dff;
        border-width: 1px;
      }

      border-color: transparent;
    }

    .vs__actions {
      svg {
        fill: #8f95a3;
      }
    }

    // hover
    &:hover {
      border-color: #343842;
      border: 1px solid #343842;
      background: #121417;
      input:hover {
        border-color: transparent !important;
      }
    }

    // disabled
    &:disabled {
      background-color: #191b1f;
      border: 1px solid #191b1f;
      color: #8f95a3;
      cursor: not-allowed;
    }
  }

  // active
  .vs__selected {
    // TODO add here the blue/purple border
    color: #ffffff !important;
  }

  .vs--open .vs__dropdown-menu {
    background: #121417;
    border: 1px solid #343842;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.32);
    margin-top: 8px;
    border-radius: 4px;
    padding: 8px;
    li {
      border-radius: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #8f95a3 !important;
      padding-left: 12px !important;
      padding-right: 12px;
      padding-top: 4px;
      padding-bottom: 4px;
      &:hover {
        // TODO the hover is sometimes of different color of blue
        background-color: #304dff;
        color: #ffffff !important;
      }
    }
  }
}

// primary button
.btn.btn-primary {
  background-color: #304dff;
  border-color: #304dff;
  color: #ffffff;
  height: 40px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 4px;

  &:hover {
    background-color: #4d66ff;
    border-color: #4d66ff;
  }
  &:disabled {
    border-color: #131a43;
    background-color: #131a43;
    color: #454a68 !important;

    svg {
      path {
        stroke: #454a68 !important;
      }
    }
  }
  svg {
    stroke: #ffffff !important;
    path {
      stroke: #ffffff !important;
    }
  }
  svg.all-requests-icon {
    path {
      fill: #ffffff !important;
    }
  }
}

// secondary button
.btn.btn-secondary {
  background-color: #22252b;
  border-color: #22252b;
  color: #8f95a3;
  height: 40px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 4px;

  &:hover {
    background-color: #2b2f36;
    border-color: #2b2f36;
    color: #ffffff;
    svg path {
      stroke: #ffffff !important;
    }
  }

  &:disabled {
    background-color: #121417;
    border-color: #121417;
    color: #272a30;

    svg path {
      stroke: #272a30 !important;
    }
  }

  svg {
    stroke: #8f95a3 !important;
    path {
      stroke: #8f95a3 !important;
    }
  }
}

// delete button
.btn.btn-danger {
  background-color: #310f18;
  border-color: #310f18;
  color: #ff3333;
  height: 40px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 4px;

  &:hover {
    background-color: #3e131f;
    border-color: #3e131f;
    color: #ff5252;
    svg path {
      stroke: #ff5252 !important;
    }
  }

  svg {
    stroke: #ff3333 !important;
    path {
      stroke: #ff3333 !important;
    }
  }
}

.sidebar {
  text-transform: capitalize;
  width: 250px !important;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #121417 !important;

  .sidebar-header {
    background-color: #121417 !important;
    border: 1px solid #0b0c0e !important;

    .sidebar-brand {
      display: flex;
      justify-items: center;
      align-items: center;
      width: 141px !important;
      height: 24px !important;
    }
  }

  .sidebar-body.ps {
    background-color: #121417 !important;
    box-shadow: none;
    width: 250px !important;
    ul.nav {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 0px;
    }

    .nav-item {
      padding-left: 10px;
      width: 200px;
      margin-right: 10px;
      .nav-link {
        height: 48px !important;
        padding-right: 10px;
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        white-space: normal !important;
        color: #8f95a3 !important;
        font-size: 14px;
        line-height: 24px;
        svg {
          stroke: #8f95a3 !important;
          path {
            stroke: #8f95a3 !important;
          }
        }
        svg.all-requests-icon {
          path {
            fill: #8f95a3 !important;
          }
        }
      }
    }

    .nav-item.active {
      background-color: #22252b;
      border-radius: 4px;
      color: #ffffff !important;

      .nav-link {
        svg {
          stroke: #ffffff !important;
          path {
            stroke: #ffffff !important;
          }
        }
        svg.all-requests-icon {
          path {
            fill: #ffffff !important;
          }
        }
        .link-title {
          color: #ffffff !important;
        }
      }
    }

    .nav-item:hover {
      background-color: #2b2f36;
      border-radius: 4px;
      .nav-link {
        svg {
          stroke: #ffffff !important;
          path {
            stroke: #ffffff !important;
          }
        }
        svg.all-requests-icon {
          path {
            fill: #ffffff !important;
          }
        }
        .link-title {
          color: #ffffff !important;
        }
      }
    }

    .nav-item.nav-category {
      color: #ffffff !important;
      font-size: 12px;
      line-height: 18px;
      height: 26px !important;
      display: flex;
      align-items: center;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.property-date-formatted {
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 300;
  span.font-normal {
    font-weight: 400;
  }
}

.property-bool-formatted {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  .true {
    color: #00d053;
  }
  .false {
    color: #ff3333;
  }
}

.custom-view-content {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-top: 24px;
  padding-bottom: 28px;
  .icon {
    height: 34px;
    width: 34px;
    margin-left: 7px;
    margin-right: 8px;
    svg {
      height: 100%;
      width: 100%;
      stroke: #ffffff !important;
      path {
        stroke: #ffffff !important;
      }
    }
  }
}

.creanet-text {
  color: #8f95a3;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-left: auto;
  padding-bottom: 8px;

  a {
    color: #8f95a3;
    &:hover {
      color: #8f95a3;
    }
  }
}

.showtimes-admin-logo {
  display: block;
  max-width: 186px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 29px;

  img {
    width: 100%;
  }
}

#profileDropdownToggle {
  height: 40px;
  width: 40px;

  img {
    height: 100%;
    width: 100%;
  }
}

.showtimes-login-form {
  background-color: #121417;
  border-radius: 4px;
  padding: 48px 24px;

  .login-button {
    width: 100%;
  }
}

.card {
  padding: 24px;
  border: none !important;
  background-color: #121417 !important;
  box-shadow: none !important;
  border-radius: 4px;
}

// prettier-ignore
@each $key, $value in $spacers {
  .ml-#{$key} { margin-left: $value !important; }
  .mr-#{$key} { margin-right: $value !important; }
  .mt-#{$key} { margin-top: $value !important; }
  .mb-#{$key} { margin-bottom: $value !important; }

  .pl-#{$key} { padding-left: $value !important; }
  .pr-#{$key} { padding-right: $value !important; }
  .pt-#{$key} { padding-top: $value !important; }
  .pb-#{$key} { padding-bottom: $value !important; }

  .ml--#{$key} { margin-left: -$value !important; }
  .mr--#{$key} { margin-right: -$value !important; }
  .mt--#{$key} { margin-top: -$value !important; }
  .mb--#{$key} { margin-bottom: -$value !important; }

  .pl--#{$key} { padding-left: -$value !important; }
  .pr--#{$key} { padding-right: -$value !important; }
  .pt--#{$key} { padding-top: -$value !important; }
  .pb--#{$key} { padding-bottom: -$value !important; }
}

.card .card-body {
  background-color: #121417;
}

.card.short {
  max-width: 1100px;
}

.card-nav {
  display: flex;
  align-items: stretch;

  a {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }

  a.router-link-active,
  a.router-link-active:hover,
  a.router-link-active:active {
    background-color: #727cf5;
    border-color: #727cf5;
    color: white;
  }

  .spacer {
    width: 10px;
  }
}

.badge-danger {
  background-color: #dc3545;
}

.badge-pill {
  width: 2em;
  height: 2em;
  border-radius: 50% !important;
}

.main-wrapper .page-wrapper {
  background-color: #0b0c0e !important;
}

.main-wrapper .page-wrapper .page-content {
  padding: $spacing-gap !important;
  background-color: #0b0c0e;
  padding-bottom: 48px !important;
  padding-right: 80px !important;
  padding-left: 80px !important;
  padding-top: 24px !important;
}
</style>
