import { MODEL_NAME_USER_PRODUCT } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import UserProduct from './user-product-class';
import userProductProperties from './user-product-properties';

/** @extends {DataStorageFactory<UserProduct>} */
class UserProductFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_USER_PRODUCT;

  getSingular = () => 'User Product';
  getPlural = () => 'user Products';

  getIcon = () => 'feather-user';

  getTabs = () => [];

  getFilters = () => [];
}

const userProductFactory = new UserProductFactory(userProductProperties, UserProduct.prototype);

export default userProductFactory;
