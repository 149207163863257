<template>
  <div>
    <data-table
      :model="remoteModelName"
      hideNewButton
      hideEditButton
      hideFilters
      :filters="{ [filterName]: single.pk() }"
      :deleteButtonLabel="`Delete ${remoteFactory.getSingular()}`"
      :onDelete="deleteConnection"
      class="connection-form-table"
    />
    <p v-if="error">{{ error }}</p>
    <div v-else class="add-connection">
      <label>Add {{ remoteFactory.getSingular() }} </label>
      <div class="inner">
        <data-select
          :model="remoteModelName"
          v-model="selected"
          :placeholder="`Select a ${remoteFactory.getSingular()}`"
          class="select-box"
        />
        <button
          class="btn btn-sm btn-primary text-white add-connection-button"
          :disabled="selected === null || loading"
          @click="addConnection"
        >
          <icon icon="check-icon" />
          Add {{ remoteFactory.getSingular() }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import connector from '../../api/connector';
import DataStorage from '../../model/abstract/data-storage';
import getFactory from '../../model';

export default {
  name: 'connection-form',
  data() {
    return {
      selected: null,
      loading: false,
      error: null,
    };
  },
  props: {
    single: {
      type: DataStorage,
      required: true,
    },
    connection: {
      type: String,
      required: true,
    },
    filterName: {
      type: String,
      required: true,
    },
  },
  // created() {
  //   console.log('single', this.single);
  //   console.log('connection', this.connection);
  //   console.log('filterName', this.filterName);
  //   console.log('localFactory', this.localFactory);
  //   console.log('localModelName', this.localModelName);
  //   console.log('remoteModelName', this.remoteModelName);
  //   console.log('remoteFactory', this.remoteFactory);
  // },
  computed: {
    localFactory() {
      return this.single._factory;
    },
    localModelName() {
      return this.localFactory.getModelName();
    },
    remoteModelName() {
      return this.localFactory.getConnectionModel(this.connection);
    },
    remoteFactory() {
      return getFactory(this.remoteModelName);
    },
  },
  methods: {
    async addConnection() {
      try {
        this.loading = true;
        this.error = null;

        await connector.post(
          `/crud/${this.localModelName}/${this.single.pk()}/connection/${this.connection}/add/${
            this.selected
          }`
        );

        this.remoteFactory.signalChange();
        this.selected = null;
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    async deleteConnection(pk) {
      try {
        this.loading = true;
        this.error = null;

        const resp = await this.$modal.confirm(
          `Do you really want to delete this ${this.remoteFactory.getSingular()}?`
        );

        if (resp) {
          await connector.post(
            `/crud/${this.localModelName}/${this.single.pk()}/connection/${
              this.connection
            }/delete/${pk}`
          );

          this.remoteFactory.signalChange();
          this.selected = null;
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.connection-form-table {
  border-radius: 4px;
  border: 1px solid #0b0c0e;
  background: #121417;
  padding: 12px 16px;
}
.add-connection {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #0b0c0e;
  background: #121417;
  padding: 24px 28px;

  label {
    margin-bottom: 8px;
  }

  .inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 28px;

    .select-box {
      width: 100%;
    }

    .add-connection-button {
      flex-shrink: 0;
      flex-grow: 0;
      width: 164px;
    }
  }
}
</style>
