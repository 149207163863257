<template>
  <span :class="['data-single-display', model]" :data-pk="pk">
    <span v-if="loading">{{ loadingText }}</span>
    <span v-else-if="error">{{ error }}</span>
    <template v-else-if="storage"
      >{{ prefix
      }}<router-link :to="`${factory.getPath()}/${pk}`">
        <!-- icons for request statuses -->
        <svg
          v-if="model === 'request-status' && pk === 0"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_270_9630)">
            <path
              d="M14.1067 12.0133V3.98664C14.1067 3.10667 13.3933 2.39331 12.5134 2.39331L4.48669 2.39331C3.60672 2.39331 2.89336 3.10667 2.89336 3.98664V12.0133C2.89336 12.8933 3.60672 13.6066 4.48669 13.6066H12.5134C13.3933 13.6066 14.1067 12.8933 14.1067 12.0133Z"
              stroke="#00D053"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.47998 7.99998L8.25331 9.77331L14.0466 3.97998"
              stroke="#00D053"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_270_9630">
              <rect width="16" height="16" fill="white" transform="translate(0.5)" />
            </clipPath>
          </defs>
        </svg>

        <svg
          v-if="model === 'request-status' && pk === 1"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_43_427)">
            <path
              d="M8.00004 13.7533C11.1775 13.7533 13.7534 11.1775 13.7534 7.99998C13.7534 4.8225 11.1775 2.24664 8.00004 2.24664C4.82256 2.24664 2.2467 4.8225 2.2467 7.99998C2.2467 11.1775 4.82256 13.7533 8.00004 13.7533Z"
              stroke="#FFAD33"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.5733 7.99999H8V4.15332"
              stroke="#FFAD33"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_43_427">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <svg
          v-if="model === 'request-status' && pk === 2"
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <g clip-path="url(#clip0_43_438)">
            <path
              d="M4.05334 8.50664L6.68001 11.1333L12.9467 4.86664"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_43_438">
              <rect width="16" height="16" fill="white" transform="translate(0.5)" />
            </clipPath>
          </defs>
        </svg>

        <svg
          v-if="model === 'request-status' && pk === 3"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_43_519)">
            <path
              d="M4.66663 11.3335L11.3334 4.66669"
              stroke="#FF3333"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.3334 11.3335L4.66663 4.66669"
              stroke="#FF3333"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_43_519">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <svg
          v-if="model === 'request-status' && pk === 4"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_43_524)">
            <path
              d="M5.16663 11.3335L11.8334 4.66669"
              stroke="#FF3333"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.8334 11.3335L5.16663 4.66669"
              stroke="#FF3333"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_43_524">
              <rect width="16" height="16" fill="white" transform="translate(0.5)" />
            </clipPath>
          </defs>
        </svg>

        <svg
          v-if="model === 'request-status' && pk === 5"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_43_537)">
            <path
              d="M8 2.24664C10.9333 2.24664 13.36 4.44664 13.7067 7.28664"
              stroke="#3357FF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.4667 9.79333C13.2067 10.5933 12.7733 11.32 12.2067 11.92C11.7267 12.44 11.1467 12.8667 10.5 13.18"
              stroke="#3357FF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.44004 2.84662C3.5467 3.78662 2.2467 5.73995 2.2467 7.99995"
              stroke="#3357FF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2.66003 10.14C2.9267 10.8 3.31337 11.4066 3.79337 11.92C4.27337 12.4333 4.85337 12.8666 5.50003 13.18C6.25337 13.5466 7.1067 13.7533 8.00003 13.7533"
              stroke="#3357FF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_43_537">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <span class="link-text">
          {{ storage.pretty() }}
        </span> </router-link
      >{{ suffix }}</template
    >
    <span v-else>-</span>
  </span>
</template>

<script>
import getFactory from '../../model';

export default {
  name: 'data-single-display',
  props: {
    model: {
      type: String,
      required: true,
    },
    pk: {
      type: [Number, String],
      default: null,
    },
    loadingText: {
      type: String,
      default: 'Loading ...',
    },
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      storage: null,
      loading: true,
      error: null,
    };
  },
  computed: {
    factory() {
      return getFactory(this.model);
    },
  },
  methods: {
    async getStorageByPk() {
      try {
        this.loading = true;
        this.storage = null;
        this.error = null;

        if (this.pk !== null) {
          this.storage = await this.factory.pk(this.pk);
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.getStorageByPk();
  },
  watch: {
    model: 'getStorageByPk',
    pk: 'getStorageByPk',
  },
};
</script>

<style lang="scss">
.data-single-display {
  a {
    color: #b3bdff;
    font-size: 14px;
    line-height: 24px;
    text-decoration: underline;
    &:hover {
      color: #ffffff;
    }
  }
  &.request-status {
    display: flex;
    width: 150px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 8px;
      padding-bottom: 8px;
      gap: 4px;
      width: 100%;
      text-decoration: none;
      span.link-text {
        display: flex;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px !important;
        height: 16px;
      }
    }

    &[data-pk='0'] {
      background-color: #0a351e;
      span.link-text {
        color: #00d053;
      }
    }

    // waiting request
    &[data-pk='1'] {
      background-color: #31291d;
      span.link-text {
        color: #ffad33;
      }
    }

    // completed
    &[data-pk='2'] {
      background-color: #22252b;
      span.link-text {
        color: #ffffff;
      }
    }

    // canceled
    &[data-pk='3'] {
      background-color: #310f18;
      span.link-text {
        color: #ff3333;
      }
    }

    // expired
    &[data-pk='4'] {
      background-color: #310f18;
      span.link-text {
        color: #ff3333;
      }
    }

    // processing
    &[data-pk='5'] {
      background-color: #181d34;
      span.link-text {
        color: #3357ff;
      }
    }
  }
}
</style>
