<template>
  <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 6.98063C13 3.24438 9.57823 0.30066 5.70359 1.11836C3.38887 1.60898 1.53961 3.50856 1.11189 5.83585C0.721914 7.91155 1.42639 9.83629 2.72213 11.1446C3.66563 12.1007 4.28205 13.3335 4.28205 14.6796V15.9376H9.71661V14.6796C9.71661 13.3335 10.333 12.1007 11.2765 11.132C12.3332 10.0627 12.9874 8.59087 12.9874 6.95547L13 6.98063Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.98022 8.07513L8.35784 5.69751"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.58392 19.0198H9.41464"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'celebrity-suggestion-icon',
};
</script>
