<template>
  <div class="request-stats-cards">
    <div class="request-stats-card request-remaining-time">
      <div class="title">Čas do vybavenia</div>
      <div class="value">{{ remainingTime }}</div>
    </div>
    <!-- TODO temporary hide these buttons -->
    <!-- <button class="btn btn-secondary">
      <icon icon="email-queue-icon" />
      Send Reminder
    </button> -->
    <!-- <button class="btn btn-danger">
      <icon icon="cancel-icon" />
      Delete request
    </button> -->

    {{ pk }}
  </div>
</template>

<script>
import { add } from 'date-fns';
import { requestFactory } from '../../model';

export default {
  name: 'request-stats-card',
  data() {
    return {
      requestFactory,
      interval: 0,
      remainingTime: '00h : 00m : 00s',
      request: null,
    };
  },
  methods: {
    computeRemainingTime() {
      if (this.request === null) {
        this.remainingTime = '00h : 00m : 00s';
        return;
      }

      // the request has been completed
      if (this.request.requestStatusId === 2) {
        this.remainingTime = 'Request has been completed!';
        return;
      }

      // request has been cancelled or expired
      if (this.request.requestStatusId === 4 || this.request.requestStatusId === 3) {
        this.remainingTime = 'Request has been cancelled or expired!';
        return;
      }

      const requestDate = new Date(this.request.created);
      let cutOffDate = add(requestDate, { hours: 24 });
      if (this.request.modExpress) {
        cutOffDate = add(requestDate, { hours: 24 });
      } else {
        cutOffDate = add(requestDate, { days: 7 });
      }

      const diff = (cutOffDate.getTime() - new Date().getTime()) / 1000;

      const originalHours = diff / 3600;
      const hours = Math.floor(originalHours);

      const originalMinutes = Math.floor(diff / 60) % 60;
      const minutes = Math.floor(originalMinutes);

      const originalSeconds = Math.floor(diff % 60);
      const seconds = originalSeconds;

      this.remainingTime = `${hours}h : ${minutes}m : ${seconds}s`;
    },
    async getData() {
      try {
        this.loading = true;
        this.error = false;

        // TODO update the interval when the status of the request is changed manually
        this.request = (await this.requestFactory.fetch({ 'pk.eq': this.pk }))[0];
        this.computeRemainingTime();
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getData();
    this.interval = setInterval(() => this.computeRemainingTime(), 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  props: ['pk'],
};
</script>

<style lang="scss">
.request-stats-cards {
  display: flex;
  flex-direction: row;
  gap: 16px;

  .request-stats-card {
    height: 40px;
  }

  .request-remaining-time {
    padding: 0px 12px;
    .title {
      color: #8f95a3;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
    }

    .value {
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}
</style>
