import { MODEL_NAME_USER_TOTAL_RECORDING_TIME } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import UserTotalRecordingTime from './user-total-recording-time-class';
import userTotalRecordingTimeProperties from './user-total-recording-time-properties';

/** @extends {DataStorageFactory<UserTotalRecordingTime>} */
class UserTotalRecordingTimeFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_USER_TOTAL_RECORDING_TIME;

  getSingular = () => 'User Recording Time';
  getPlural = () => 'user Recording Time';

  getIcon = () => 'feather-tag';

  getTabs = () => [];

  getFilters = () => [];

  canDelete = () => false;
  canInsert = () => false;
  canUpdate = () => false;
}

const userTotalRecordingTimeFactory = new UserTotalRecordingTimeFactory(
  userTotalRecordingTimeProperties,
  UserTotalRecordingTime.prototype
);

export default userTotalRecordingTimeFactory;
