import { MODEL_NAME_VIDEO_STATUS } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import VideoStatus from './video-status-class';
import videoStatusProperties from './video-status-properties';

/** @extends {DataStorageFactory<VideoStatus>} */
class VideoStatusFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_VIDEO_STATUS;

  getSingular = () => 'Video Statuses';
  getPlural = () => 'video Statuses';

  getIcon = () => 'feather-mic';

  getTabs = () => [];
}

const videoStatusFactory = new VideoStatusFactory(videoStatusProperties, VideoStatus.prototype);

export default videoStatusFactory;
