<template>
  <data-table
    :model="MODEL_NAME_REQUEST"
    hideNewButton
    hideEditButton
    hideDeleteButton
    hideFilters
    hideAggregates
    :filters="{ 'pk.in': single.pk() }"
    :overrideColumns="[
      'recipientName',
      'contactFullname',
      'contactEmail',
      'contactPhone',
      'contact',
    ]"
  />
</template>

<script>
import { MODEL_NAME_REQUEST } from '../../constants';

export default {
  name: 'request-contact-tab',
  data() {
    return {
      MODEL_NAME_REQUEST,
    };
  },
  props: ['single'],
};
</script>
