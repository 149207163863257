import { MODEL_NAME_REQUEST_INVOICE } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import RequestInvoice from './request-invoice-class';
import requestInvoiceProperties from './request-invoice-properties';

/** @extends {DataStorageFactory<RequestInvoice>} */
class RequestInvoiceFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_REQUEST_INVOICE;

  getSingular = () => 'Request Invoice';
  getPlural = () => 'request Invoices';

  getIcon = () => 'invoice-icon';

  getTabs = () => [
    {
      name: 'Download Invoice',
      slug: 'download',
      description: 'Download the invoice in the PDF format.',
    },
    {
      name: 'Modify Invoice',
      slug: 'modify-invoice',
      description: 'Modify the invoice and generate it anew.',
    },
  ];

  getFilters = () => [
    {
      filter: 'pk.eq',
      label: 'Filter by variable symbol',
      type: 'data-select-filter',
      description: 'Display invoices with given variable symbol',
      props: {
        model: MODEL_NAME_REQUEST_INVOICE,
        // TODO multiple variable symbols
        multiple: false,
        placeholder: 'All variable symbols',
      },
    },
    {
      filter: 'created.range',
      label: 'Filter by date of creation',
      type: 'date-range-filter',
      description: 'Display request invoices based on their time of creation.',
    },
  ];

  getDefaultAggregates = () => [
    {
      aggregateFunction: 'Sum',
      aggregatedColumn: 'amount',
    },
  ];
}

const requestInvoiceFactory = new RequestInvoiceFactory(
  requestInvoiceProperties,
  RequestInvoice.prototype
);

export default requestInvoiceFactory;
