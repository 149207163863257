import {
  MODEL_NAME_CELEBRITY_SUGGESTION,
  MODEL_NAME_CELEBRITY_SUGGESTION_EMAIL,
} from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import CelebritySuggestionEmail from './celebrity-suggestion-email-class';
import celebritySuggestionEmailProperties from './celebrity-suggestion-email-properties';

/** @extends {DataStorageFactory<CelebritySuggestionEmail>} */
class CelebritySuggestionEmailFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_CELEBRITY_SUGGESTION_EMAIL;

  getSingular = () => 'Celebrity Suggestion Email';
  getPlural = () => 'celebrity Suggestion Emails';

  getIcon = () => 'feather-book';
  getTabs = () => [];

  getFilters = () => [
    {
      filter: 'celebritySuggestionId.eq',
      label: 'Filter by celebrity suggestion',
      type: 'data-select-filter',
      description: 'Display suggestions for a celebrity',
      props: {
        model: MODEL_NAME_CELEBRITY_SUGGESTION,
        multiple: false,
        placeholder: 'All celebrities',
      },
    },
  ];
}

const celebritySuggestionEmailFactory = new CelebritySuggestionEmailFactory(
  celebritySuggestionEmailProperties,
  CelebritySuggestionEmail.prototype
);

export default celebritySuggestionEmailFactory;
