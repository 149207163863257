/** @typedef {import('../data-storage-property-constructor-params').default} DataStoragePropertyConstructorParams */

import merge from 'lodash/merge';
import { PROPERTY_TYPE_INT } from '../../../constants';
import DataStorageProperty from '../data-storage-property';

/**
 * The default parameters for the constructor of PropertyInt.
 */
class PropertyIntConstructorParams {
  /**
   * The minimal value the int can take.
   *
   * @type {number}
   */
  min = undefined;

  /**
   * The maximal value the int can take.
   *
   * @type {number}
   */
  max = undefined;
}

/** @typedef {DataStoragePropertyConstructorParams & PropertyIntConstructorParams} ConstructorParams */

/**
 * A property of type int.
 */
class PropertyInt extends DataStorageProperty {
  propertyType = () => PROPERTY_TYPE_INT;
  tableColumnType = () => 'number';
  formComponentName = () => 'number-input';

  /**
   * Creates a new instance of PropertyInt.
   *
   * @param {ConstructorParams} params
   */
  constructor(params = {}) {
    params = merge(new PropertyIntConstructorParams(), params);

    super(params);

    this.min = params.min;
    this.max = params.max;
  }
}

/** @param {ConstructorParams} params */
const int = (params = {}) => new PropertyInt(params);

export default int;
