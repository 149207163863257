import { MODEL_NAME_REQUEST } from '../../constants';
import DataStorageProperties from '../abstract/data-storage-properties';
import date from '../abstract/properties/property-date';
import int from '../abstract/properties/property-int';
import price from '../abstract/properties/property-price';
import string from '../abstract/properties/property-string';
import url from '../abstract/properties/property-url';

const requestInvoiceProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  variableSymbol: string({
    titleKey: true,
    searchable: true,
  }),
  refYear: int({}),
  refMonth: int({}),
  requestId: int({
    references: MODEL_NAME_REQUEST,
    pretty: 'Request',
  }),
  amount: price({
    defaultValue: 0,
    min: 0,
    precision: 2,
    canAggregate: true,
    table: {
      formatFn: (amount) => `${amount} €`,
    },
  }),
  url: url({
    required: false,
  }),
  created: date({
    defaultValue: () => new Date(),
  }),
});

export default requestInvoiceProperties;
