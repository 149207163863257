import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import globalPlugins from './global-plugins';

// Fonts
import './assets/theme/fonts/feather-font/css/iconfont.css';
import './assets/theme/vendors/mdi/css/materialdesignicons.min.css';
import './assets/fonts.css';

// Styles
import 'vue-good-table/dist/vue-good-table.css';
import 'vue-select/dist/vue-select.css';
import './assets/style.scss';

Vue.config.productionTip = false;

Vue.use(globalPlugins);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
