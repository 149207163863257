<template>
  <nav class="sidebar dashboard-layout__sidebar">
    <div class="sidebar-header">
      <router-link to="/" class="sidebar-brand">
        <img src="../../assets/logo.svg" alt="logo" />
      </router-link>
    </div>
    <div class="sidebar-body ps">
      <ul class="nav">
        <!-- Home -->
        <li class="nav-item nav-category">Home</li>
        <li class="nav-item" :class="activeIf('/dashboard')">
          <router-link class="nav-link" to="/dashboard">
            <icon icon="dashboard-icon" class="link-icon" />
            <span class="link-title">Dashboard</span>
          </router-link>
        </li>

        <!-- Orders -->
        <li class="nav-item nav-category">Orders</li>
        <li class="nav-item" :class="activeIf('/orders/completed-requests')">
          <router-link class="nav-link" to="/orders/completed-requests">
            <icon icon="completed-requests-icon" class="link-icon" />
            <span class="link-title">Completed Requests</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf(requestFactory.getPath())">
          <router-link class="nav-link" :to="requestFactory.getPath()">
            <icon :icon="requestFactory.getIcon()" class="link-icon" />
            <span class="link-title">All requests</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/orders/videos')">
          <router-link class="nav-link" to="/orders/videos">
            <icon :icon="videoFactory.getIcon()" class="link-icon" />
            <span class="link-title">Videos</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/orders/audios')">
          <router-link class="nav-link" to="/orders/audios">
            <icon :icon="videoFactory.getIcon()" class="link-icon" />
            <span class="link-title">Audios</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf(payoutFactory.getPath())">
          <router-link class="nav-link" :to="payoutFactory.getPath()">
            <icon :icon="payoutFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ payoutFactory.getPlural() }}</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/orders/reviews')">
          <router-link class="nav-link" to="/orders/reviews">
            <icon icon="review-icon" class="link-icon" />
            <span class="link-title">Reviews</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf(requestInvoiceFactory.getPath())">
          <router-link class="nav-link" :to="requestInvoiceFactory.getPath()">
            <icon :icon="requestInvoiceFactory.getIcon()" class="link-icon" />
            <span class="link-title">Invoices</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf(couponFactory.getPath())">
          <router-link class="nav-link" :to="couponFactory.getPath()">
            <icon :icon="couponFactory.getIcon()" class="link-icon" />
            <span class="link-title">Coupons</span>
          </router-link>
        </li>

        <!-- Users -->
        <li class="nav-item nav-category">Users</li>
        <li class="nav-item" :class="activeIf('/users/public-users')">
          <router-link class="nav-link" to="/users/public-users">
            <icon icon="public-user-icon" class="link-icon" />
            <span class="link-title">Public users</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/users/non-public-users')">
          <router-link class="nav-link" to="/users/non-public-users">
            <icon icon="non-public-user-icon" class="link-icon" />
            <span class="link-title">Non-public users</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/users/not-approved-users')">
          <router-link class="nav-link" to="/users/not-approved-users">
            <icon icon="not-approved-user-icon" class="link-icon" />
            <span class="link-title">Not approved users</span>
          </router-link>
        </li>

        <!-- Settings -->
        <li class="nav-item nav-category">Settings</li>
        <li class="nav-item" :class="activeIf(productFactory.getPath())">
          <router-link class="nav-link" :to="productFactory.getPath()">
            <icon :icon="productFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ productFactory.getPlural() }}</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf(charityFactory.getPath())">
          <router-link class="nav-link" :to="charityFactory.getPath()">
            <icon :icon="charityFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ charityFactory.getPlural() }}</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf(tagFactory.getPath())">
          <router-link class="nav-link" :to="tagFactory.getPath()">
            <icon :icon="tagFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ tagFactory.getPlural() }}</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf(occasionFactory.getPath())">
          <router-link class="nav-link" :to="occasionFactory.getPath()">
            <icon :icon="occasionFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ occasionFactory.getPlural() }}</span>
          </router-link>
        </li>

        <!-- Stats -->
        <li class="nav-item nav-category">Statistics</li>
        <li class="nav-item" :class="activeIf('/stats/basic')">
          <router-link class="nav-link" :to="'/stats/basic'">
            <icon icon="basic-statistics-icon" class="link-icon" />
            <span class="link-title">Basic Statistics</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/stats/user')">
          <router-link class="nav-link" :to="'/stats/user'">
            <icon icon="user-statistics-icon" class="link-icon" />
            <span class="link-title">User Statistics</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/stats/user-advanced')">
          <router-link class="nav-link" :to="'/stats/user-advanced'">
            <icon icon="user-statistics-two-icon" class="link-icon" />
            <span class="link-title">User II Statistics</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/stats/request')">
          <router-link class="nav-link" :to="'/stats/request'">
            <icon icon="request-statistics-icon" class="link-icon" />
            <span class="link-title">Request Statistics</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf('/stats/product')">
          <router-link class="nav-link" :to="'/stats/product'">
            <icon icon="product-statistics-icon" class="link-icon" />
            <span class="link-title">Product Statistics</span>
          </router-link>
        </li>

        <!-- Data -->
        <li class="nav-item nav-category">Data</li>
        <li class="nav-item" :class="activeIf(contactFormMessageFactory.getPath())">
          <router-link class="nav-link" :to="contactFormMessageFactory.getPath()">
            <icon :icon="contactFormMessageFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ contactFormMessageFactory.getPlural() }}</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf(newsletterAddressFactory.getPath())">
          <router-link class="nav-link" :to="newsletterAddressFactory.getPath()">
            <icon :icon="newsletterAddressFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ newsletterAddressFactory.getPlural() }}</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf(celebritySuggestionFactory.getPath())">
          <router-link class="nav-link" :to="celebritySuggestionFactory.getPath()">
            <icon :icon="celebritySuggestionFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ celebritySuggestionFactory.getPlural() }}</span>
          </router-link>
        </li>

        <!-- System -->
        <li class="nav-item nav-category">System</li>
        <li class="nav-item" :class="activeIf(adminFactory.getPath())">
          <router-link class="nav-link" :to="adminFactory.getPath()">
            <icon :icon="adminFactory.getIcon()" class="link-icon" />
            <span class="link-title">Admin users</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf(documentTemplateFactory.getPath())">
          <router-link class="nav-link" :to="documentTemplateFactory.getPath()">
            <icon :icon="documentTemplateFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ documentTemplateFactory.getPlural() }}</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf(emailQueueFactory.getPath())">
          <router-link class="nav-link" :to="emailQueueFactory.getPath()">
            <icon :icon="emailQueueFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ emailQueueFactory.getPlural() }}</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf(smsQueueFactory.getPath())">
          <router-link class="nav-link" :to="smsQueueFactory.getPath()">
            <icon :icon="smsQueueFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ smsQueueFactory.getPlural() }}</span>
          </router-link>
        </li>
        <li class="nav-item" :class="activeIf(firebaseNotificationQueueFactory.getPath())">
          <router-link class="nav-link" :to="firebaseNotificationQueueFactory.getPath()">
            <icon :icon="firebaseNotificationQueueFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ firebaseNotificationQueueFactory.getPlural() }}</span>
          </router-link>
        </li>
        <!-- <li class="nav-item" :class="activeIf(emailTemplateFactory.getPath())">
          <router-link class="nav-link" :to="emailTemplateFactory.getPath()">
            <icon :icon="emailTemplateFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ emailTemplateFactory.getPlural() }}</span>
          </router-link>
        </li> -->
        <!-- <li class="nav-item" :class="activeIf('/email-editor')">
          <router-link class="nav-link" to="/email-editor">
            <icon icon="feather-pen-tool" class="link-icon" />
            <span class="link-title">Email Editor</span>
          </router-link>
        </li> -->
        <!-- <li class="nav-item" :class="activeIf(smsTemplateFactory.getPath())">
          <router-link class="nav-link" :to="smsTemplateFactory.getPath()">
            <icon :icon="smsTemplateFactory.getIcon()" class="link-icon" />
            <span class="link-title">{{ smsTemplateFactory.getPlural() }}</span>
          </router-link>
        </li> -->
        <!-- <li class="nav-item" :class="activeIf('/sms-editor')">
          <router-link class="nav-link" to="/sms-editor">
            <icon icon="feather-pen-tool" class="link-icon" />
            <span class="link-title">Sms Editor</span>
          </router-link>
        </li> -->
      </ul>
    </div>
  </nav>
</template>

<script>
import {
  userFactory,
  tagFactory,
  charityFactory,
  productFactory,
  occasionFactory,
  payoutFactory,
  requestFactory,
  videoFactory,
  adminFactory,
  emailQueueFactory,
  emailTemplateFactory,
  smsQueueFactory,
  smsTemplateFactory,
  documentTemplateFactory,
  documentTemplateVersionFactory,
  contactFormMessageFactory,
  newsletterAddressFactory,
  requestInvoiceFactory,
  celebritySuggestionFactory,
  celebritySuggestionEmailFactory,
  firebaseNotificationQueueFactory,
  couponFactory,
} from '../../model';

export default {
  name: 'dashboard-layout-sidebar',
  data() {
    return {
      userFactory,
      tagFactory,
      charityFactory,
      productFactory,
      occasionFactory,
      payoutFactory,
      requestFactory,
      videoFactory,
      adminFactory,
      emailQueueFactory,
      emailTemplateFactory,
      smsQueueFactory,
      smsTemplateFactory,
      documentTemplateFactory,
      documentTemplateVersionFactory,
      contactFormMessageFactory,
      newsletterAddressFactory,
      requestInvoiceFactory,
      celebritySuggestionFactory,
      celebritySuggestionEmailFactory,
      firebaseNotificationQueueFactory,
      couponFactory,
    };
  },
  methods: {
    activeIf(path) {
      return new RegExp(`${path}(/|$)`).test(this.$route.fullPath) ? 'active' : '';
    },
  },
};
</script>
