/**
 * Ascends the DOM and calls the predicate function for every element. If the
 * predicate returns `true`, the function immediately returns `true`. If the
 * top of the DOM is reached, the function returns `false`.
 *
 * @param {HTMLElement} el
 * @param {(el: HTMLElement) => boolean} predicate
 */
export const isChildOf = (el, predicate) => {
  while (el instanceof HTMLElement) {
    if (predicate(el)) {
      return true;
    }

    el = el.parentElement;
  }

  return false;
};
