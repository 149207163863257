import getFactory from '../../model';
import DataStorage from '../../model/abstract/data-storage'; // eslint-disable-line
import DataStorageFactory from '../../model/abstract/data-storage-factory'; // eslint-disable-line

/**
 * @typedef {{
 *   model: string,
 *   factory: DataStorageFactory,
 *   pk: string|number,
 *   storage: DataStorage,
 *   initial: DataStorage,
 * }} State
 */

/** @type {State} */
const state = {
  /**
   * The name of the active model.
   */
  model: null,

  /**
   * The factory for the active model.
   */
  factory: null,

  /**
   * The primary key of the storage being edited or created.
   */
  pk: null,

  /**
   * The storage being edited or created.
   */
  storage: null,

  /**
   * The initial copy of the storage being edited or created.
   */
  initial: null,
};

const getters = {
  /**
   * Getter for the `loading` property.
   *
   * @param {State}
   */
  isLoading: ({ loading }) => loading,

  /**
   * Getter for the `model` property.
   *
   * @param {State}
   */
  getModel: ({ model }) => model,

  /**
   * Getter for the `factory` property.
   *
   * @param {State}
   */
  getFactory: ({ factory }) => factory,

  /**
   * Getter for the `pk` property.
   *
   * @param {State}
   */
  getPk: ({ pk }) => pk,

  /**
   * Getter for the `storage` property.
   *
   * @param {State}
   */
  getStorage: ({ storage }) => storage,

  /**
   * Getter for the `initial` property.
   *
   * @param {State}
   */
  getInitial: ({ initial }) => initial,

  /**
   * Whether some changes have been made.
   *
   * @param {State}
   */
  hasChanges: ({ factory, storage, initial }) => {
    const changes = factory.properties.filter(
      ({ prop, areEqual }) => !areEqual(storage[prop], initial[prop])
    );

    return changes.length > 0;
  },

  /**
   * Returns the form config.
   *
   * @param {State}
   */
  getConfig: ({ factory }) => factory.properties.formDescriptor(),

  /**
   * Returns the indication whether the form can be submitted.
   *
   * @param {State}
   */
  canSubmit: ({ factory, storage }) => storage.isNew || factory.canUpdate(),
};

const actions = {
  /**
   * Fetches the storage which will be edited. The model and pk properties are
   * used as parameters.
   *
   * @param {{ state: State, commit: (name: string, payload: *) => void }}
   */
  fetchStorage: async ({ state, commit }) => {
    const { pk, factory, storage } = state;

    if (!storage || (storage.pk() || null) !== pk || !factory.isFactoryFor(storage)) {
      const storage = pk ? await factory.pk(pk) : factory.blank();

      commit('setStorage', storage);
    }
  },
};

const mutations = {
  /**
   * Starts loading.
   *
   * @param {State}
   */
  startLoading: (state) => (state.loading = true),

  /**
   * Stops loading.
   *
   * @param {State}
   */
  stopLoading: (state) => (state.loading = false),

  /**
   * Setter for the `model` property. Also sets the storage.
   *
   * @param {State}
   */
  setModel: (state, model) => {
    state.model = model;
    state.factory = getFactory(model);
  },

  /**
   * Setter for the `pk` property. Does NOT fetch the storage.
   *
   * @param {State}
   */
  setPk: (state, pk) => (state.pk = pk),

  /**
   * Setter for the `storage` property.
   *
   * @param {State} state
   * @param {DataStorage} storage
   */
  setStorage: (state, storage) => {
    state.pk = storage.pk() || null;
    state.factory = storage._factory;
    state.model = storage._factory.getModelName();
    state.storage = storage;
    state.initial = state.factory.blank(state.storage);
  },

  /**
   * Copies the storage into initial.
   *
   * @param {State}
   */
  copyStorageIntoInitial: (state) => (state.initial = state.factory.blank(state.storage)),

  /**
   * Resets the state.
   *
   * @param {State} state
   */
  reset: (state) => {
    state.factory = null;
    state.initial = null;
    state.model = null;
    state.pk = null;
    state.storage = null;
  },
};

const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
