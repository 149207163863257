const state = {
  /**
   * The form which is currently open
   *
   * @type {string}
   */
  form: null,
};

const getters = {
  /**
   * Returns the form which is currently open
   *
   * @param {state} state
   */
  getForm: ({ form }) => form,
};

const actions = {
  /**
   * Confirms the specified message - returns a Promise which resolves when the
   * user confirms and rejects otherwise.
   *
   * @param {*} _
   * @param {string} msg
   */
  confirm: (_, msg) => {
    return new Promise((resolve, reject) => {
      if (window.confirm(msg)) {
        return resolve();
      } else {
        return reject();
      }
    });
  },

  /**
   * Alerts the user about the specified message - returns a Promise which
   * resolves when the user closes the dialog.
   *
   * @param {*} _
   * @param {string} msg
   */
  alert: (_, msg) => {
    return new Promise((resolve) => {
      window.alert(msg);
      resolve();
    });
  },

  /**
   * Opens the specified form in a modal
   *
   * @param {{state: state}} context
   * @param {string} payload
   */
  openForm: ({ state }, payload) => (state.form = payload),

  /**
   * Closes the open modal
   *
   * @param {{state: state}} context
   */
  close: ({ state }) => {
    state.form = null;
  },
};

const mutations = {};

const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
