<template>
  <data-table
    :model="MODEL_NAME_REQUEST_PRODUCT"
    hideNewButton
    hideEditButton
    hideDeleteButton
    hideFilters
    :filters="{ 'requestId.eq': single.pk() }"
    :overrideColumns="['productName', 'isAddon', 'price', 'created']"
  />
</template>

<script>
import { MODEL_NAME_REQUEST_PRODUCT } from '../../constants';

export default {
  name: 'request-products-tab',
  props: ['single'],
  data() {
    return {
      MODEL_NAME_REQUEST_PRODUCT,
    };
  },
};
</script>
