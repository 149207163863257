<template>
  <span v-if="loading">Loading ...</span>
  <span v-else-if="error"> {{ error }} </span>
  <data-table
    v-else-if="video"
    :model="MODEL_NAME_VIDEO"
    hideNewButton
    hideEditButton
    hideDeleteButton
    hideFilters
    :filters="{ 'pk.eq': video.pk() }"
    :overrideColumns="['title', 'review', 'vote']"
  />
  <p v-else><b>No review has been sent!</b></p>
</template>
<script>
import { MODEL_NAME_VIDEO } from '../../constants';
import videoFactory from '../../model/video/video-factory';

export default {
  name: 'request-review-tab',
  props: ['single'],
  data() {
    return {
      MODEL_NAME_VIDEO,
      loading: false,
      error: null,
      video: null,
    };
  },
  methods: {
    async getVideo() {
      try {
        this.loading = true;
        this.error = null;
        this.video = (
          await videoFactory.fetch({
            'requestId.eq': this.single.id,
          })
        )[0];
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.getVideo();
  },
};
</script>
