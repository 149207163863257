/** @typedef {import('../data-storage-property-constructor-params').default} DataStoragePropertyConstructorParams */

import merge from 'lodash/merge';
import { PROPERTY_TYPE_BOOL } from '../../../constants';
import DataStorageProperty from '../data-storage-property';

/**
 * The default parameters for the constructor of PropertyBool.
 */
class PropertyBoolConstructorParams {
  table = {
    formatFn: (value) =>
      value === true
        ? `<div class="property-bool-formatted">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="8" fill="#00D053" fill-opacity="0.2" />
            <circle cx="8" cy="8" r="2" fill="#00D053" />
          </svg>
          <span class="true">True</span>
        </div>`
        : `<div class="property-bool-formatted">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="8" fill="#FF3333" fill-opacity="0.2" />
            <circle cx="8" cy="8" r="2" fill="#FF3333" />
          </svg>
          <span class="false">False</span>
        </div>`,
    html: true,
  };
}

/** @typedef {DataStoragePropertyConstructorParams & PropertyBoolConstructorParams} ConstructorParams */

/**
 * A property of type boolean.
 */
class PropertyBool extends DataStorageProperty {
  propertyType = () => PROPERTY_TYPE_BOOL;
  tableColumnType = () => 'string';
  formComponentName = () => 'boolean-input';

  /**
   * Creates a new instance of PropertyBool.
   *
   * @param {ConstructorParams} params
   */
  constructor(params = {}) {
    params = merge(new PropertyBoolConstructorParams(), params);

    super(params);
  }
}

/** @param {ConstructorParams} params */
const bool = (params = {}) => new PropertyBool(params);

export default bool;
