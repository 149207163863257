import { MODEL_NAME_PRODUCT, MODEL_NAME_USER } from '../../constants';
import DataStorageProperties from '../abstract/data-storage-properties';
import int from '../abstract/properties/property-int';
import price from '../abstract/properties/property-price';
import bool from '../abstract/properties/property-bool';

const userProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  userId: int({
    references: MODEL_NAME_USER,
  }),
  productId: int({
    references: MODEL_NAME_PRODUCT,
  }),
  price: price({
    min: 0,
    precision: 2,
    canAggregate: true,
  }),
  isActive: bool({
    default: true,
    required: false,
  }),
});

export default userProperties;
