<template>
  <div :class="`alert alert-${color}`">
    <icon class="mr-3" :icon="icon" />
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'not-implemented-banner',
  props: {
    text: {
      type: String,
      default: 'Not implemented!',
    },
    color: {
      type: String,
      default: 'warning',
    },
    icon: {
      type: String,
      default: 'feather-alert-circle',
    },
  },
};
</script>
