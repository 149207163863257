import { MODEL_NAME_USER_DATE_RANGE_COUNT } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import UserDateRangeCount from './user-date-range-count-class';
import userDateRangeCountProperties from './user-date-range-count-properties';

/** @extends {DataStorageFactory<UserDateRangeCount>} */
class UserDateRangeCountFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_USER_DATE_RANGE_COUNT;

  getSingular = () => 'User Date Range Count';
  getPlural = () => 'user Date Range Counts';

  getIcon = () => 'feather-users';

  getTabs = () => [];

  getFilters = () => [
    {
      filter: 'registered.range',
      label: 'Filter by date of registration',
      type: 'date-range-filter',
      description: 'Display the number of new users based on their day of registration.',
    },
  ];
}

const userDateRangeCountFactory = new UserDateRangeCountFactory(
  userDateRangeCountProperties,
  UserDateRangeCount.prototype
);

export default userDateRangeCountFactory;
