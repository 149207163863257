import { MODEL_NAME_USER, MODEL_NAME_VIDEO, MODEL_NAME_VIDEO_STATUS } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import Video from './video-class';
import videoProperties from './video-properties';

/** @extends {DataStorageFactory<Video>} */
class VideoFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_VIDEO;

  getSingular = () => 'Video';
  getPlural = () => 'videos';

  getIcon = () => 'video-icon';

  // TODO temporary
  // canUpdate = () => false;

  getTabs = () => [
    {
      name: 'Actions',
      slug: 'actions',
      description: 'Actions to be executed concerning the video.',
    },
    {
      name: 'Play',
      slug: 'play',
      description: 'View the recorded video.',
    },
  ];

  getFilters = () => [
    {
      filter: 'videoStatusId.eq',
      label: 'Filter by video status',
      type: 'data-select-filter',
      description: 'Display videos with given status.',
      props: {
        model: MODEL_NAME_VIDEO_STATUS,
        multiple: false,
        placeholder: 'All statuses',
      },
    },
    {
      filter: 'userId.eq',
      label: 'Filter by user',
      type: 'data-select-filter',
      description: 'Display videos recorded by given user.',
      props: {
        model: MODEL_NAME_USER,
        multiple: false,
        placeholder: 'All users',
      },
    },
    {
      filter: 'created.range',
      label: 'Filter by the time of creation',
      type: 'date-range-filter',
      description: 'Display videos based on their time of creation.',
    },
    {
      filter: 'vote.eq',
      label: 'Filter by video vote',
      type: 'simple-select-filter',
      description: 'Display videos with given vote.',
      props: {
        options: [
          { label: '1', value: 1 },
          { label: '2', value: 2 },
          { label: '3', value: 3 },
          { label: '4', value: 4 },
          { label: '5', value: 5 },
        ],
      },
    },
    {
      filter: 'isPromotional.eq',
      label: "Filter by video's privacy status",
      type: 'simple-select-filter',
      props: {
        options: [
          { label: 'Promotional videos', value: 1 },
          { label: 'Not promotional videos', value: 0 },
        ],
      },
    },
    {
      filter: 'isProfileVideo.eq',
      label: 'Whether the videos are profile videos',
      type: 'simple-select-filter',
      props: {
        options: [
          { label: 'Profile videos', value: 1 },
          { label: 'Not profile videos', value: 0 },
        ],
      },
    },
  ];
}

const videoFactory = new VideoFactory(videoProperties, Video.prototype);

export default videoFactory;
