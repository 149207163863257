<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill="#16242C" />
    <g clip-path="url(#clip0_188_7190)">
      <path
        d="M35.0119 21.5625H18.1875C17.2555 21.5625 16.5 22.318 16.5 23.25V36.75C16.5 37.682 17.2555 38.4375 18.1875 38.4375H35.0119C35.9439 38.4375 36.6994 37.682 36.6994 36.75V23.25C36.6994 22.318 35.9439 21.5625 35.0119 21.5625Z"
        stroke="#30E6FF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.5 36.8681L36.6993 30L43.5 23.1319V36.8681Z"
        stroke="#30E6FF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.6806 31.2656L24.2119 33.7969L31.8056 26.2031"
        stroke="#30E6FF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_188_7190">
        <rect width="36" height="36" fill="white" transform="translate(12 12)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'recording-success-icon',
};
</script>
