import { MODEL_NAME_PRODUCT } from '../../constants';
import DataStorageProperties from '../abstract/data-storage-properties';
import int from '../abstract/properties/property-int';
import price from '../abstract/properties/property-price';
import string from '../abstract/properties/property-string';

const productRequestCountSumProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
  }),
  title: string({
    pretty: 'Názov produktu (Rodičovský produkt)',
    required: false,
  }),
  productId: string({
    references: MODEL_NAME_PRODUCT,
    pretty: 'Detail produktu',
  }),
  count: int({
    required: false,
    pretty: 'Počet žiadostí s produktom',
  }),
  sum: price({
    required: false,
    pretty: 'Suma za produkt za vybavené žiadosti',
    table: {
      formatFn: (price) => `${price} €`,
    },
  }),
  productName: string({ table: { hidden: true } }),
  parentProductName: string({ table: { hidden: true } }),
});

export default productRequestCountSumProperties;
