import DataStorageProperties from '../abstract/data-storage-properties';
import bool from '../abstract/properties/property-bool';
import date from '../abstract/properties/property-date';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';
import text from '../abstract/properties/property-text';

const smsQueueProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  title: string({
    titleKey: true,
    readonly: true,
    required: false,
    form: { ignoreWhenNew: true },
  }),
  to: string({ maxLength: 255, searchable: true }),
  body: string({}),
  sent: bool({ required: false, form: { ignoreWhenNew: true }, readonly: true }),
  result: string({
    maxLength: 50,
    defaultValue: null,
    required: false,
    form: { ignoreWhenNew: true },
    readonly: true,
  }),
  resultInfo: text({
    defaultValue: null,
    table: { hidden: true },
    required: false,
    form: { ignoreWhenNew: true },
    readonly: true,
  }),
  created: date({ required: false, form: { ignoreWhenNew: true }, readonly: true }),
  processed: date({
    defaultValue: null,
    required: false,
    form: { ignoreWhenNew: true },
    readonly: true,
  }),
});

export default smsQueueProperties;
