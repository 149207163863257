<template>
  <card class="short" title="Email editor" description="Allows to edit emails.">
    <template-editor :model="MODEL_NAME_EMAIL_TEMPLATE" @submit="sendEmail" />
  </card>
</template>

<script>
import { MODEL_NAME_EMAIL_TEMPLATE } from '../constants.js';
import { emailQueueFactory } from '../model/index.js';
import { mapMutations } from 'vuex';
import replaceMultiple from '../utils/replace-multiple.js';

export default {
  name: 'page-email-editor',
  data() {
    return {
      MODEL_NAME_EMAIL_TEMPLATE,
    };
  },
  methods: {
    ...mapMutations({
      setStorage: 'dataForm/setStorage',
    }),
    sendEmail({ templateVariables, templateContent }) {
      const emailQueueStorage = emailQueueFactory.blank({
        subject: templateContent.subject,
        textBody: replaceMultiple(templateContent.textBody, templateVariables),
        htmlBody: replaceMultiple(templateContent.htmlBody, templateVariables),
      });

      this.setStorage(emailQueueStorage);

      this.$router.push(`${emailQueueFactory.getPath()}/new`);
    },
  },
};
</script>

<style></style>
