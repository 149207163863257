/** @typedef {import('../data-storage-property-constructor-params').default} DataStoragePropertyConstructorParams */

import merge from 'lodash/merge';
import { PROPERTY_TYPE_TEXT } from '../../../constants';
import DataStorageProperty from '../data-storage-property';

/**
 * The default parameters for the constructor of PropertyText.
 */
class PropertyTextConstructorParams {
  table = {
    formatFn: (value) => value,
    html: false,
    width: '400px',
  };
}

/** @typedef {DataStoragePropertyConstructorParams & PropertyTextConstructorParams} ConstructorParams */

/**
 * A property of type text.
 */
class PropertyText extends DataStorageProperty {
  propertyType = () => PROPERTY_TYPE_TEXT;
  tableColumnType = () => 'string';
  formComponentName = () => 'textarea-input';

  /**
   * Creates a new instance of PropertyText
   *
   * @param {ConstructorParams} params
   */
  constructor(params = {}) {
    params = merge(new PropertyTextConstructorParams(), params);

    super(params);

    this.table.width = params.table.width;
  }
}

/** @param {ConstructorParams} params */
const text = (params = {}) => new PropertyText(params);

export default text;
