<template>
  <div>
    <!-- Header -->
    <div class="d-flex align-items-center mt-3 mb-2">
      <h5 class="text-uppercase">{{ plural }}</h5>
      <p class="text-muted ml-2">
        Found {{ results.length }} {{ noun }}
        <template v-if="!showingAll"> &mdash; Showing only {{ limit }} {{ plural }}</template>
      </p>
    </div>

    <!-- Results -->
    <template v-for="(result, i) in someResults">
      <router-link
        @click.native="$emit('open-result')"
        :key="`search-result-${model}-${result.pk()}`"
        class="d-flex"
        :class="{
          highlighted: i + startIndex === highlighted,
          [`search-result-${i + startIndex}`]: true,
        }"
        :to="`${factory.getPath()}/${result.pk()}`"
      >
        <icon :icon="factory.getIcon()" />
        <span v-html="highlight(result)"></span>
      </router-link>
    </template>
  </div>
</template>

<script>
import getFactory from '../../model';
import Mark from 'mark.js';

export default {
  name: 'navbar-search-section',
  props: {
    q: {
      type: String,
      required: true,
    },
    results: {
      type: Array,
      required: true,
    },
    model: {
      type: String,
      required: 'true',
    },
    limit: {
      type: Number,
      default: 10,
    },
    startIndex: {
      type: Number,
      required: true,
    },
    highlighted: {
      type: Number,
      required: true,
    },
  },
  computed: {
    factory() {
      return getFactory(this.model);
    },
    singular() {
      return this.factory.getSingular();
    },
    plural() {
      return this.factory.getPlural();
    },
    noun() {
      return this.results.length === 1 ? this.singular : this.plural;
    },
    someResults() {
      return this.results.slice(0, this.limit).map((result) => this.factory.blank(result));
    },
    showingAll() {
      return this.results.length === this.someResults.length;
    },
  },
  methods: {
    highlight(dataStorage) {
      // todo: ↴
      // this method runs every time the `highlighted` property is changed.
      // This may cause performance issues if there area many search results.

      console.debug('highlight');

      const regex = new RegExp(this.q, 'ig');

      for (const { searchable, prop, titleKey } of dataStorage._factory.properties) {
        if (searchable && String(dataStorage[prop]).match(regex)) {
          const base = titleKey ? dataStorage[prop] : `${dataStorage.tk()} (${dataStorage[prop]})`;

          const span = document.createElement('span');
          span.textContent = base;
          const mark = new Mark(span);

          mark.mark(this.q);

          return span.innerHTML;
        }
      }

      return dataStorage.pretty();
    },
  },
};
</script>
