<template>
  <data-table
    :model="MODEL_NAME_PAYOUT"
    hideNewButton
    hideDeleteButton
    hideFilters
    :filters="{ 'userId.eq': single.pk() }"
    :overrideColumns="['amount', 'iban', 'invoiceUrl', 'created', 'approved', 'completed']"
    :userSortOptions="{
      enabled: true,
      initialSortBy: [
        { field: 'created', type: 'desc' },
        { field: 'approved', type: 'desc' },
      ],
    }"
  />
</template>

<script>
import { MODEL_NAME_PAYOUT } from '../../constants';
export default {
  name: 'user-payouts-tab',
  props: ['single'],
  data() {
    return {
      MODEL_NAME_PAYOUT,
    };
  },
};
</script>
