import { MODEL_NAME_PRODUCT_REQUEST_COUNT_SUM } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import ProductRequestCountSum from './product-request-count-sum-class';
import productRequestCountSumProperties from './product-request-count-sum-properties';

/** @extends {DataStorageFactory<ProductRequestCountSum>} */
class ProductRequestCountSumFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_PRODUCT_REQUEST_COUNT_SUM;

  getSingular = () => 'Product Request Count Sum';
  getPlural = () => 'product Request Count Sums';

  getIcon = () => 'feather-user';

  getTabs = () => [];

  getFilters = () => [];
}

const productRequestCountSumFactory = new ProductRequestCountSumFactory(
  productRequestCountSumProperties,
  ProductRequestCountSum.prototype
);

export default productRequestCountSumFactory;
