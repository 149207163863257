/** @typedef {import('../data-storage-property-constructor-params').default} DataStoragePropertyConstructorParams */

import merge from 'lodash/merge';
import { PROPERTY_TYPE_OBJECT } from '../../../constants';
import DataStorageProperty from '../data-storage-property';

/**
 * The default parameters for the constructor of PropertyObject.
 */
class PropertyObjectConstructorParams {}

/** @typedef {DataStoragePropertyConstructorParams & PropertyObjectConstructorParams} ConstructorParams */

/**
 * A property of type object.
 */
class PropertyObject extends DataStorageProperty {
  propertyType = () => PROPERTY_TYPE_OBJECT;
  tableColumnType = () => 'string';
  formComponentName = () => 'object-input';

  /**
   * Creates a new instance of PropertyObject.
   *
   * @param {ConstructorParams} params
   */
  constructor(params = {}) {
    params = merge(new PropertyObjectConstructorParams(), params);

    super(params);
  }
}

/** @param {ConstructorParams} params */
const object = (params = {}) => new PropertyObject(params);

export default object;
