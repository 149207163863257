<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill="#1F2922" />
    <g clip-path="url(#clip0_188_7183)">
      <path
        d="M24.195 22.74V37.26C24.195 38.55 25.62 39.315 26.7 38.625L38.055 31.365C39.06 30.72 39.06 29.265 38.055 28.635L26.7 21.375C25.62 20.685 24.195 21.465 24.195 22.74Z"
        stroke="#3CF365"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_188_7183">
        <rect width="36" height="36" fill="white" transform="translate(12 12)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'completed-videos-icon',
};
</script>
