import { MODEL_NAME_USER } from '../../constants';
import DataStorageProperties from '../abstract/data-storage-properties';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';

const userRequestSuccessFailCountProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  userId: string({
    references: MODEL_NAME_USER,
    pretty: 'User',
  }),
  successful: int({
    required: false,
    pretty: 'Successful requests',
  }),
  cancelled: int({
    required: false,
    pretty: 'Cancelled requests',
  }),
  total: int({
    required: false,
    pretty: 'Total requests',
  }),
  successRate: int({
    required: false,
  }),
});

export default userRequestSuccessFailCountProperties;
