import { MODEL_NAME_TAG } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import Tag from './tag-class';
import tagProperties from './tag-properties';

/** @extends {DataStorageFactory<Tag>} */
class TagFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_TAG;

  getSingular = () => 'Tag';
  getPlural = () => 'tags';

  getIcon = () => 'tag-icon';

  getTabs = () => [];

  getFilters = () => [
    {
      filter: 'isCategory.eq',
      label: 'Filter by category',
      type: 'simple-select-filter',
      description: 'Display tags based on their category state.',
      props: {
        options: [
          { label: 'Category', value: true },
          { label: 'Not category', value: false },
        ],
      },
    },
  ];
}

const tagFactory = new TagFactory(tagProperties, Tag.prototype);

export default tagFactory;
