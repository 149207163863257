import { MODEL_NAME_USER } from '../../constants';
import DataStorageProperties from '../abstract/data-storage-properties';
import bool from '../abstract/properties/property-bool';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';

const userBillingAddressProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  userId: int({
    references: MODEL_NAME_USER,
  }),
  name: string({
    maxLength: 255,
    searchable: true,
    titleKey: true,
  }),
  address: string({
    maxLength: 255,
  }),
  zip: string({
    maxLength: 10,
  }),
  city: string({
    maxLength: 100,
  }),
  ico: string({
    maxLength: 20,
  }),
  dic: string({
    maxLength: 20,
  }),
  isVatPayer: bool({}),
  icdph: string({
    required: false,
  }),
});

export default userBillingAddressProperties;
