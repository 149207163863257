import { MODEL_NAME_PRODUCT, MODEL_NAME_REQUEST } from '../../constants';
import DataStorageProperties from '../abstract/data-storage-properties';
import bool from '../abstract/properties/property-bool';
import date from '../abstract/properties/property-date';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';

const tagProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  requestId: int({
    pretty: 'Request',
    references: MODEL_NAME_REQUEST,
  }),
  productId: int({
    pretty: 'Product',
    references: MODEL_NAME_PRODUCT,
    defaultValue: null,
  }),
  productName: string({
    maxLength: 50,
    titleKey: true,
    pretty: 'Request Product',
  }),
  isAddon: bool({}),
  price: int({
    table: {
      formatFn: (price) => `${price} €`,
    },
    canAggregate: true,
  }),
  created: date({
    defaultValue: null,
    required: false,
  }),
  priceUser: int({
    defaultValue: 0,
  }),
});

export default tagProperties;
