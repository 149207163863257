/**
 * A simple date builder which allows addition and subtraction of time units.
 * Also implements simple static methods for frequently used dates such as
 * `now`, `yesterday`, `tomorrow`, etc.
 */
class DateBuilder {
  /**
   * Sets the milliseconds.
   *
   * @param {number} milliseconds
   */
  setMilliseconds(milliseconds) {
    this.date.setMilliseconds(milliseconds);
    return this;
  }

  /**
   * Adds milliseconds.
   *
   * @param {number} milliseconds
   */
  addMilliseconds(milliseconds) {
    this.date.setMilliseconds(this.date.getMilliseconds() + milliseconds);
    return this;
  }

  /**
   * Subtracts milliseconds.
   *
   * @param {number} milliseconds
   */
  subtractMilliseconds(milliseconds) {
    this.addMilliseconds(-milliseconds);
    return this;
  }

  /**
   * Sets the seconds.
   *
   * @param {number} seconds
   */
  setSeconds(seconds) {
    this.date.setSeconds(seconds);
    return this;
  }

  /**
   * Adds seconds.
   *
   * @param {number} seconds
   */
  addSeconds(seconds) {
    this.date.setSeconds(this.date.getSeconds() + seconds);
    return this;
  }

  /**
   * Subtracts seconds.
   *
   * @param {number} seconds
   */
  subtractSeconds(seconds) {
    this.addSeconds(-seconds);
    return this;
  }

  /**
   * Sets the minutes.
   *
   * @param {number} minutes
   */
  setMinutes(minutes) {
    this.date.setMinutes(minutes);
    return this;
  }

  /**
   * Adds minutes.
   *
   * @param {number} minutes
   */
  addMinutes(minutes) {
    this.date.setMinutes(this.date.getMinutes() + minutes);
    return this;
  }

  /**
   * Subtracts minutes.
   *
   * @param {number} minutes
   */
  subtractMinutes(minutes) {
    this.addMinutes(-minutes);
    return this;
  }

  /**
   * Sets the hours.
   *
   * @param {number} hours
   */
  setHours(hours) {
    this.date.setHours(hours);
    return this;
  }

  /**
   * Adds hours.
   *
   * @param {number} hours
   */
  addHours(hours) {
    this.date.setHours(this.date.getHours() + hours);
    return this;
  }

  /**
   * Subtracts hours.
   *
   * @param {number} hours
   */
  subtractHours(hours) {
    this.addHours(-hours);
    return this;
  }

  /**
   * Sets the date.
   *
   * @param {number} date
   */
  setDate(date) {
    this.date.setDate(date);
    return this;
  }

  /**
   * Adds days.
   *
   * @param {number} days
   */
  addDays(days) {
    this.date.setDate(this.date.getDate() + days);
    return this;
  }

  /**
   * Subtracts days.
   *
   * @param {number} days
   */
  subtractDays(days) {
    this.addDays(-days);
    return this;
  }

  /**
   * Adds weeks.
   *
   * @param {number} weeks
   */
  addWeeks(weeks) {
    this.addDays(weeks * 7);
    return this;
  }

  /**
   * Subtracts weeks.
   *
   * @param {number} weeks
   */
  subtractWeeks(weeks) {
    this.addWeeks(-weeks);
    return this;
  }

  /**
   * Sets the months.
   *
   * @param {number} months
   */
  setMonth(months) {
    this.date.setMonth(months);
    return this;
  }

  /**
   * Adds months.
   *
   * @param {number} months
   */
  addMonths(months) {
    this.date.setMonth(this.date.getMonth() + months);
    return this;
  }

  /**
   * Subtracts months.
   *
   * @param {number} months
   */
  subtractMonths(months) {
    this.addMonths(-months);
    return this;
  }

  /**
   * Sets the full year.
   *
   * @param {number} years
   */
  setFullYear(years) {
    this.date.setFullYear(years);
    return this;
  }

  /**
   * Adds years.
   *
   * @param {number} years
   */
  addYears(years) {
    this.date.setFullYear(this.date.getFullYear() + years);
    return this;
  }

  /**
   * Subtracts years.
   *
   * @param {number} years
   */
  subtractYears(years) {
    this.addYears(-years);
    return this;
  }

  dayStart() {
    this.setHours(0).setMinutes(0).setSeconds(0).setMilliseconds(0);

    return this;
  }

  dayEnd() {
    this.setHours(23).setMinutes(23).setSeconds(59).setMilliseconds(999);

    return this;
  }

  weekStart() {
    this.subtractDays(this.date.getDay() - 1).dayStart();

    return this;
  }

  weekEnd() {
    this.weekStart().addDays(6).dayEnd();

    return this;
  }

  monthStart() {
    this.setDate(1).dayStart();

    return this;
  }

  monthEnd() {
    this.monthStart().addMonths(1).subtractDays(1).dayEnd();

    return this;
  }

  yearStart() {
    this.setMonth(0).monthStart();

    return this;
  }

  yearEnd() {
    this.yearStart().addYears(1).subtractDays(1).dayEnd();

    return this;
  }

  /**
   * Returns the built date.
   */
  build() {
    return this.date;
  }

  /**
   * Creates a date builder for the current date.
   */
  static now() {
    return new DateBuilder(new Date());
  }

  /**
   * Creates a date builder for the specified value.
   *
   * @param {*} value
   */
  static from(value) {
    return new DateBuilder(new Date(value));
  }

  /**
   * Creates a new instance of DateBuilder.
   *
   * @param {Date} date
   */
  constructor(date) {
    /**
     * The date being built.
     *
     * @type {Date}
     */
    this.date = date;
  }
}

module.exports = DateBuilder;
