import { MODEL_NAME_SMS_QUEUE } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import SmsQueue from './sms-queue-class';
import smsQueueProperties from './sms-queue-properties';

/** @extends {DataStorageFactory<SmsQueue>} */
class SmsQueueFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_SMS_QUEUE;

  getSingular = () => 'Sms Queue';
  getPlural = () => 'sms Queues';

  getIcon = () => 'sms-queue-icon';

  canUpdate = () => false;

  getTabs = () => [];

  getFilters = () => [
    {
      filter: 'sent.eq',
      label: 'Filter by the sending status',
      type: 'simple-select-filter',
      description: 'Display SMS in the queue based on the sent property.',
      props: {
        options: [
          { label: 'Sent', value: true },
          { label: 'Not sent', value: false },
        ],
      },
    },
    {
      filter: 'result.eq',
      label: 'Filter by result',
      type: 'simple-select-filter',
      description: 'Display SMS in queue based on the success of their sending.',
      props: {
        options: [
          { label: 'Successful', value: 'ok' },
          { label: 'Not successful', value: 'error' },
        ],
      },
    },
    {
      filter: 'created.range',
      label: 'Filter by the date of creation',
      type: 'date-range-filter',
      description: 'Display SMS in queue based on their date of creation.',
    },
  ];
}

const smsQueueFactory = new SmsQueueFactory(smsQueueProperties, SmsQueue.prototype);

export default smsQueueFactory;
