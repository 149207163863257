/** @typedef {import('../data-storage-property-constructor-params').default} DataStoragePropertyConstructorParams */

import merge from 'lodash/merge';
import { PROPERTY_TYPE_CONNECTION } from '../../../constants';
import DataStorageProperty from '../data-storage-property';

/**
 * The default parameters for the constructor of PropertyConnection.
 */
class PropertyConnectionConstructorParams {
  /**
   * The name of the connection.
   *
   * @type {string}
   */
  connection = undefined;
}

/** @typedef {DataStoragePropertyConstructorParams & PropertyConnectionConstructorParams} ConstructorParams */

/**
 * A property of type connection.
 */
class PropertyConnection extends DataStorageProperty {
  propertyType = () => PROPERTY_TYPE_CONNECTION;
  tableColumnType = () => ''; // This will be ignored
  formComponentName = () => ''; // This will be ignored

  /**
   * Creates a new instance of PropertyConnection.
   *
   * @param {ConstructorParams} params
   */
  constructor(params = {}) {
    params = merge(new PropertyConnectionConstructorParams(), params);

    super(params);

    this.connection = params.connection;

    this.form.ignore = true; // Always ignore
  }
}

/** @param {ConstructorParams} params */
const connection = (params = {}) => new PropertyConnection(params);

export default connection;
