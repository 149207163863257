<template>
  <div>
    <span v-if="loading">Loading ...</span>
    <span v-else-if="errors"> {{ errors }} </span>
    <card v-else title="Product statistics" description="Holds product statistics">
      <data-table :model="model" hideAggregates hideNewButton hideFilters />
      <div class="showtime-stats-chart">
        <pie-chart :chart="chart" :backgroundColor="colors" :labels="labels" />
      </div>
    </card>
  </div>
</template>

<script>
import { MODEL_NAME_PRODUCT_REQUEST_COUNT_SUM } from '../../constants';
import PieChart from '../../components/charts/PieChart.vue';
import getFactory from '../../model';
import { randomColor } from '../../utils/random-color';

export default {
  name: 'page-product-stats',
  components: {
    PieChart,
  },
  data() {
    return {
      loading: false,
      errors: null,
      model: MODEL_NAME_PRODUCT_REQUEST_COUNT_SUM,
      response: null,
      chart: [],
      labels: [],
      colors: [],
    };
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        this.errors = null;
        this.response = await this.factory.fetch();

        this.response.forEach((item) => {
          if (item.count > 0) {
            this.chart.push(item.sum);
            this.labels.push(item.title);
            this.colors.push(randomColor());
          }
        });
      } catch (e) {
        this.errors = e;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    factory() {
      return getFactory(this.model);
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>

<style></style>
