<template>
  <select class="form-control" v-model="valueStorage" v-bind="$attrs">
    <option :value="null" disabled></option>
    <option :value="false">No</option>
    <option :value="true">Yes</option>
  </select>
</template>

<script>
export default {
  name: 'boolean-input',
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    properties: {},
    type: {},
  },
  data() {
    return {
      valueStorage: this.value,
    };
  },
  watch: {
    valueStorage() {
      this.$emit('input', this.valueStorage);
    },
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.valueStorage = newValue;
      }
    },
  },
};
</script>
