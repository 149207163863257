<template>
  <span v-if="loading">Loading ...</span>
  <span v-else-if="error"> {{ error }} </span>
  <div v-else-if="video">
    <video controls width="350">
      <source :src="video.webmUrl" type="video/webm" />
      <source :src="video.mp4Url" type="video/mp4" />

      Your browser does not support embedded videos.
    </video>
    <div class="mt-2">
      <data-table
        :model="MODEL_NAME_VIDEO"
        hideNewButton
        hideEditButton
        hideDeleteButton
        hideFilters
        :filters="{ 'pk.eq': video.pk() }"
        :overrideColumns="['title', 'userId', 'created']"
      />
    </div>
  </div>
  <p v-else><b>No video has been recorded!</b></p>
</template>
<script>
import { MODEL_NAME_VIDEO } from '../../constants';
import videoFactory from '../../model/video/video-factory';

export default {
  name: 'request-products-tab',
  props: ['single'],
  data() {
    return {
      MODEL_NAME_VIDEO,
      loading: false,
      error: null,
      video: null,
    };
  },
  methods: {
    async getVideos() {
      try {
        this.loading = true;
        this.error = null;
        this.video = (
          await videoFactory.fetch({
            'requestId.eq': this.single.id,
          })
        )[0];
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.getVideos();
  },
};
</script>

<style lang="scss">
p {
  color: #8f95a3;
}
</style>
