<template>
  <div class="showtime-statistics ml-2 mr-2 mb-4">
    <div class="statistics-content">
      <div class="statistics-img mb-2">
        <icon :icon="statsIcon" />
      </div>
      <h5 class="showtime-statistics-title">{{ title }}</h5>
      <data-aggregate-display
        :model="model"
        :aggregatedProperty="aggregatedProperty"
        :aggregateFunction="aggregateFunction"
        :filters="filters"
        :precision="precision"
        :reloadToken="reloadToken"
      >
        <span slot-scope="{ value }">{{ transformFn(value) }}</span>
      </data-aggregate-display>
      {{ suffix }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'data-stats',
  props: {
    model: {
      type: String,
    },
    title: {
      type: String,
    },
    aggregatedProperty: {
      type: String,
    },
    aggregateFunction: {
      type: String,
    },
    suffix: {
      type: String,
      default: '€',
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    transformFn: {
      type: Function,
      default: (val) => val,
    },
    precision: {
      type: Number,
      default: 2,
    },
    reloadToken: {
      default: null,
    },
    statsIcon: {
      default: '',
    },
  },
};
</script>

<style lang="scss">
.d-flex {
  .showtime-statistics {
    min-width: 150px;
    max-width: 150px;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    border: 1px solid #f2f4f9;
    padding: 15px;
  }
}

.statistics-content {
  padding: 10px;
}

.showtime-statistics-title {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
</style>
