<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill="#29281F" />
    <g clip-path="url(#clip0_188_7176)">
      <path
        d="M40.14 21.51H19.875C18.6158 21.51 17.595 22.5308 17.595 23.79V36.195C17.595 37.4542 18.6158 38.475 19.875 38.475H40.14C41.3992 38.475 42.42 37.4542 42.42 36.195V23.79C42.42 22.5308 41.3992 21.51 40.14 21.51Z"
        stroke="#E5D55B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.525 32.715H41.475"
        stroke="#E5D55B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.35 26.97H31.095"
        stroke="#E5D55B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_188_7176">
        <rect width="36" height="36" fill="white" transform="translate(12 12)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'money-icon',
};
</script>
