import DataStorageProperties from '../abstract/data-storage-properties';
import int from '../abstract/properties/property-int';
import string from '../abstract/properties/property-string';
import text from '../abstract/properties/property-text';

const occasionProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  slug: string({
    maxLength: 50,
    searchable: true,
  }),
  name: string({
    maxLength: 50,
    titleKey: true,
    searchable: true,
  }),
  ord: int(),
  templateText: text({
    required: false,
    table: { hidden: true },
  }),
});

export default occasionProperties;
