<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-corner-left-down"
  >
    <polyline points="14 15 9 20 4 15"></polyline>
    <path d="M20 4h-7a4 4 0 0 0-4 4v12"></path>
  </svg>
</template>

<script>
export default {
  name: 'icon-feather-corner-left-down',
};
</script>
