<template>
  <not-implemented-banner />
</template>

<script>
export default {
  name: 'document-template-version-download-tab',
};
</script>

<style></style>
