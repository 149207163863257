<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_256_2743)">
      <path
        d="M9.11998 4.38H5.76998C5.0244 4.38 4.41998 4.98442 4.41998 5.73V9.08C4.41998 9.82559 5.0244 10.43 5.76998 10.43H9.11998C9.86557 10.43 10.47 9.82559 10.47 9.08V5.73C10.47 4.98442 9.86557 4.38 9.11998 4.38Z"
        stroke="#8F95A3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.58 7.405C19.58 5.73434 18.2256 4.38 16.555 4.38C14.8843 4.38 13.53 5.73434 13.53 7.405C13.53 9.07567 14.8843 10.43 16.555 10.43C18.2256 10.43 19.58 9.07567 19.58 7.405Z"
        stroke="#8F95A3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.47 16.595C10.47 14.9243 9.11564 13.57 7.44498 13.57C5.77432 13.57 4.41998 14.9243 4.41998 16.595C4.41998 18.2657 5.77432 19.62 7.44498 19.62C9.11564 19.62 10.47 18.2657 10.47 16.595Z"
        stroke="#8F95A3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.23 13.57H14.88C14.1344 13.57 13.53 14.1744 13.53 14.92V18.27C13.53 19.0156 14.1344 19.62 14.88 19.62H18.23C18.9756 19.62 19.58 19.0156 19.58 18.27V14.92C19.58 14.1744 18.9756 13.57 18.23 13.57Z"
        stroke="#8F95A3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_256_2743">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'dashboard-icon',
};
</script>
