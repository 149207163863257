<template>
  <data-table
    :model="MODEL_NAME_REQUEST"
    hideNewButton
    hideEditButton
    hideDeleteButton
    hideFilters
    :filters="{ 'userId.eq': single.pk() }"
    :overrideColumns="['title', 'created', 'contact', 'requestStatusId', 'amountUser']"
    :userSortOptions="{
      enabled: true,
      initialSortBy: [{ field: 'created', type: 'desc' }],
    }"
  />
</template>

<script>
import { MODEL_NAME_REQUEST } from '../../constants';

export default {
  name: 'user-requests-tab',
  props: ['single'],
  data() {
    return {
      MODEL_NAME_REQUEST,
    };
  },
};
</script>
