<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.75 5.02997C8.07 4.70997 8.52 4.53997 9.02 4.61997C9.63 4.70997 10.14 5.20997 10.23 5.82997C10.32 6.44997 10.06 6.97997 9.61 7.27997C9.25 7.52997 8.97 7.87997 8.85 8.27997"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.71002 10.2V9.98999"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.9376 1H3.06242C1.92266 1 1 1.96935 1 3.16679V11.1953C1 12.3927 1.92266 13.3621 3.06242 13.3621H4.47354V17L8.7829 13.3621H14.9376C16.0773 13.3621 17 12.3927 17 11.1953V3.16679C17 1.96935 16.0773 1 14.9376 1Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'contact-form-message-icon',
};
</script>
