import { MODEL_NAME_COUPON } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import Coupon from './coupon-class';
import couponProperties from './coupon-properties';

/** @extends {DataStorageFactory<Coupon>} */
class CouponFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_COUPON;

  getSingular = () => 'Coupon';
  getPlural = () => 'Coupons';

  getTabs = () => [];

  getIcon = () => 'charity-icon';
}

const couponFactory = new CouponFactory(couponProperties, Coupon.prototype);

export default couponFactory;
