<template>
  <span>
    <span v-if="loading"> Loading ... </span>
    <span v-else-if="error">{{ error }} </span>
    <template v-else>
      <slot :value="value">
        <span>{{ value }}</span>
      </slot>
    </template>
  </span>
</template>

<script>
import connector from '../../api/connector';

export default {
  name: 'data-aggregate-display',
  props: {
    model: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    aggregatedProperty: {
      type: String,
    },
    aggregateFunction: {
      type: String,
    },
    reloadToken: {
      default: null,
    },
    precision: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      value: null,
    };
  },
  methods: {
    async fetchData() {
      try {
        if (this.aggregatedProperty !== null && this.aggregateFunction !== null) {
          this.loading = true;
          this.error = null;
          const params = {};
          Object.entries(this.filters).forEach(([key, value]) => {
            params[`filters[${key}]`] = value;
          });

          const result = await connector.get(
            `/stats/aggregate/${this.model}/${this.aggregatedProperty}/${this.aggregateFunction}`,
            { params }
          );

          this.value = Number(result.aggregates[0].value).toFixed(this.precision);
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    async reloadToken() {
      await this.fetchData();
    },
  },
  async created() {
    await this.fetchData();
  },
};
</script>

<style></style>
