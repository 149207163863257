import { MODEL_NAME_EMAIL_TEMPLATE } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import EmailTemplate from './email-template-class';
import emailTemplateProperties from './email-template-properties';

/** @extends {DataStorageFactory<EmailTemplate>} */
class EmailTemplateFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_EMAIL_TEMPLATE;

  getSingular = () => 'Email Template';
  getPlural = () => 'email Templates';

  getIcon = () => 'feather-archive';

  getTabs = () => [];

  /**
   *
   * @param {EmailTemplate} emailTemplate
   */
  getTemplateBody = (emailTemplate) => emailTemplate.textBody;
}

const emailTemplateFactory = new EmailTemplateFactory(
  emailTemplateProperties,
  EmailTemplate.prototype
);

export default emailTemplateFactory;
