import { MODEL_NAME_CONTACT_FORM_MESSAGE } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import ContactFormMessage from './contact-form-message-class';
import contactFormMessageProperties from './contact-form-message-properties';

/** @extends {DataStorageFactory<ContactFormMessage>} */
class ContactFormMessageFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_CONTACT_FORM_MESSAGE;

  getSingular = () => 'Contact Form Message';
  getPlural = () => 'contact Form Messages';

  getIcon = () => 'contact-form-message-icon';

  getTabs = () => [];

  canUpdate = () => false;
  canInsert = () => false;
}

const contactFormMessageFactory = new ContactFormMessageFactory(
  contactFormMessageProperties,
  ContactFormMessage.prototype
);

export default contactFormMessageFactory;
