import { MODEL_NAME_REQUEST_STATUS, MODEL_NAME_USER_REQUEST_COUNT } from '../../constants';
import DataStorageFactory from '../abstract/data-storage-factory';
import UserRequestCount from './user-request-count-class';
import userRequestCountProperties from './user-request-count-properties';

/** @extends {DataStorageFactory<UserRequestCount>} */
class UserRequestCountFactory extends DataStorageFactory {
  getModelName = () => MODEL_NAME_USER_REQUEST_COUNT;

  getSingular = () => 'User Request Count';
  getPlural = () => 'user Request Counts';

  getIcon = () => 'feather-tag';

  getTabs = () => [];

  getFilters = () => [
    {
      filter: 'requestStatusId.eq',
      label: 'Filter by request status',
      type: 'data-select-filter',
      description: 'Display requests based on their status',
      props: {
        model: MODEL_NAME_REQUEST_STATUS,
        multiple: false,
        placeholder: 'All statuses',
      },
    },
  ];

  canDelete = () => false;
  canInsert = () => false;
  canUpdate = () => false;
}

const userRequestCountFactory = new UserRequestCountFactory(
  userRequestCountProperties,
  UserRequestCount.prototype
);

export default userRequestCountFactory;
