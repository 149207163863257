/** @typedef {import('../data-storage-property-constructor-params').default} DataStoragePropertyConstructorParams */

import merge from 'lodash/merge';
import { PROPERTY_TYPE_STRING } from '../../../constants';
import DataStorageProperty from '../data-storage-property';

/**
 * The default parameters for the constructor of PropertyString.
 */
class PropertyStringConstructorParams {
  /**
   * The maximal number of characters.
   *
   * @type {number}
   */
  maxLength = undefined;
}

/** @typedef {DataStoragePropertyConstructorParams & PropertyStringConstructorParams} ConstructorParams */

/**
 * A property of type string (varchar).
 */
class PropertyString extends DataStorageProperty {
  propertyType = () => PROPERTY_TYPE_STRING;
  tableColumnType = () => 'string';
  formComponentName = () => 'text-input';

  /**
   * Creates a new instance of PropertyString.
   *
   * @param {ConstructorParams} params
   */
  constructor(params = {}) {
    params = merge(new PropertyStringConstructorParams(), params);

    super(params);

    this.maxLength = params.maxLength;
  }
}

/** @param {ConstructorParams} params */
const string = (params = {}) => new PropertyString(params);

export default string;
