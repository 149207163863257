<template>
  <div class="tab-pane">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'tab-pane',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      COMPONENT_TAB_PANE: true,
    };
  },
};
</script>
