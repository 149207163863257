import { camelCase } from 'change-case';
import userFactory from './user/user-factory';
import userProductFactory from './user-product/user-product-factory';
import userBillingAddressFactory from './user-billing-address/user-billing-address-factory';
import tagFactory from './tag/tag-factory';
import charityFactory from './charity/charity-factory';
import occasionFactory from './occasion/occasion-factory';
import productFactory from './product/product-factory';
import payoutFactory from './payout/payout-factory';
import requestFactory from './request/request-factory';
import requestProductFactory from './request-product/request-product-factory';
import requestStatusFactory from './request-status/request-status-factory';
import videoFactory from './video/video-factory';
import videoStatusFactory from './video-status/video-status-factory';
import adminFactory from './admin/admin-factory';
import emailQueueFactory from './email-queue/email-queue-factory';
import emailTemplateFactory from './email-template/email-template-factory';
import smsQueueFactory from './sms-queue/sms-queue-factory';
import smsTemplateFactory from './sms-template/sms-template-factory';
import documentTemplateFactory from './document-template/document-template-factory';
import documentTemplateVersionFactory from './document-template-version/document-template-version-factory';
import contactFormMessageFactory from './contact-form-message/contact-form-message-factory';
import newsletterAddressFactory from './newsletter-address/newsletter-address-factory';
import requestInvoiceFactory from './request-invoice/request-invoice-factory';
import userRequestCountFactory from './user-request-count/user-request-count-factory';
import userRequestSuccessFailCountFactory from './user-request-success-fail-count/user-request-success-fail-count-factory';
import productRequestCountSumFactory from './product-request-count-sum/product-request-count-sum-factory';
import requestDateRangeCountFactory from './request-date-range-count/request-date-range-count-factory';
import userDateRangeCountFactory from './user-date-range-count/user-date-range-count-factory';
import celebritySuggestionFactory from './celebrity-suggestion/celebrity-suggestion-factory';
import celebritySuggestionEmailFactory from './celebrity-suggestion-email/celebrity-suggestion-email-factory';
import userCharityLogFactory from './user-charity-log/user-charity-log-factory';
import userPromotionalVideoFactory from './user-promotional-video/user-promotional-video-factory';
import firebaseNotificationQueueFactory from './firebase-notification-queue/firebase-notification-queue-factory';
import couponFactory from './coupon/coupon-factory';
import userSumBalanceFactory from './user-sum-balance/user-sum-balance-factory';
import userRecordingTimeFactory from './user-recording-time/user-recording-time-factory';
import userTotalRecordingTimeFactory from './user-total-recording-time/user-total-recording-time-factory';

const factories = {
  [camelCase(userFactory.getModelName())]: userFactory,
  [camelCase(userProductFactory.getModelName())]: userProductFactory,
  [camelCase(userBillingAddressFactory.getModelName())]: userBillingAddressFactory,
  [camelCase(tagFactory.getModelName())]: tagFactory,
  [camelCase(charityFactory.getModelName())]: charityFactory,
  [camelCase(occasionFactory.getModelName())]: occasionFactory,
  [camelCase(productFactory.getModelName())]: productFactory,
  [camelCase(payoutFactory.getModelName())]: payoutFactory,
  [camelCase(requestFactory.getModelName())]: requestFactory,
  [camelCase(requestProductFactory.getModelName())]: requestProductFactory,
  [camelCase(requestStatusFactory.getModelName())]: requestStatusFactory,
  [camelCase(videoFactory.getModelName())]: videoFactory,
  [camelCase(videoStatusFactory.getModelName())]: videoStatusFactory,
  [camelCase(adminFactory.getModelName())]: adminFactory,
  [camelCase(emailQueueFactory.getModelName())]: emailQueueFactory,
  [camelCase(emailTemplateFactory.getModelName())]: emailTemplateFactory,
  [camelCase(smsQueueFactory.getModelName())]: smsQueueFactory,
  [camelCase(smsTemplateFactory.getModelName())]: smsTemplateFactory,
  [camelCase(documentTemplateFactory.getModelName())]: documentTemplateFactory,
  [camelCase(documentTemplateVersionFactory.getModelName())]: documentTemplateVersionFactory,
  [camelCase(contactFormMessageFactory.getModelName())]: contactFormMessageFactory,
  [camelCase(newsletterAddressFactory.getModelName())]: newsletterAddressFactory,
  [camelCase(requestInvoiceFactory.getModelName())]: requestInvoiceFactory,
  [camelCase(celebritySuggestionFactory.getModelName())]: celebritySuggestionFactory,
  [camelCase(celebritySuggestionEmailFactory.getModelName())]: celebritySuggestionEmailFactory,
  [camelCase(userCharityLogFactory.getModelName())]: userCharityLogFactory,
  [camelCase(userPromotionalVideoFactory.getModelName())]: userPromotionalVideoFactory,
  [camelCase(firebaseNotificationQueueFactory.getModelName())]: firebaseNotificationQueueFactory,
  [camelCase(userRequestCountFactory.getModelName())]: userRequestCountFactory,
  [camelCase(userRequestSuccessFailCountFactory.getModelName())]:
    userRequestSuccessFailCountFactory,
  [camelCase(productRequestCountSumFactory.getModelName())]: productRequestCountSumFactory,
  [camelCase(requestDateRangeCountFactory.getModelName())]: requestDateRangeCountFactory,
  [camelCase(userDateRangeCountFactory.getModelName())]: userDateRangeCountFactory,
  [camelCase(couponFactory.getModelName())]: couponFactory,
  [camelCase(userSumBalanceFactory.getModelName())]: userSumBalanceFactory,
  [camelCase(userRecordingTimeFactory.getModelName())]: userRecordingTimeFactory,
  [camelCase(userTotalRecordingTimeFactory.getModelName())]: userTotalRecordingTimeFactory,
};

/**
 * Get factory by model name.
 *
 * @param {string} name
 */
const getFactory = (name) => {
  if (typeof name !== 'string') {
    throw new Error(`Model does not exist: ${name}`);
  }

  name = camelCase(name);

  if (!Object.prototype.hasOwnProperty.call(factories, name)) {
    throw new Error(`Model does not exist: ${name}`);
  }

  return factories[name];
};

/**
 * Check if model with the specified name exists.
 *
 * @param {string} name
 */
export const modelExists = (name) => Object.keys(factories).includes(camelCase(name));

export default getFactory;

export {
  userFactory,
  userBillingAddressFactory,
  tagFactory,
  charityFactory,
  occasionFactory,
  productFactory,
  payoutFactory,
  requestFactory,
  requestProductFactory,
  requestStatusFactory,
  videoFactory,
  videoStatusFactory,
  adminFactory,
  emailQueueFactory,
  emailTemplateFactory,
  smsQueueFactory,
  smsTemplateFactory,
  documentTemplateFactory,
  documentTemplateVersionFactory,
  contactFormMessageFactory,
  newsletterAddressFactory,
  requestInvoiceFactory,
  celebritySuggestionFactory,
  celebritySuggestionEmailFactory,
  userCharityLogFactory,
  userPromotionalVideoFactory,
  firebaseNotificationQueueFactory,
  userRequestCountFactory,
  userRequestSuccessFailCountFactory,
  productRequestCountSumFactory,
  requestDateRangeCountFactory,
  userDateRangeCountFactory,
  couponFactory,
  userSumBalanceFactory,
  userRecordingTimeFactory,
  userTotalRecordingTimeFactory,
};
