<template>
  <div>
    <json-editor
      v-if="!loading"
      :schema="schema"
      :initialData="initialData"
      @change="handleChange"
    />
  </div>
</template>

<script>
import connector from '../../api/connector';
import jsonEditor from '../../components/forms/JSONEditor.vue';
import { HOST } from '../../environment';
import {
  documentTemplateFactory,
  documentTemplateVersionFactory,
  requestFactory,
  requestProductFactory,
} from '../../model';

export default {
  name: 'request-invoice-modify-invoice-tab',
  components: {
    jsonEditor,
  },
  props: ['single'],
  data() {
    return {
      schema: {
        $schema: 'https://json-schema.org/draft/2019-09/schema',
        type: 'object',
        title: 'Invoice',
        description: 'Request invoice',
        properties: {
          variables: {
            type: 'object',
            properties: {
              customer: {
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                  },
                  phone: {
                    type: 'string',
                  },
                  email: {
                    type: 'string',
                  },
                },
                required: ['name', 'phone', 'email'],
              },
              invoice: {
                type: 'object',
                properties: {
                  variableSymbol: {
                    type: 'string',
                  },
                  issueDate: {
                    type: 'string',
                  },
                  deliveryDate: {
                    type: 'string',
                  },
                  paymentMethod: {
                    type: 'string',
                  },
                },
                required: ['variableSymbol', 'issueDate', 'deliveryDate', 'paymentMethod'],
              },
              products: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    number: {
                      type: 'string',
                    },
                    name: {
                      type: 'string',
                    },
                    quantity: {
                      type: 'integer',
                    },
                    priceUnit: {
                      type: 'string',
                    },
                    priceTotal: {
                      type: 'string',
                    },
                  },
                  required: ['number', 'name', 'quantity', 'priceUnit', 'priceTotal'],
                },
              },
              total: {
                type: 'object',
                properties: {
                  withoutTax: {
                    type: 'string',
                  },
                  taxPercent: {
                    type: 'string',
                  },
                  tax: {
                    type: 'string',
                  },
                  withTax: {
                    type: 'string',
                  },
                  toPay: {
                    type: 'string',
                  },
                },
                required: ['withoutTax', 'taxPercent', 'tax', 'withTax', 'toPay'],
              },
            },
            required: ['customer', 'invoice', 'products', 'total'],
          },
        },
        required: ['variables'],
      },
      initialData: {},
      loading: true,
      updatedData: {},
    };
  },
  methods: {
    formatPrice(price) {
      price = parseFloat(price);

      return `${price.toFixed(2).replace('.', ',')} €`;
    },
    formatDate(date) {
      date = new Date(date);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    },
    async getSchema() {
      const currentVersion = (
        await documentTemplateFactory.fetch({
          'slug.eq': 'invoice',
        })
      )[0].currentVersion;

      this.schema = (
        await documentTemplateVersionFactory.fetch({
          'slug.eq': 'invoice',
          'version.eq': currentVersion,
        })
      )[0].schema;
    },
    async retrieveInvoiceData() {
      const request = await requestFactory.pk(this.single.requestId);
      const requestProducts = await requestProductFactory.fetch({ 'requestId.eq': request.id });

      const products = requestProducts.map((product, index) => ({
        number: (index + 1).toString(),
        name: product.productName,
        quantity: 1,
        priceUnit: this.formatPrice(product.price),
        priceTotal: this.formatPrice(product.price),
      }));

      this.initialData = {
        variables: {
          customer: {
            name: request.contactFullname,
            phone: request.contactPhone,
            email: request.contactEmail,
          },
          invoice: {
            variableSymbol: this.single.variableSymbol,
            issueDate: this.formatDate(this.single.created),
            deliveryDate: this.formatDate(this.single.created),
            paymentMethod: 'platobnou kartou', // TODO maybe define some constant
          },
          products,
          total: {
            withoutTax: this.formatPrice(request.amount),
            taxPercent: '21', // TODO maybe define some constant
            tax: '0 €',
            withTax: this.formatPrice(request.amount),
            toPay: '0,00 €',
          },
        },
      };

      this.loading = false;
    },
    async handleChange(invoiceData) {
      const { identifier } = await connector.post('/custom/generate-invoice', {
        data: invoiceData,
      });

      if (!identifier) {
        alert('Something went wrong');
      } else {
        window.open(`${HOST}/documents/download/${identifier}`, '_blank');
      }
    },
  },
  created() {
    this.getSchema();
    this.retrieveInvoiceData();
  },
};
</script>

<style></style>
