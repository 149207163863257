<template>
  <div>
    <div>
      <card title="User statistics" description="Holds user statistics">
        <data-table
          :model="MODEL_NAME_USER_REQUEST_COUNT"
          hideAggregates
          hideNewButton
          :filters="{ 'requestStatusId.eq': 2 }"
          :userSortOptions="{
            enabled: true,
            initialSortBy: { field: 'count', type: 'desc' },
          }"
        />

        <div class="showtime-data-table mt-5">
          <div class="left">
            <span v-if="loading">Loading ...</span>
            <span v-else-if="errors"> {{ errors }} </span>
            <bar-chart
              v-else
              :datasets="[
                {
                  label: 'Prírastok nových autorov',
                  backgroundColor: colors[0],
                  data: chart,
                },
                {
                  label: 'Schválení autori',
                  backgroundColor: colors[1],
                  data: approvedUsers,
                },
                {
                  label: 'Neschválení autori',
                  backgroundColor: colors[2],
                  data: notApprovedUsers,
                },
              ]"
              :labels="labels"
              :maxValue="maxValue"
            />
          </div>
          <aside class="right showtime-data-table-filters">
            <header class="table-filter-header">
              <h5>Filters</h5>
            </header>
            <div class="table-filter-row">
              <label class="table-filter-label">
                Filter by date of registration <br />
                <small class="text-muted"
                  >Display the number of new users based on their day of registration.</small
                >
              </label>
              <date-range-filter @input="saveDateRange" :clear="clearFiltersNanoid" />
            </div>
            <div class="spacer"></div>
            <footer class="table-filter-footer">
              <button @click="clearFilters" class="btn btn-sm btn-light">Clear filters</button>
            </footer>
          </aside>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { MODEL_NAME_USER_DATE_RANGE_COUNT, MODEL_NAME_USER_REQUEST_COUNT } from '../../constants';
import { randomColor as rc } from '../../utils/random-color';
import getFactory from '../../model';
import { nanoid } from 'nanoid/non-secure';
import BarChart from '../../components/charts/BarChart.vue';
import DateRangeFilter from '../../components/data/data-filters/DateRangeFilter.vue';

export default {
  name: 'page-user-stats',
  components: {
    BarChart,
    DateRangeFilter,
  },
  data: () => ({
    loading: false,
    errors: null,
    model: MODEL_NAME_USER_DATE_RANGE_COUNT,
    MODEL_NAME_USER_REQUEST_COUNT,
    userCount: null,
    colors: [],
    chart: [],
    approvedUsers: [],
    notApprovedUsers: [],
    labels: [],
    approval: null,
    range: null,
    clearFiltersNanoid: null,
  }),
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        this.errors = null;
        this.chart = [];
        this.approvedUsers = [];
        this.notApprovedUsers = [];
        this.labels = [];

        this.userCount = await this.factory.fetch({
          'registered.range': this.range,
        });

        // sort the entries by date of registration, the earliest first
        this.userCount.sort(
          (a, b) => new Date(a.registered).getTime() - new Date(b.registered).getTime()
        );

        this.userCount.forEach((item) => {
          this.chart.push(item.userCount);
          this.approvedUsers.push(item.isApprovedCount);
          this.notApprovedUsers.push(item.notApprovedCount);
          this.labels.push(item.registered.split('T')[0]);
        });
      } catch (e) {
        this.errors = e;
      } finally {
        this.loading = false;
      }
    },
    fillColors() {
      for (let i = 0; i < 3; ++i) {
        this.colors.push(this.randomColor());
      }
    },
    saveDateRange(dateRange) {
      this.range = dateRange;
      this.fetchData();
    },
    saveApprovalState(approval) {
      this.approval = approval;
      this.fetchData();
    },
    clearFilters() {
      this.clearFiltersNanoid = nanoid();
    },
    randomColor() {
      return rc();
    },
  },
  computed: {
    factory() {
      return getFactory(this.model);
    },
    maxValue() {
      return this.chart.length > 0 ? Math.max(...this.chart) + 2 : 10;
    },
  },
  async created() {
    this.fillColors();
    this.fetchData();
  },
};
</script>

<style></style>
