import { MODEL_NAME_USER } from '../../constants';
import DataStorageProperties from '../abstract/data-storage-properties';
import bool from '../abstract/properties/property-bool';
import date from '../abstract/properties/property-date';
import int from '../abstract/properties/property-int';
import object from '../abstract/properties/property-object';
import string from '../abstract/properties/property-string';

const firebaseNotificationQueueProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    form: { ignore: true },
    table: { hidden: true },
    required: false,
  }),
  titleKey: string({
    titleKey: true,
    form: { ignore: true },
  }),
  token: string({
    table: {
      hidden: true,
    },
  }),
  userId: int({ references: MODEL_NAME_USER, pretty: 'Celebrity' }),
  payload: object({}),
  options: object({ table: { hidden: true } }),
  created: date({ defaultValue: new Date(), table: { hidden: true } }),
  sent: date({
    required: false,
    form: { ignoreWhenNew: true },
  }),
  success: bool({
    required: false,
    form: { ignoreWhenNew: true },
  }),
  response: object({
    required: false,
    form: { ignoreWhenNew: true },
    table: { hidden: true },
  }),
  test: bool({
    required: false,
    defaultValue: false,
    table: { hidden: true },
    form: { ignoreWhenNew: true },
  }),
});

export default firebaseNotificationQueueProperties;
