class Cursor {
  next() {
    if (this.max > 0) {
      this.position = (this.position + 1) % this.max;
    }

    return this;
  }

  prev() {
    if (this.max > 0) {
      if (this.position === -1) {
        this.position = this.max - 1;
      } else {
        this.position = (this.position - 1 + this.max) % this.max;
      }
    }

    return this;
  }

  setPosition(position) {
    this.position = position;

    return this;
  }

  setMax(max) {
    this.max = max;

    return this;
  }

  reset() {
    this.position = -1;
    this.max = 0;

    return this;
  }

  constructor() {
    this.position = -1;

    this.max = 0;
  }
}

export default Cursor;
