import Card from './components/Card.vue';
import Icon from './components/icons/Icon.vue';
import Tabs from './components/tabs/Tabs.vue';
import TabPane from './components/tabs/TabPane.vue';
import NotImplementedBanner from './components/NotImplementedBanner.vue';

import DataTable from './components/data/DataTable.vue';
import DataForm from './components/data/DataForm.vue';
import DataSelect from './components/data/DataSelect.vue';
import DataSingleDisplay from './components/data/DataSingleDisplay.vue';
import DataSingleConnectionDisplay from './components/data/DataSingleConnectionDisplay.vue';
import DataAggregateDisplay from './components/data/DataAggregateDisplay.vue';
import DataStats from './components/data/DataStats.vue';

import ConnectionForm from './components/composites/ConnectionForm.vue';
import TemplateEditor from './components/composites/TemplateEditor.vue';

export default {
  install(Vue) {
    Vue.component('card', Card);
    Vue.component('icon', Icon);
    Vue.component('tabs', Tabs);
    Vue.component('tab-pane', TabPane);
    Vue.component('not-implemented-banner', NotImplementedBanner);

    Vue.component('data-table', DataTable);
    Vue.component('data-form', DataForm);
    Vue.component('data-select', DataSelect);
    Vue.component('data-single-display', DataSingleDisplay);
    Vue.component('data-single-connection-display', DataSingleConnectionDisplay);
    Vue.component('data-aggregate-display', DataAggregateDisplay);
    Vue.component('data-stats', DataStats);

    Vue.component('connection-form', ConnectionForm);
    Vue.component('template-editor', TemplateEditor);
  },
};
