<template>
  <nav aria-label="breadcrumb">
    <p v-if="error">Error</p>
    <ol v-else class="breadcrumb">
      <template v-for="part in routeParts">
        <template v-if="!part.last && part.path">
          <li :key="`breadcrumb-part-${part.position}`" class="breadcrumb-item">
            <router-link :to="part.path">{{ part.name }}</router-link>
          </li>
        </template>
        <template v-else>
          <li
            :key="`breadcrumb-part-${part.position}`"
            class="breadcrumb-item active"
            aria-current="page"
          >
            {{ part.name }}
          </li>
        </template>
      </template>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'breadcrumbs',
  data() {
    return {
      routeParts: [],
      loading: false,
      error: null,
    };
  },
  methods: {
    async updateRouteParts() {
      try {
        this.loading = true;
        this.error = null;

        if (this.$route.matched.length == 0) {
          return [];
        }

        // const parts = [];
        const page = this.$route.matched[this.$route.matched.length - 1];
        const breadcrumbs = await page.meta.breadcrumbs(this.$route);

        this.routeParts = breadcrumbs.map(({ path, name }, i) => ({
          path,
          name,
          position: i,
          last: i === breadcrumbs.length - 1,
        }));
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    '$route.path': 'updateRouteParts',
  },
  async created() {
    await this.updateRouteParts();
  },
};
</script>

<style lang="scss">
.breadcrumb {
  background-color: #0b0c0e !important;
  color: #8f95a3 !important;
  text-transform: capitalize;
  .breadcrumb-item {
    line-height: 16px;
    font-size: 12px;
    a {
      text-decoration: underline;
      color: #ffffff;
    }
    &.active {
      color: #8f95a3 !important;
    }

    + .breadcrumb-item {
      padding-left: 16px !important;
    }
  }
}
</style>
