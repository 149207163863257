var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('card',{attrs:{"title":"User statistics","description":"Holds user statistics"}},[_c('data-table',{attrs:{"model":_vm.MODEL_NAME_USER_REQUEST_COUNT,"hideAggregates":"","hideNewButton":"","filters":{ 'requestStatusId.eq': 2 },"userSortOptions":{
          enabled: true,
          initialSortBy: { field: 'count', type: 'desc' },
        }}}),_c('div',{staticClass:"showtime-data-table mt-5"},[_c('div',{staticClass:"left"},[(_vm.loading)?_c('span',[_vm._v("Loading ...")]):(_vm.errors)?_c('span',[_vm._v(" "+_vm._s(_vm.errors)+" ")]):_c('bar-chart',{attrs:{"datasets":[
              {
                label: 'Prírastok nových autorov',
                backgroundColor: _vm.colors[0],
                data: _vm.chart,
              },
              {
                label: 'Schválení autori',
                backgroundColor: _vm.colors[1],
                data: _vm.approvedUsers,
              },
              {
                label: 'Neschválení autori',
                backgroundColor: _vm.colors[2],
                data: _vm.notApprovedUsers,
              } ],"labels":_vm.labels,"maxValue":_vm.maxValue}})],1),_c('aside',{staticClass:"right showtime-data-table-filters"},[_c('header',{staticClass:"table-filter-header"},[_c('h5',[_vm._v("Filters")])]),_c('div',{staticClass:"table-filter-row"},[_c('label',{staticClass:"table-filter-label"},[_vm._v(" Filter by date of registration "),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v("Display the number of new users based on their day of registration.")])]),_c('date-range-filter',{attrs:{"clear":_vm.clearFiltersNanoid},on:{"input":_vm.saveDateRange}})],1),_c('div',{staticClass:"spacer"}),_c('footer',{staticClass:"table-filter-footer"},[_c('button',{staticClass:"btn btn-sm btn-light",on:{"click":_vm.clearFilters}},[_vm._v("Clear filters")])])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }