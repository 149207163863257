<template>
  <div>
    <div class="custom-view-content">
      <div class="icon">
        <icon icon="video-icon" />
      </div>
      <div>
        <h1>All videos</h1>
        <p class="model-description">The list of videos.</p>
      </div>
    </div>
    <div class="model-filters">
      <template v-for="filter in videoFactory.getFilters()">
        <div class="table-filter-row" :key="filter.filter">
          <label class="table-filter-label"> {{ filter.label }}</label>
          <component
            :is="filter.type"
            v-bind="filter.props"
            @input="handleFilterInput(filter.filter, $event)"
            :clear="clearFiltersNanoid"
            :initialValue="userFilters[filter.filter]"
          />
        </div>
      </template>
      <button @click="clearFilters" class="clear-filters-button btn">
        <icon icon="cancel-icon" />
        <span>Clear filters</span>
      </button>
    </div>
    <span v-if="loading">Loading ...</span>
    <span v-else-if="error"> {{ error }} </span>
    <video-grid-layout v-else-if="videos" :videos="videos" :hasActions="false" class="mt-2" />
    <!-- <data-table
      :model="MODEL_NAME_VIDEO"
      hideNewButton
      hideEditButton
      hideDeleteButton
      hideAggregates
      :filters="{
        'isAudio.eq': false,
      }"
    /> -->
  </div>
</template>

<script>
import { MODEL_NAME_VIDEO } from '../../constants';
import videoGridLayout from '../../components/layout/VideoGridLayout.vue';
import { videoFactory } from '../../model';
import { isArray } from 'lodash';
import SimpleSelectFilter from '../../components/data/data-filters/SimpleSelectFilter.vue';
import DataSelectFilter from '../../components/data/data-filters/DataSelectFilter.vue';
import DateRangeFilter from '../../components/data/data-filters/DateRangeFilter.vue';
import deepEqual from 'deep-equal';
import { nanoid } from 'nanoid/non-secure';
import Vue from 'vue';

export default {
  name: 'reviews',
  props: {
    // Data properties
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    videoGridLayout,
    SimpleSelectFilter,
    DataSelectFilter,
    DateRangeFilter,
  },
  data() {
    return {
      MODEL_NAME_VIDEO,
      videoFactory,
      loading: false,
      error: null,
      videos: [],
      clearFiltersNanoid: null,
      userFilters: {},
    };
  },
  methods: {
    clearFilters() {
      this.search = '';
      this.clearFiltersNanoid = nanoid();
    },
    async getData() {
      try {
        this.loading = true;
        this.error = null;
        this.videos = await videoFactory.fetch({
          'isAudio.eq': false,
          ...this.userFilters,
        });

        if (!isArray(this.videos)) {
          this.videos = [this.videos];
        }
      } catch (e) {
        this.error = e;
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    handleFilterInput(filter, value) {
      this.userFilters[filter] = value;
      this.getData();
    },
  },
  watch: {
    async filters(newValue, oldValue) {
      if (!deepEqual(newValue, oldValue)) {
        await this.getData();
      }
    },
  },
  created() {
    Object.entries(this.filters).forEach(([key, value]) => Vue.set(this.userFilters, key, value));

    this.getData();
  },
};
</script>
