<template>
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3413 1.375H2.125C1.50368 1.375 1 1.87868 1 2.5V11.5C1 12.1213 1.50368 12.625 2.125 12.625H13.3413C13.9626 12.625 14.4663 12.1213 14.4663 11.5V2.5C14.4663 1.87868 13.9626 1.375 13.3413 1.375Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.9999 11.5788L14.4662 7.00001L18.9999 2.42126V11.5788Z"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.45374 7.84375L6.14123 9.53125L11.2037 4.46875"
      stroke="#8F95A3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'completed-requests-icon',
};
</script>
