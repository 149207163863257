var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{attrs:{"model":_vm.MODEL_NAME_USER,"hideNewButton":"","hideEditButton":"","hideDeleteButton":"","hideFilters":"","hideAggregates":"","filters":{ 'pk.in': _vm.single.pk() },"overrideColumns":[
    'contactVerified',
    'smsCode',
    'emailCode',
    'consentTermsAndConditions',
    'consentPersonalData',
    'consentCommunityPolicy',
    'consentFrameworkContract' ]}})}
var staticRenderFns = []

export { render, staticRenderFns }