<template>
  <connection-form :single="single" connection="tags" filterName="users.eq" />
</template>

<script>
export default {
  name: 'user-tags-tab',
  props: ['single'],
};
</script>
