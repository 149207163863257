<template>
  <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.049 1.5H3.10002C2.16114 1.5 1.40002 2.26112 1.40002 3.2V16.8C1.40002 17.7389 2.16114 18.5 3.10002 18.5H20.049C20.9879 18.5 21.749 17.7389 21.749 16.8V3.2C21.749 2.26112 20.9879 1.5 20.049 1.5Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.6 16.9191L21.749 10.0001L28.6 3.08105V16.9191Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'all-requests-icon',
};
</script>
