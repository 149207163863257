<template>
  <div>
    <div class="model-single-header">
      <div class="model-single-content">
        <div>
          <h1>{{ title }}</h1>
          <p class="model-description">{{ description }}</p>
        </div>
      </div>
      <div class="model-single-stats-card">
        <component v-if="!isNew" :is="statsCardComponent" :pk="pk" />
      </div>
    </div>
    <p class="mt-3 mb-3" v-if="loading">Loading ...</p>
    <p class="mt-3 mb-3" v-else-if="error">{{ error }}</p>

    <template v-else>
      <!-- Navigation -->
      <template v-if="!isNew">
        <nav slot="navigation" class="tabs mb-4">
          <router-link
            :to="`${factory.getPath()}/${pk}`"
            :class="tab === null ? 'tab active' : 'tab'"
            class="btn btn-sm mr-2"
            ><span class="title">Detail</span></router-link
          >
          <template v-for="t in factory.getTabs()">
            <router-link
              v-if="t.shouldDisplay === undefined || t.shouldDisplay(single)"
              :key="t.slug"
              :to="`${factory.getPath()}/${pk}/${t.slug}`"
              :class="(t.slug === tab ? 'tab active ' : 'tab ') + (t.className || '')"
              class="btn btn-sm mr-2"
              ><span class="title">{{ t.name }}</span></router-link
            >
          </template>
        </nav>
      </template>

      <!-- Contents -->
      <template v-if="tab === null">
        <div class="pb-10">
          <data-form
            :model="model"
            :pk="pk"
            :class="['model-single-form', `model-single-${model}`]"
          />
          <component :is="sideComponent" :pk="pk" class="model-side-component" />
        </div>
      </template>
      <component v-else :is="tabComponent" :single="single" class="model-single-component" />
    </template>
  </div>
</template>

<script>
import getFactory from '../model';
import UserRequestsTab from './user-tabs/RequestsTab.vue';
import UserVideosTab from './user-tabs/VideosTab.vue';
import UserProductsTab from './user-tabs/ProductsTab.vue';
import UserTagsTab from './user-tabs/TagsTab.vue';
import UserVerificationsTab from './user-tabs/VerificationsTab.vue';
import UserPayoutsTab from './user-tabs/PayoutsTab.vue';
import UserBillingAddressTab from './user-tabs/BillingAddressTab.vue';
import UserSupportedCharitiesTab from './user-tabs/SupportedCharitiesTab.vue';
import UserStatsCard from './user-stats-card/UserStatsCard.vue';
import CharityUsersTab from './charity-tabs/UsersTab.vue';
import ProductAddonsTab from './product-tabs/AddonsTab.vue';
import VideoActionsTab from './video-tabs/ActionsTab.vue';
import VideoPlayTab from './video-tabs/PlayTab.vue';
import RequestProductsTab from './request-tabs/ProductsTab.vue';
import RequestVideoTab from './request-tabs/VideoTab.vue';
import RequestActionsTab from './request-tabs/ActionsTab.vue';
import RequestPaymentsTab from './request-tabs/PaymentsTab.vue';
import RequestContactTab from './request-tabs/ContactTab.vue';
import RequestReviewTab from './request-tabs/ReviewTab.vue';
import RequestStatsCard from './request-stats-card/RequestStatsCard.vue';
import RequestSideComponent from './request-side-component/RequestSideComponent.vue';
import RequestInvoiceDownloadTab from './request-invoice-tabs/DownloadTab.vue';
import RequestInvoiceModifyInvoiceTab from './request-invoice-tabs/ModifyInvoiceTab.vue';
import DocumentTemplateVersionsTab from './document-template-tabs/VersionsTab.vue';
import DocumentTemplateVersionDownloadTab from './document-template-version-tabs/DownloadTab.vue';
import CelebritySuggestionEmailSuggestionsTab from './celebrity-suggestion-tabs/EmailSuggestionsTab.vue';

export default {
  name: 'page-model-new',
  components: {
    // User tabs
    UserRequestsTab,
    UserVideosTab,
    UserProductsTab,
    UserTagsTab,
    UserVerificationsTab,
    UserPayoutsTab,
    UserBillingAddressTab,
    UserSupportedCharitiesTab,

    // User stats card
    UserStatsCard,

    // Charity tabs
    CharityUsersTab,

    // Product tabs
    ProductAddonsTab,

    // Video tabs
    VideoActionsTab,
    VideoPlayTab,

    // Request tabs
    RequestProductsTab,
    RequestVideoTab,
    RequestActionsTab,
    RequestPaymentsTab,
    RequestContactTab,
    RequestReviewTab,

    // Request stats card
    RequestStatsCard,

    // Request side component
    RequestSideComponent,

    // Request Invoice tabs
    RequestInvoiceDownloadTab,
    RequestInvoiceModifyInvoiceTab,

    // Document Template tabs
    DocumentTemplateVersionsTab,

    // Document Template Version tabs
    DocumentTemplateVersionDownloadTab,

    // Celebrity Suggestion tabs
    CelebritySuggestionEmailSuggestionsTab,
  },
  data() {
    return {
      single: null,
      loading: false,
      error: null,
    };
  },
  computed: {
    pk() {
      const pk = this.$route.params.pk;
      return typeof pk === 'string' && pk.toLowerCase() === 'new' ? null : pk;
    },
    model() {
      return this.$route.params.model;
    },
    isNew() {
      return this.pk === null;
    },
    factory() {
      return getFactory(this.model);
    },
    title() {
      if (this.isNew) {
        return `New ${this.factory.getSingular()}`;
      }

      if (this.loading) {
        return this.factory.getSingular();
      }

      return this.single[this.factory.getTitleKey()];
    },
    description() {
      if (this.tab === null) {
        let description = `The general info about ${
          this.loading || this.isNew
            ? this.factory.getSingular()
            : this.single[this.factory.getTitleKey()]
        }.`;

        if (this.isNew) {
          description += ` More settings will be available after the ${this.factory.getSingular()} is created.`;
        }

        return description;
      }

      return this.factory.getTabDescription(this.tab);
    },
    tab() {
      return this.$route.params.tab || null;
    },
    tabComponent() {
      return `${this.factory.getModelName()}-${this.tab}-tab`;
    },
    statsCardComponent() {
      return `${this.factory.getModelName()}-stats-card`;
    },
    sideComponent() {
      return `${this.factory.getModelName()}-side-component`;
    },
  },
  methods: {
    async loadSingle() {
      if (this.isNew) {
        return; // Do nothing when creating a new storage.
      }

      try {
        this.error = null;
        this.single = null;
        this.loading = true;

        this.single = await this.factory.pk(this.pk);
      } catch (error) {
        this.error = (error || {}).message || 'Error';
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.loadSingle();
  },
  watch: {
    pk: 'loadSingle',
    model: 'loadSingle',
  },
};
</script>

<style lang="scss">
.tabs {
  display: flex;
  align-items: center;
  padding: 8px 0px 8px 0px;
  margin-bottom: 8px;

  .tab {
    min-width: 81px;
    height: 40px;
    border-radius: 4px;
    padding: 8px 16px 0px 16px;
    background-color: transparent;
    color: #8f95a3;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;

    &:hover {
      color: #ffffff;
      .title {
        padding-bottom: 8px;
        border-bottom: 2px solid #343842;
      }
    }

    &.active {
      color: #ffffff;
      .title {
        padding-bottom: 8px;
        border-bottom: 2px solid #304dff;
      }
    }
  }
}

.model-single-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  padding-top: 24px;
  padding-bottom: 8px;

  .model-single-content {
    display: flex;
    flex-direction: row;
    gap: 8px;
    .icon {
      height: 34px;
      width: 34px;
      margin-left: 7px;
      margin-right: 8px;
      svg {
        height: 100%;
        width: 100%;
        stroke: #ffffff !important;
        path {
          stroke: #ffffff !important;
        }
      }
    }
  }
}

.model-single-form {
  background-color: #121417;
  border-radius: 4px;
  border: 1px solid #0b0c0e;
  padding: 12px 16px;
}
</style>
