<template>
  <div class="page-home">
    <card title="Showtime Admin"> </card>
  </div>
</template>

<script>
export default {
  name: 'page-home',
};
</script>
