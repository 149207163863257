<template>
  <form @submit.prevent="onSubmit">
    <div ref="editor" class="json-editor"></div>
    <button type="submit" class="btn btn-primary text-white mt-2">Submit the changes</button>
  </form>
</template>

<script>
import JSONEditor from '../../vendor/json-editor';

export default {
  name: 'json-editor',
  props: ['schema', 'initialData'],
  methods: {
    setEditor() {
      this.editor = new JSONEditor(this.$refs.editor, {
        schema: this.schema,
        disable_edit_json: true,
        disable_collapse: true,
        disable_properties: true,
        disable_array_delete_last_row: true,
        disable_array_reorder: true,
        disable_array_delete_all_rows: true,
      });

      return this.editor;
    },
    setData() {
      this.editor.setValue(this.initialData);
    },
    onSubmit() {
      this.$emit('change', this.editor.getValue());
    },
  },
  mounted() {
    this.setEditor();
    this.setData();
  },
};
</script>

<style lang="scss">
.json-editor {
  .form-control {
    display: flex;
    gap: 10px;
  }

  input:focus {
    border-color: black;
  }
}

button.json-editor-btn-delete.delete {
  height: 25px;
  font-size: 13px;
}
</style>
