<template>
  <data-table
    :model="MODEL_NAME_CELEBRITY_SUGGESTION_EMAIL"
    hideNewButton
    hideEditButton
    hideDeleteButton
    hideFilters
    :filters="{ 'celebritySuggestionId.eq': single.pk() }"
    :overrideColumns="['email', 'created']"
  />
</template>

<script>
import { MODEL_NAME_CELEBRITY_SUGGESTION_EMAIL } from '../../constants';

export default {
  name: 'celebrity-suggestion-email-suggestions-tab',
  data() {
    return {
      MODEL_NAME_CELEBRITY_SUGGESTION_EMAIL,
    };
  },
  props: ['single'],
};
</script>
