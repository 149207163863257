<template>
  <div>
    <span v-if="loading">Loading ...</span>
    <span v-else-if="errors"> {{ errors }} </span>
    <card v-else title="Basic statistics" description="Holds basic statistics">
      <div class="showtime-data-table">
        <div class="left">
          <div class="d-flex" style="flex-wrap: wrap; flex-direction: row; justify-content: left">
            <data-stats
              :model="MODEL_NAME_REQUEST"
              title="Vybavené žiadosti"
              aggregatedProperty="id"
              aggregateFunction="Count"
              :filters="{ 'requestStatusId.eq': 2, 'completed.range': range }"
              suffix="ks"
              :precision="0"
              :reloadToken="reloadToken"
              statsIcon="feather-activity"
            />
            <data-stats
              :model="MODEL_NAME_REQUEST"
              title="Čakajúce žiadosti"
              aggregatedProperty="id"
              aggregateFunction="Count"
              :filters="{ 'requestStatusId.in': [0, 1], 'paid.range': range }"
              suffix="ks"
              :precision="0"
              :reloadToken="reloadToken"
              statsIcon="feather-loader"
            />
            <data-stats
              :model="MODEL_NAME_REQUEST"
              title="Tržby spolu"
              aggregatedProperty="amount"
              aggregateFunction="Sum"
              :filters="{ 'completed.range': range }"
              :reloadToken="reloadToken"
              statsIcon="feather-briefcase"
            />
            <data-stats
              :model="MODEL_NAME_REQUEST"
              title="Úspešnosť vybavenia žiadostí"
              aggregatedProperty="id"
              aggregateFunction="Count"
              :filters="{ 'requestStatusId.eq': 2, 'completed.range': range }"
              suffix="%"
              :transformFn="(countId) => Math.round((countId / totalRequests) * 100)"
              :reloadToken="reloadToken"
              statsIcon="feather-arrow-up"
            />
            <data-stats
              :model="MODEL_NAME_PAYOUT"
              title="Vyplatené autorom"
              aggregatedProperty="amount"
              aggregateFunction="Sum"
              :filters="{ 'completed.range': range }"
              :reloadToken="reloadToken"
              statsIcon="feather-credit-card"
            />
            <data-stats
              :model="MODEL_NAME_USER"
              title="Suma na autorských účtoch"
              aggregatedProperty="balanceUsable"
              aggregateFunction="Sum"
              statsIcon="feather-users"
            />
          </div>
        </div>
        <aside class="right showtime-data-table-filters">
          <header class="table-filter-header">
            <h5>Filters</h5>
          </header>
          <div class="table-filter-row">
            <label class="table-filter-label">
              Filter the statistics by date range <br />
              <small class="text-muted"> Display the statistics in given date range </small>
            </label>
            <date-range-filter @input="saveDateRange" :clear="clearFiltersNanoid" />
          </div>

          <div class="spacer"></div>
          <footer class="table-filter-footer">
            <button @click="clearFilters" class="btn btn-sm btn-light">Clear filters</button>
          </footer>
        </aside>
      </div>
    </card>
  </div>
</template>

<script>
import DateRangeFilter from '../../components/data/data-filters/DateRangeFilter.vue';
import { MODEL_NAME_REQUEST, MODEL_NAME_PAYOUT, MODEL_NAME_USER } from '../../constants';
import { requestFactory } from '../../model';
import { nanoid } from 'nanoid/non-secure';

export default {
  components: { DateRangeFilter },
  name: 'page-basic-stats',
  data() {
    return {
      loading: false,
      errors: null,
      MODEL_NAME_REQUEST,
      MODEL_NAME_PAYOUT,
      MODEL_NAME_USER,
      totalRequests: null,
      range: null,
      reloadToken: null,
      clearFiltersNanoid: null,
    };
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        this.errors = null;

        this.totalRequests = (await requestFactory.fetch()).length;
      } catch (e) {
        this.errors = e;
      } finally {
        this.loading = false;
      }
    },
    saveDateRange(dateRange) {
      this.range = dateRange;
      this.reloadToken = nanoid();
    },
    clearFilters() {
      this.clearFiltersNanoid = nanoid();
      this.reloadToken = nanoid();
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>

<style></style>
