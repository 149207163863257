import { MODEL_NAME_USER } from '../../constants';
import DataStorageProperties from '../abstract/data-storage-properties';
import bool from '../abstract/properties/property-bool';
import date from '../abstract/properties/property-date';
import int from '../abstract/properties/property-int';
import price from '../abstract/properties/property-price';
import string from '../abstract/properties/property-string';
import url from '../abstract/properties/property-url';

const payoutProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  userId: int({
    references: MODEL_NAME_USER,
    pretty: 'User',
    form: { disableWhenUpdate: true },
  }),
  amount: price({
    min: 0,
    precision: 2,
    defaultValue: 0,
    form: { disableWhenUpdate: true },
    canAggregate: true,
    table: {
      formatFn: (price) => `${price} €`,
    },
  }),
  iban: string({
    required: false,
    maxLength: 255,
    titleKey: true,
  }),
  isApproved: bool({
    table: { hidden: true },
    defaultValue: false,
  }),
  isCompleted: bool({
    table: { hidden: true },
    defaultValue: false,
  }),
  invoiceUrl: url({
    required: false,
    table: { hidden: true },
  }),
  created: date({
    defaultValue: () => new Date(),
    form: { disableWhenUpdate: true },
  }),
  approved: date({
    required: false,
  }),
  completed: date({
    required: false,
  }),
});

export default payoutProperties;
